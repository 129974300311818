import React, { useRef, useState } from "react";
import Password from "../assets/password.png";
import "./Customer.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import down from "../assets/down.png";
import add from "../assets/add.png";
import { useEffect } from "react";
import { getCustomerDetail, deleteCustomer, updateCustomerDetail, getInvoiceDetail, printInvoice, save_notes_api, delete_notes_api } from "../services/httpService";
import Loader from "./Loader";
import ResizeFile, { img_to_text_url, notifyError, showConfirmDelete } from "../util/Util";
import { confirmAlert } from "react-confirm-alert";
import BillPage from "./billPage";
import { useReactToPrint } from "react-to-print";
import Navbar from "./Navbar";
import SideBar from "./Sidebar";
import { useMediaQuery } from "@mui/material";
import { extractTextFromImage } from "textifyimage";
import careypic from "../assets/carey48.jpg"
import { createWorker } from "tesseract.js";
import axios from "axios";
import Img_to_text from "./Img_to_text";
import DialogBox from "./DialogBox";



function InvoiceDetail(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const matches_520 = useMediaQuery('(max-width:520px)');
  const history = useHistory();
  const [invoiceData, setInvoiceData] = useState({});
  const [load, setLoad] = useState(false);
  const [updatedData, setUpdatedData] = useState(null);
  const ventor_id = localStorage.getItem("vendor_id");
  const [itemDetail, setItemDetail] = useState([])
  const [customerDetail, setCustomerDetail] = useState("")
  const [vendorDetail, setVendorDetail] = useState("")
  const [selected, setSelected] = useState("");
  const [total_price, settotal_price] = useState(0);
  const [total_tax, settotal_tax] = useState(0);
  const [imagetext, setimagetext] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [cropped_img, setcropped_img] = useState(null);
  const [selected_img, setselected_img] = useState(null);
  const [note, setnote] = useState('');
  const [notes_arr, setnotes_arr] = useState([]);
  const [policy_arr, setpolicy_arr] = useState([]);

  async function processImage(file) {
    try {
      // const text = extractTextFromImage(file);
      // text
      //   .then((data) => {
      //     console.log(data); // Output the extracted text
      //     setimagetext(data);
      //   })
      //   .catch((error) => {
      //     console.log(error); // Handle any errors
      //   });

      const worker = await createWorker();
      await worker.loadLanguage("eng");
      await worker.initialize("eng");
      const resp = await worker.recognize(file);
      console.log(resp);
      setimagetext(resp.data.text);
      await worker.terminate();
      // return text;



    } catch (error) {
      console.error("Error extracting text from the image: ", error);
    }
  }

  // processImage();


  useEffect(() => {
    setLoad(true);
    // console.log(props.location.pathname.split('/'));
    printInvoice(props.match.params.id, props.location.pathname.split('/')[3], props.location.pathname.split('/')[4]).then((res) => {
      if (res["data"]["shop_wizard"]["status"] === "1") {
        console.log({ data: res["data"]["shop_wizard"] });
        setLoad(false);
        setnotes_arr(res["data"]["shop_wizard"]['notes'])
        setpolicy_arr(res["data"]["shop_wizard"]['policy'])
        console.log("invoice data", res["data"]["shop_wizard"])
        setInvoiceData(res["data"]["shop_wizard"]);
        settotal_price(res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.amount ? v.amount : 0)).reduce((t, v) => (t + v)));
        settotal_tax(res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.tax ? v.tax : 0)).reduce((t, v) => (t + v)));
      }
    });
    return () => { };
  }, []);

  useEffect(() => {
    if (selected) {
      printInvoice(selected, props.location.pathname.split('/')[3]).then(res => {
        // console.log({ data: res["data"]["shop_wizard"] });
        if (res["data"]["shop_wizard"]["status"] === "1") {
          setItemDetail(res.data.shop_wizard.item_detail)
          setCustomerDetail(res.data.shop_wizard.customer_detail)
          setVendorDetail(res.data.shop_wizard.vendor_detail)
        }
      })
    }
  }, [selected])

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    // console.log({ itemDetail, vendorDetail });
    if (itemDetail.length && vendorDetail) {
      handlePrint();
    }
  }, [itemDetail.length, JSON.stringify(vendorDetail)]);

  // console.log({ invoiceData, inv_id: props.match.params });

  const add_note = () => {
    if (note.length > 0 && note.length < 51) {
      save_notes_api(note, props.match.params.id).then((res) => {
        if (res.data.shop_wizard.status == 1) {
          setnotes_arr([{
            notes: note,
            notes_id: res.data.shop_wizard.notes_id
          }, ...notes_arr]);
          setnote('');
          // notifyError('Some error occured');
        }
        else {
          notifyError('Some error occured');
        }
      }).catch((err) => {
        console.log({ err });
        notifyError('Some error occured');
      })
    }
  }

  const remove_note = (item) => {
    delete_notes_api(item.notes_id).then((res) => {
      if (res.data.shop_wizard.status == 1) {
        setnotes_arr(notes_arr.filter((el) => {
          return el != item
        }));
      }
      else {
        notifyError('Some error occured');
      }
    }).catch((err) => {
      console.log({ err });
      notifyError('Some error occured');
    })

  }

  const inv_date = props.location.pathname.split('/')[5] == 'null' ? "No date" : props.location.pathname.split('/')[5];

  return (
    <>

      {
        Object.keys(invoiceData).length > 0 ?
          <>
            <Loader load={load} />
            <section className="customer__section">
              <Navbar heading='Invoice Details' />
              <div style={{ display: "flex" }}>
                {
                  !matches_820 &&
                  <div style={{ width: "19%" }}>
                    <SideBar />
                  </div>
                }
                <div style={{ width: matches_820 ? "100%" : "80.9%" }}>
                  <i class="fa fa-print" aria-hidden="true" onClick={() => {
                    // alert(props.match.params.id);
                    setVendorDetail("")
                    setItemDetail([]);
                    setCustomerDetail("");
                    setSelected('');
                    setTimeout(() => {
                      setSelected(props.match.params.id)
                    }, 50);
                  }} style={{ fontSize: "24px", float: "right", color: "#000", marginTop: "10px", marginRight: "15px", cursor: "pointer" }}></i>
                  <div className=" container-fluid">

                    <div
                      className="row"
                      style={{
                        // borderTopLeftRadius: "30px",
                        // borderTopLeftRadius: "30px",
                        // borderTopRightRadius: "30px",
                        overflow: "hidden",
                      }}
                    >
                      <div className="col-12 p-0">
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div style={{ display: "flex", flexWrap: "wrap", width: "100%", padding: "0px 0px 0px 10px" }} >
                                <div style={{ width: !matches_520 ? "48%" : "100%", display: "flex" }}>
                                  <img
                                    src={Object.keys(invoiceData).length ? invoiceData.vendor_detail.logo_url : ""}
                                    style={{ height: "120px" }} />&nbsp;&nbsp;
                                  <div>
                                    <p style={{ fontSize: "11px", color: "black", lineHeight: "16px", paddingTop: "1px" }}>
                                      <span style={{ fontSize: "15px", fontWeight: "600" }}>{invoiceData.vendor_detail.name}</span><br />
                                      {invoiceData.vendor_detail.address}<br />
                                      {invoiceData.vendor_detail.contact}<br />
                                      {invoiceData.vendor_detail.email}
                                    </p>
                                  </div>
                                </div>
                                <div style={{ width: !matches_520 ? "48%" : "100%", color: "#000" }}>
                                  <div style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                                    <b>INVOICE# {props.match.params.id}</b>
                                  </div>
                                  <div style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center", fontSize: "12px" }}>
                                    <i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;{props.location.pathname.split('/')[5] == 'null' ? "No date" : props.location.pathname.split('/')[5]}
                                  </div>
                                  <div style={{
                                    width: "100%",
                                    paddingRight: "20px"
                                    , position: "relative", display: "flex", fontWeight: "600", color: JSON.stringify(invoiceData.customer_detail.invoice_status)?.toLowerCase()?.includes('paid') ? "#5cb85c" : "red", justifyContent: "end", alignItems: "center", fontSize: JSON.stringify(invoiceData.customer_detail.invoice_status)?.toLowerCase()?.includes('paid') ? "24px" : "16px"
                                  }}>
                                    <span>{invoiceData.customer_detail.invoice_status}</span>
                                    {
                                      !(JSON.stringify(invoiceData.customer_detail.invoice_status)?.toLowerCase()?.includes('paid')) &&
                                      <button
                                        onClick={() => {
                                          localStorage.removeItem("estimateEdit2")
                                          localStorage.setItem("estimateEdit2",
                                            JSON.stringify({ invoice: props.match.params.id, customer_name: invoiceData.customer_detail.name, total: invoiceData.customer_detail.total_amount, vin: invoiceData.customer_detail.vin_number, reg: invoiceData.customer_detail.reg_number, customer: invoiceData.customer_detail.customer_id }))
                                          history.push("/pay-estimate/" + props.match.params.id)
                                        }}
                                        style={{ position: "absolute", fontWeight: "600", top: "22px", right: "0px", width: "95px", backgroundColor: "#5cb85c", border: "1px solid green", borderRadius: "5px", padding: "2px 5px", color: "#fff", fontSize: "16px", marginLeft: "15px" }}>Pay Now</button>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex", width: "100%", padding: "15px 0px 0px 10px", fontSize: "13px", fontWeight: "500" }} >
                                <div style={{ width: "48%", color: "#000" }}>
                                  <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <i class="fa fa-user" aria-hidden="true"></i>&nbsp; {invoiceData.customer_detail.name}
                                  </div>
                                  <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <i class="fa fa-phone" aria-hidden="true"></i>&nbsp; {invoiceData.customer_detail.contact}
                                  </div>
                                </div>
                                <div style={{ width: "48%", color: "#000" }}>
                                  <div style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                                    {invoiceData.vehicle_detail.year + " " + invoiceData.vehicle_detail.color + " " + invoiceData.vehicle_detail.car_name + " " + invoiceData.vehicle_detail.model}
                                  </div>
                                  <div style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                                    <span style={{ fontWeight: "600" }}>VIN:</span>&nbsp; {invoiceData.customer_detail.vin_number}
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: "100%", padding: "15px 10px 0px 10px", color: "#000" }} >
                                <table style={{ width: "100%" }}>
                                  <thead style={{ border: "1px solid grey", fontSize: "12px", backgroundColor: "#ddd" }} >
                                    <th style={{ padding: "7px 0px" }} ><center>#</center></th>
                                    <th ><center>Description</center></th>
                                    <th style={{ textAlign: "end", paddingRight: "30px" }}>Price</th>
                                    <th style={{ textAlign: "end", paddingRight: "30px" }}>Tax</th>
                                    <th style={{ textAlign: "end", paddingRight: "50px" }}>Sub total</th>
                                  </thead>
                                  <tbody>
                                    {
                                      invoiceData?.item_detail?.map((val, index) => {
                                        return (
                                          <tr style={{ border: "1px solid grey", fontSize: "13px", padding: "5px 0px" }}>
                                            <td style={{ padding: "5px 0px" }}><center>{index + 1}</center></td>
                                            <td><center>{val.item_name}</center></td>
                                            <td style={{ textAlign: "end", paddingRight: "30px" }}>{val.amount}</td>
                                            <td style={{ textAlign: "end", paddingRight: "30px" }}>{val.tax}</td>
                                            <td style={{ textAlign: "end", paddingRight: "50px" }}>{(parseFloat(val.amount) + parseFloat(val.tax)).toFixed(2)}</td>
                                          </tr>
                                        )
                                      }
                                      )
                                    }
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ width: "100%", padding: "0px 10px 0px 10px", display: "flex", justifyContent: "space-between", color: "#000" }} >
                                <div style={{ width: "49%", paddingTop: "8px" }}>
                                  <input placeholder="type notes here..." type="text" style={{ width: "200px" }} value={note} onChange={(e) => { setnote(e.target.value) }} />
                                  <button onClick={add_note} style={{ width: "62px", backgroundColor: "#1050e6", border: "1px solid blue", borderRadius: "5px", color: "#fff", fontSize: "16px", marginLeft: "7px", fontWeight: "600" }}>Add</button>
                                  <div style={{ marginLeft: "-20px", fontSize: "14px", marginTop: "5px" }}>
                                    <ul class="dotted">
                                      {
                                        notes_arr.length > 0 &&
                                        notes_arr.map((el) => {
                                          return (
                                            <li>{el.notes} <span style={{ cursor: "pointer" }} onClick={() => remove_note(el)} >&times;</span></li>
                                          )
                                        })
                                      }
                                    </ul>
                                  </div>
                                </div>
                                <div style={{ width: "50%", padding: "0px 0px", fontSize: "12px", fontWeight: "600" }}>
                                  <div style={{ width: "100%", height: "30px", justifyContent: "space-between", padding: "0px 51px 0px 8px", border: "1px solid grey", display: "flex", alignItems: "center", backgroundColor: "#fff" }}>
                                    <span style={{ fontWeight: "550", fontSize: "11px" }}>TOTAL PRICE</span><span>{total_price.toFixed(2)}</span>
                                  </div>
                                  <div style={{ width: "100%", height: "30px", justifyContent: "space-between", padding: "0px 51px 0px 8px", border: "1px solid grey", display: "flex", alignItems: "center", backgroundColor: "#fff" }}>
                                    <span style={{ fontWeight: "550", fontSize: "11px" }}>TOTAL TAX</span><span>{total_tax.toFixed(2)}</span>
                                  </div>
                                  <div style={{ width: "100%", height: "30px", justifyContent: "space-between", padding: "0px 51px 0px 8px", border: "1px solid grey", display: "flex", alignItems: "center", backgroundColor: "#ddd" }}>
                                    <span style={{ fontWeight: "550", fontSize: "11px" }}>TOTAL AMOUNT</span><span>{(total_price + total_tax).toFixed(2)}</span>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: "100%", padding: "15px 10px 0px 10px", display: "flex", fontSize: "11px", justifyContent: "start", color: "#000" }} >
                                <div style={{ marginLeft: "-20px", fontSize: "14px", marginTop: "5px" }}>
                                  <span style={{ color: "#000", fontSize: "16px", marginLeft: "20px" }}>
                                    Terms and conditions:-
                                  </span>
                                  <ul class="dotted">
                                    {
                                      policy_arr.length > 0 &&
                                      policy_arr.map((el) => {
                                        return (
                                          <li>{el.policy}</li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                              <div style={{ width: "100%", padding: "10px 10px 0px 10px", display: "flex", fontSize: "13px", justifyContent: "start", color: "#000" }} >
                                <strong>Signature____________________</strong>
                              </div>
                            </div>
                            {/*<div className="row mt-4 px-3">
                    <div className="col-6 p-0 profile__content_col">
                      <small className="font-weight-bold text-dark">
                        Contact:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col">
                      <small className="profile__content__right">
                        {invoiceData?.customer_detail?.contact}
                      </small>
                    </div>
                  </div>
                  <div className="row mt-4 px-3">
                    <div className="col-6 p-0 profile__content_col">
                      <small className="font-weight-bold text-dark">
                        Reg no.:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col">
                      <small className="profile__content__right">
                        {invoiceData?.customer_detail?.reg_number}
                      </small>
                    </div>
                  </div>

                  <div className="row mt-4 px-3">
                    <div className="col-6 p-0 profile__content_col border-0">
                      <small className="font-weight-bold text-dark">
                        Vin no.:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col border-0">
                      <small className="profile__content__right">
                        {invoiceData?.customer_detail?.vin_number}
                      </small>
                    </div>
                  </div>
                  <div className="row mt-4 px-3">
                    <div className="col-6 p-0 profile__content_col border-0">
                      <small className="font-weight-bold text-dark">
                        Total amount:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col border-0">
                      <small className="profile__content__right">
                        {invoiceData?.customer_detail?.total_amount}
                      </small>
                    </div>
                  </div>
                  <div className="row mt-4 px-3">
                    <div className="col-6 p-0 profile__content_col border-0">
                      <small className="font-weight-bold text-dark">
                        Total Tax:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col border-0">
                      <small className="profile__content__right">
                        {invoiceData?.customer_detail?.total_tax}
                      </small>
                    </div>
                  </div>
                  <div className="row mt-4 px-3">
                    <div className="col-6 p-0 profile__content_col border-0">
                      <small className="font-weight-bold text-dark">
                        Grand Total:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col border-0">
                      <small className="profile__content__right">
                        {invoiceData?.customer_detail?.grand_total}
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 profile__content_col border-0 d-flex align-items-center justify-content-center">
                      <h3 className="text-dark mt-2">
                        Item Details
                      </h3>
                    </div>
                  </div>
                  {invoiceData?.item_detail?.map(function (data) {
                    return (
                      <div
                        className="col-12 p-0 mt-2"
                      >
                        <div className="card customer__card">
                          <div className="card-body customer__cardBody px-0">
                            <div className="row mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  Item Name.:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.item_name}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  Amount:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.amount}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  Tax:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.tax}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-none"><BillPage inv_date={inv_date} ref={componentRef} policy_arr={policy_arr} notes_arr={notes_arr} invoiceNumber={selected} vehicle_detail={invoiceData.vehicle_detail} itemDetail={itemDetail} customerDetail={customerDetail} vendorDetail={vendorDetail} /></div>
                </div>
              </div>
            </section>
          </>
          : ""}
    </>
  );
}

export default InvoiceDetail;
