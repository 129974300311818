import React, { useState } from "react";
import "./Customer.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import Name from "../assets/name.png";
import Message from "../assets/message.png";
import Phone from "../assets/phone.png";
import down from "../assets/down.png";
import add from "../assets/add.png";
import { useEffect } from "react";
import { getAllCustomer, searchCustomer, updateCustomerDetail, deleteCustomer, getCustomerDetail } from "../services/httpService";
import { confirmAlert } from "react-confirm-alert";
import Loader from "./Loader";
import { notifyError, notifySuccess, showConfirmDelete } from "../util/Util";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";

function Customer() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const [customerData, setCustomerData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [load, setLoad] = useState(false);
  const [query, setQuery] = useState("");
  const [updatedData, setUpdatedData] = useState(null);
  const ventor_id = localStorage.getItem("vendor_id");
  const history = useHistory()
  useEffect(() => {
    setLoad(true)
    getAllCustomer(ventor_id)
      .then((res) => {
        setLoad(false)
        if (res["data"]["shop_wizard"]["status"] === "1") {
          console.log(res["data"]["shop_wizard"]["customer_list"]);
          setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
          setShowData(res["data"]["shop_wizard"]["customer_list"]);
        }
      });
    return () => { };
  }, []);

  const getCustomers = () => {
    getAllCustomer(ventor_id)
      .then((res) => {
        setLoad(false)
        if (res["data"]["shop_wizard"]["status"] === "1") {
          setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
          setShowData(res["data"]["shop_wizard"]["customer_list"]);
        }
        else {
          setCustomerData([]);
          setShowData([]);
        }
      });
  }

  useEffect(() => {
    if (query) {
      // searchCustomer(ventor_id, query)
      //   .then((res) => {
      //     if (res["data"]["shop_wizard"]["status"] === "1") {
      //       setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
      //     }
      //   });
      setShowData(customerData.filter(v => v.name.toLowerCase().includes(query.toLowerCase())))
    } else {
      // getAllCustomer(ventor_id)
      //   .then((res) => {
      //     if (res["data"]["shop_wizard"]["status"] === "1") {
      //       setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
      //     }
      //   });
      setShowData(customerData)
    }
  }, [query])
  // console.log("datatatata")
  return (
    <>
      <Loader load={load} />
      <section className="customer__section">
        <Navbar heading='Customers' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80%" }}>
            <div className="input-group col-md-12 search-bar_container py-3">
              <input className="form-control py-2 border-right-0 border" type="search" onChange={e => setQuery(e.target.value)} placeholder="Search customer" id="example-search-input" />
              <span className="input-group-append">
                <button className="btn btn-outline-secondary border-left-0 border" type="button">
                  <i className="fa fa-search"></i>
                </button>
              </span>
              <span className="input-group-append">
                <Link to="/add-customer" >
                  <button className="btn btn-outline-secondary border-left-0 border" type="button">
                    <i className="fa fa-plus"></i>
                  </button>
                </Link>
              </span>
            </div>
            <div className="container customer__content" style={{height:"80vh",overflowY:"auto"}}>
              <div className="row">

                {showData?.map(function (data,index) {
                  return (
                    <div className="col-12 mt-2 cursor-pointer" key={index} >
                      <div className="card customer__card">
                        <div className="card-body customer__cardBody py-1">
                          <div className="row py-2">
                            <div className="col-8"
                              onClick={e => {
                                history.push("/customer-detail/" + data.customer_id)
                              }}
                            >
                              <p className="customer__paraDark mb-0">{data.name}</p>
                              <p className="mb-0 customer__paraLight">{data.email}</p>
                            </div>
                            <div className="col-4 d-flex flex-column justify-content-center align-items-end">
                              {/* <i className="fa fa-angle-right text-secondary fa-2x"></i> */}
                              <i className="fa fa-edit text-primary mb-2"
                                onClick={
                                  () => {
                                    setUpdatedData({
                                      name: data.name,
                                      email: data.email,
                                      contact: data.contact
                                    })
                                    confirmAlert({
                                      // title: 'Error',
                                      // message: text,
                                      customUI: ({ onClose }) => {

                                        return (
                                          <div
                                            className="custom-ui d-flex flex-column align-items-start"
                                            style={{
                                              width: "300px",
                                              background: "white",
                                              padding: "20px",
                                              border: "1px solid #ebebeb",
                                              color: "#4f4f4f",
                                              borderRadius: "10px",
                                            }}
                                          >
                                            {/* <h3 className="text-danger">Error</h3> */}
                                            {/* <small className="font-weight-bold mb-4" style={{ color: "black", fontSize: "0.85em" }}>{"Are you sure you want to generate invoice"}</small> */}
                                            <div className="form-group mb-0 w-100">
                                              <label
                                                htmlFor="exampleInputEmail1"
                                                className="ml-3"
                                              >
                                                Name
                                              </label>
                                              <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <span
                                                    className="input-group-text login__span px-2"
                                                    id="basic-addon1"
                                                  >
                                                    <img
                                                      src={Name}
                                                      alt=""
                                                      className="img-fluid login__img email_img"
                                                    />
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control login__input"
                                                  placeholder=""
                                                  aria-label="Username"
                                                  aria-describedby="basic-addon1"
                                                  name="email"
                                                  defaultValue={data.name}
                                                  onChange={(e) =>
                                                    setUpdatedData(v => {
                                                      console.log(v)
                                                      return {
                                                        ...v,
                                                        name: e.target.value
                                                      }
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group w-100">
                                              <label
                                                htmlFor="exampleInputEmail1"
                                                className="ml-3"
                                              >
                                                Email
                                              </label>
                                              <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <span
                                                    className="input-group-text login__span px-2"
                                                    id="basic-addon1"
                                                  >
                                                    <img
                                                      src={Message}
                                                      alt=""
                                                      className="img-fluid login__img email_img"
                                                    />
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control login__input"
                                                  placeholder=""
                                                  aria-label="Username"
                                                  aria-describedby="basic-addon1"
                                                  name="email"
                                                  defaultValue={data.email}
                                                  onChange={(e) =>
                                                    setUpdatedData(v => {
                                                      return {
                                                        ...v,
                                                        email: e.target.value
                                                      }
                                                    }
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group w-100">
                                              <label
                                                htmlFor="exampleInputEmail1"
                                                className="ml-3"
                                              >
                                                Contact
                                              </label>
                                              <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <span
                                                    className="input-group-text login__span px-2"
                                                    id="basic-addon1"
                                                  >
                                                    <img
                                                      src={Phone}
                                                      alt=""
                                                      className="img-fluid login__img email_img"
                                                    />
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control login__input"
                                                  placeholder=""
                                                  aria-label="Username"
                                                  aria-describedby="basic-addon1"
                                                  name="email"
                                                  defaultValue={data.contact}
                                                  onChange={(e) =>
                                                    setUpdatedData(v => {
                                                      return {
                                                        ...v,
                                                        contact: e.target.value
                                                      }
                                                    }
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between w-100">
                                              <button
                                                className="btn alertBtn"
                                                style={{
                                                  color: "#1658e6",
                                                  background: "#ddd",
                                                }}
                                                onClick={onClose}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                className="btn btn-primary alertBtn"
                                                style={{
                                                  background: "#1658e6",
                                                  color: "white",
                                                }}
                                                onClick={(e) => {
                                                  setUpdatedData(v => {
                                                    let emailVal = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v.email);
                                                    if (emailVal) {
                                                      updateCustomerDetail(
                                                        data.customer_id, v.name, v.email, v.contact
                                                      ).then(res => {
                                                        notifySuccess("Customer Updated Successfully")
                                                        // onClose()
                                                        getCustomerDetail(data.customer_id).then((res) => {
                                                          if (res["data"]["shop_wizard"]["status"] === "1") {
                                                            setLoad(false);
                                                            setCustomerData(res["data"]["shop_wizard"]["customer"]);
                                                          }
                                                        });
                                                        getCustomers()
                                                      })
                                                      console.log(v)
                                                      return v
                                                    } else {
                                                      notifyError("Enter Correct Email")
                                                    }
                                                  })

                                                }}
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      },
                                    });
                                  }
                                }
                              ></i>
                              <i className="fa fa-trash text-danger"
                                onClick={
                                  () => {
                                    showConfirmDelete(
                                      () => {
                                        deleteCustomer(data.customer_id)
                                          .then(res => {
                                            getCustomers()
                                          })
                                      }
                                    )
                                  }
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row">
                <div className="col-12  d-flex justify-content-center align-items-center">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Customer;
