import React, { useEffect } from "react";
import Email from "../assets/email.png";
import emailIcon from "../assets/email.png";
import passwordImage from "../assets/password.png";
import "./Login.css";
import Load from './Loader'
// import { LoginUser } from '../services/httpService'
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { notifyError, notifySuccess } from "../util/Util";
import { forgotPassword, LoginUser, Reset_pass, Verify_token } from "../services/httpService";
import logo from "../assets/sw_logo.png";
import shop_pic from "../assets/shop.jpg"

import { useMediaQuery } from '@mui/material';


function Reset_password() {
    const matches_820 = useMediaQuery('(max-width:820px)');
    const history = useHistory();
    const { token } = useParams();
    const [email, setEmail] = useState("");
    const [confirm_passord, setconfirm_passord] = useState('');
    const [password, setPassword] = useState("");
    const [load, setLoad] = useState(false)
    const [type, setType] = useState("password");
    const [type1, setType1] = useState("password");
    const [showAlert, setShowAlert] = useState(false);
    const [vendor_id, setvendor_id] = useState('');

    function formatDateFromMilliseconds(milliseconds) {
        const date = new Date(milliseconds);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const passwordRegex = /^(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/;

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password) {
            if (confirm_passord == password) {
                if (passwordRegex.test(password)) {
                    setLoad(true);
                    Reset_pass(
                        vendor_id, password
                    ).then((res) => {
                        if (res["data"]["shop_wizard"]["status"] == "1") {
                            notifySuccess(res["data"]["shop_wizard"]["msg"])
                            history.push('/');
                        }
                        else{
                            notifySuccess(res["data"]["shop_wizard"]["msg"])
                            // history.push('/');
                        }
                        setLoad(false)
                    }).catch((err) => {
                        console.log(err);
                    })
                }
                else {
                    notifyError("Password should contain atleast one number and one special symbol!")
                }
            }
            else {
                notifyError("Passwords don't match!")
            }

        } else {
            let validate = [{ name: "Password", value: password }]
            validate.some(v => {
                if (v.value) {
                    return false
                } else {
                    notifyError("Please enter " + v.name)
                    return true
                }
            })
            // notifyError("Please enter values in all the fields")
        }
    };

    const verify_token = () => {
        Verify_token(token).then((res) => {
            // setvendor_id()
            if (res["data"]["shop_wizard"]["status"] == "1") {
                setvendor_id(res["data"]["shop_wizard"]["vendor_id"]);
            }
            else {
                notifyError(res["data"]["shop_wizard"]["msg"])
                history.push("/")
            }
        }).catch((err) => {
            console.log(err);
            notifyError('Some error occured')
        })
    }

    useEffect(() => {
        verify_token();
    }, [])


    return (
        // <div className="login">
        <>
            <Load load={load} />
            <section className="login_section">
                {/*<div className="container">
          <div className="row">
            <div className="col-12 mt-5 ml-3 py-4">
              <h2 className="font-weight-bold">Log in</h2>
            </div>
          </div>
  </div>*/}
                <div style={{ width: "100hw" }}>
                    <div className="row">
                        {
                            !matches_820 &&
                            <div className="col-4 p-0" style={{
                                background: "#1c1b3b url(" + shop_pic + ") no-repeat bottom center",
                                // backgroundImage: "url(" + shop_pic + ")",
                                position: "relative",
                                backgroundPosition: "bottom 0px ;",
                                // back
                                backgroundSize: "460px",
                                backgroundRepeat: "no-repeat",
                                display: "flex", justifyContent: "center", backgroundColor: "#1c1b3b", alignItems: "center"
                            }}>
                                <div style={{ position: "absolute", width: "400px", left: "90px", backgroundColor: "#1c1b3b", bottom: "15px", fontSize: "40px" }}>
                                    <span>Service at it's best!</span>
                                </div>
                                <img src={logo} style={{ position: "absolute", top: "90px" }} />
                            </div>
                        }
                        <div className={matches_820 ? "col-12 p-0" : "col-8 p-0"}>
                            <div className="card login_card" style={{ paddingTop: "15%" }}>
                                <div className="card-body">
                                    <div className="container mt-3" style={{ width: "60%" }} >
                                        <div className="row" >
                                            <h3 style={{ marginLeft: "20px", color: "#000", width: "100%" }}>Reset password!</h3>
                                            <p style={{ marginLeft: "20px", color: "#000", width: "100%" }}>Please enter password here!</p>
                                            <div className="col-12">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1" className="ml-3">
                                                        </label>
                                                        <div className="input-group mb-3">

                                                            <input
                                                                type={type1}
                                                                className="form-control login__input"
                                                                placeholder="Password"
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                name="Password"
                                                                value={password}
                                                                autoComplete="password"
                                                                onChange={(event) => {
                                                                    console.log(event.target.value.length < 16)
                                                                    let value = (event.target.value.length < 16) ? event.target.value : password
                                                                    console.log(value)
                                                                    setPassword(v => (value))
                                                                }}
                                                            />
                                                            <div className="input-group-append password_toggle" >
                                                                <span class="input-group-text login__span cursor-pointer" onClick={e => setType1(v => v === "password" ? "text" : "password")}>
                                                                    {type1 === "password" ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mt-5">
                                                        <div className="input-group mb-3"
                                                        //  style={{border:"1px solid #fff",borderRadius:"4px"}}
                                                        >

                                                            <input
                                                                type={type}
                                                                className="form-control login__input"
                                                                placeholder="Confirm Password"
                                                                aria-label="Password"
                                                                aria-describedby="basic-addon1"
                                                                name="password"
                                                                autoComplete="password"
                                                                value={confirm_passord}
                                                                onChange={(event) => {
                                                                    setconfirm_passord(event.target.value)
                                                                }
                                                                }
                                                            />
                                                            <div className="input-group-append password_toggle" >
                                                                <span class="input-group-text login__span cursor-pointer" onClick={e => setType(v => v === "password" ? "text" : "password")}>
                                                                    {type === "password" ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row mt-5 login__submit__row">
                                                        <div className="col-12">
                                                            <button className="btn login__submit_button">
                                                                Reset Password
                                                            </button>
                                                        </div>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    data-backdrop="false"
                    style={{ background: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content mx-auto" style={{ width: "410px", marginTop: "300px", marginLeft: '20px' }}>
                            {showAlert ? (
                                <div class="modal-body" >
                                    <h4>Alert</h4>
                                    <p className="text-dark font-weight-bolder mt-4">Rocovery link send to your mail successfully</p>
                                </div>
                            ) : (<div class="modal-body">
                                <div className="container mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1" className="ml-3">
                                                    Vender’s Name
                                                </label>
                                                <div className="input-group mb-3">

                                                    <input
                                                        type="text"
                                                        className="form-control login__input"
                                                        placeholder="Email"
                                                        aria-label="Email"
                                                        aria-describedby="basic-addon1"
                                                        name="email"
                                                        value={email}
                                                        onChange={(event) => setEmail(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                            {showAlert ? (
                                <div class="modal-footer" style={{ border: 'none', }}>
                                    <div className="d-flex ml-auto mr-3">
                                        <p className="text-dark font-weight-bold" data-dismiss="modal" onClick={() => setShowAlert(false)}>OK</p>
                                    </div>
                                </div>
                            ) : (<div class="modal-footer" style={{ border: 'none', }}>
                                <div className="row w-100">
                                    <div className="col-5">
                                        <button type="button" class="btn btn cancel_btn" data-dismiss="modal">Close</button>
                                    </div>
                                    <div className="col-2"></div>
                                    <div className="col-5">
                                        <button type="button" class="btn btn login__submit_button" onClick={(e) => {
                                            forgotPassword(email).then(res => {
                                                if (res["data"]["shop_wizard"]["status"] == "0") {
                                                    notifyError(res["data"]["shop_wizard"]["msg"])
                                                }
                                                if (res["data"]["shop_wizard"]["status"] == "1") {
                                                    setShowAlert(true)
                                                }
                                            })
                                        }}>Send Link</button>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Reset_password;
