import React, { useEffect, useState } from "react";
import Load from './Loader'
import back from "../assets/back.png";
import location from "../assets/location.png";
import googleLink from "../assets/Link.png";
import addLight from "../assets/addLight.png";
import add from "../assets/2997933.png"
import Tax from "../assets/tax.png";
import "./EditProfile.css";
import "./VendorProfile.css";
import { Link, useHistory } from "react-router-dom";
import { createProfile, getAllStates } from "../services/httpService";
import { notifyError, notifySuccess } from "../util/Util";
// import TimePicker from 'react-time-picker';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

import Loader from "./Loader";
import logo from "../assets/sw_logo.png";
import shop_pic from "../assets/shop.jpg"

import { useMediaQuery } from '@mui/material';



function VendorProfile() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const history = useHistory()
  const [image, setImage] = useState(null)
  const [load, setLoad] = useState(true)
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState("");
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState("");
  const [close, setClose] = useState("");
  const [url, setUrl] = useState("");
  const [tax, setTax] = useState("");
  const ventor_id = localStorage.getItem("vendor_id");
  useEffect(() => {
    getAllStates().then(res => {
      if (res.data.shop_wizard.status === "1") {
        setLoad(false)
        setStates(res.data.shop_wizard.state_data)
        // console.log(res.data.shop_wizard.state_data);
      }
    })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (stateId && address && open && close && image) {
      createProfile({ image, id: ventor_id, state: stateId, address, url, open, close, tax }).then((res) => {
        try {
          if (res["data"]["shop_wizard"]["status"] == "0") {
            notifyError(res["data"]["shop_wizard"]["msg"])
          }
          if (res["data"]["shop_wizard"]["status"] == "1") {
            history.push("/payment")
          }
        } catch (error) {
          notifyError("Something went wrong!")
        }
      });
    } else {
      let validate = [
        { name: "Opening time", value: open },
        { name: "Closing time", value: close },
        { name: "Address", value: address },
        { name: "State", value: stateId },
        { name: "Tax", value: tax },
        { name: "Image", value: image }
      ]
      validate.some(v => {
        if (v.value) {
          return false
        } else {
          notifyError("Please enter " + v.name)
          return true
        }
      })
      // notifyError("Please fill all the fields!")
    }
  };

  return (
    <>
      <Load load={load} />
      <section className="singup_section">
        <div style={{ width: "100hw", height: "100vh" }}>
          <div className="row" >
            {
              !matches_820 &&
              <div className="col-4 p-0" style={{
                background: "#1c1b3b url(" + shop_pic + ") no-repeat bottom center",
                // backgroundImage: "url(" + shop_pic + ")",
                position: "relative",
                backgroundPosition: "bottom 0px ;",
                // back
                height: "100vh",
                backgroundSize: "460px",
                backgroundRepeat: "no-repeat",
                display: "flex", justifyContent: "center", backgroundColor: "#1c1b3b", alignItems: "center"
              }}>
              <div style={{ position: "absolute",width:"400px",left:"90px", backgroundColor: "#1c1b3b",bottom:"15px",fontSize:"40px" }}>
                  <span>Service at it's best!</span>
              </div>
                <img src={logo} style={{ position: "absolute", top: "90px" }} />
              </div>
            }
            <div className={matches_820 ? "col-12 p-0" : "col-8 p-0"}>
              <div className="container">
                <div className="row">
                  <div className="col-12 mt-5 ml-3">
                    <h3 style={{ marginLeft: "105px", color: "#000", width: "100%" }}>Create Profile</h3>
                  </div>
                  <div className="header_options d-flex ml-auto">
                    <span className="mx-2" onClick={handleSubmit} style={{ cursor: "pointer" }}>Save</span>
                    <span className="ml-4 mr-5" onClick={e => {
                      history.push("/")
                    }} style={{ cursor: "pointer" }}>Login</span>
                  </div>
                </div>
              </div>
              <div className="vendor-profile_container card login-card mt-1" >
                <div className="addimage_container editImage cursor-pointer" style={{ paddingTop: "10px" }}>
                  <div className="roundImage">
                    <center>
                      <img className="imageSet" style={{ cursor: "pointer" }}
                        src={image ? URL.createObjectURL(image) : addLight} alt="" />
                    </center>  </div>
                  <input type="file" onChange={e => setImage(e.target.files[0])} style={{ cursor: "pointer" }} />
                  <h5 className="add-img-text font-weight-lighter mt-1 mx-3" style={{ color: "#495057", fontSize: "16px" }}>Vendor logo</h5>
                </div>
                <div style={{ padding: "0px 10%" }} className="vendor-profile mt-4">
                  <div className="shop-timing d-flex">
                    <div className="col-4" style={{ color: "#495057" }}>
                      Shop Timing
                    </div>
                    <div className="col-4">
                      <div className="show-input_container d-flex">
                        <TimePicker
                          showSecond={false}
                          defaultValue={moment().hour(0).minute(0)}
                          // className="h-25"
                          onChange={(e) => {
                            let h = new Date(e._d).getHours() < 10 ? "0" + new Date(e._d).getHours() : new Date(e._d).getHours();
                            let m = new Date(e._d).getMinutes() < 10 ? "0" + new Date(e._d).getMinutes() : new Date(e._d).getMinutes();
                            setOpen(h + ":" + m);
                          }}
                          format='h:mm a'
                          use12Hours
                          inputReadOnly
                          // fontSize='18px'
                          // className="h-[100px]"
                          // style={{fontSize:"16px"}}
                        />
                        {/*<input type="time" name="" max="12:00" id=""  onChange={e => setOpen(e.target.value)} value={open ? open : "00:00"} />*/}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="show-input_container d-flex">
                        <TimePicker
                          showSecond={false}
                          defaultValue={moment().hour(0).minute(0)}
                          className="xxx"
                          onChange={(e) => {
                            let h = new Date(e._d).getHours() < 10 ? "0" + new Date(e._d).getHours() : new Date(e._d).getHours();
                            let m = new Date(e._d).getMinutes() < 10 ? "0" + new Date(e._d).getMinutes() : new Date(e._d).getMinutes();
                            setClose(h + ":" + m);
                          }}
                          format='h:mm a'
                          use12Hours
                          inputReadOnly
                        />
                        {/*<input type="time" name="" id="" onChange={e => setClose(e.target.value)} value={close ? close : "00:00"} />*/}
                      </div>
                    </div>
                  </div>
                  <div className="data-inputs_container">
                    <div className="input_container d-flex">
                      <div style={{ width: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={location} style={{ height: "34px", maxWidth: "32px" }} alt="" />
                      </div>
                      <input type="text" className="w-100" placeholder="address" onChange={e => setAddress(e.target.value)} />
                    </div>
                    <div className="input_container d-flex">
                      <div style={{ width: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={location} style={{ height: "34px", maxWidth: "32px" }} alt="" />
                      </div>
                      {/* <input type="text" className="w-100" placeholder="State" /> */}
                      <select name="" id="" className="form-control" onChange={e => {
                        setStateId(e.target.value.split("/")[0])
                        setTax(e.target.value.split("/")[1])
                      }}>
                        <option selected disabled>Select state &nbsp;&nbsp;&nbsp;&nbsp;</option>
                        {states.map(val => (
                          <option value={val.state_id + "/" + val.state_tax} onClick={() => {
                            setTax(val.state_tax)
                          }}>{val.state_name}</option>
                        ))}
                      </select>
                      <div className="caret_down" style={{ color: '#666', position: "relative", left: '-30px' }}>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="input_container d-flex">
                      <div style={{ width: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={googleLink} style={{ height: "23px", maxWidth: "21px" }} alt="" />
                      </div>
                      <input
                        type="text"
                        className="w-100"
                        placeholder="Google-review URL"
                        onChange={e => setUrl(e.target.value)}
                      />
                    </div>
                    {/*<div className="input_container d-flex">
                <img src={Tax} alt="" />
                <input
                  type="number"
                  min="0"
                  className="w-100"
                  value={tax}
                  placeholder="Tax"
                  onChange={e => setTax(e.target.value)}
                  // readOnly
                  style={{backgroundColor:"#ddd"}}
                  disabled
                />
                <hr className="" />
                  </div>*/}
                    {/*<button className="filled-btn mt-5" onClick={handleSubmit}>Save</button>
                    <h5 className="or text-center text-dark my-3">OR</h5>
                    <button className="filled-btn" onClick={e => {
                      history.push("/")
                    }}>LOGIN</button>*/}
                    <div className="row mt-3">
                      <div className="col-12" style={{ display: "flex", justifyContent: "center" }}>
                        <button onClick={handleSubmit} className="btn login__submit_button mt-md-1" style={{ maxWidth: "350px" }}>
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default VendorProfile;
