import { useMediaQuery } from '@mui/material';
import React from 'react'
import Loader from 'react-loader-spinner'

const Load = ({ load }) => {
    const matches_545 = useMediaQuery('(max-width:545px)');
    const matches_820 = useMediaQuery('(max-width:820px)');
    return (
        load ? (
            <div className="d-flex position-fixed"
                style={{ zIndex: "1000", right: "32%", top: matches_820 ? (matches_545 ? "0%" : "2%") : "2%", width: matches_820 ? (matches_545 ? "130px" : "320px"):"450px", height: "100vh", opacity: "0.5" }}>
                <div className="mx-auto my-auto">
                    <Loader
                        type="Puff"
                        color="#1758e7"
                        height={100}
                        width={100}
                        visible={true}
                    />
                </div>
            </div>
        ) : null
    )
}

export default Load;
