import React, { useEffect, useRef } from "react";
import "./AddCustomer.css";
import back from "../assets/back.png";
// import { Link } from "react-router-dom";
import humburger from "../assets/humburger.png";
import profileIcon from "../assets/customer.png";
import emailIcon from "../assets/email.png";
import passwordIcon from "../assets/password.png";
import phoneIcon from "../assets/phone.png";
import scannerIcon from "../assets/scanner.png";
import crossIcon from "../assets/remove.png";
import checkIcon from "../assets/checked_new.png";
// import { useHistory } from "react-router-dom";
import { useState } from "react";
import { addCustomer, getVINData, hitCarsApi } from "../services/httpService";
import ResizeFile, { notifyError, notifySuccess } from "../util/Util";
import Loader from "./Loader";
import { useMediaQuery } from "@mui/material";
import { extractTextFromImage } from "textifyimage";
import DialogBox from "./DialogBox";
import Img_to_text from "./Img_to_text";

var interval
function AddCustomer_popup({
    func_objects,
    onClose
}) {

    const fileInputRef = useRef(null);

    const handleIconClick = (e) => {
        // e.preventDefault();
        // console.log('input opened');
        fileInputRef.current.click();
    };

    const VINRegx = /^[A-HJ-NPR-Z0-9]{17}$/i;

    const { vehicleData,
        setVehicleData,
        setCustomerId,
        setVin_app,
        setReg_app, setSelectValue } = func_objects;

    const matches_820 = useMediaQuery('(max-width:820px)');
    // const history = useHistory();
    const ventor_id = localStorage.getItem("vendor_id");
    const [username, setUsername] = useState("");
    const [load, setLoad] = useState(false);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [vin, setVin] = useState(null);
    const [reg, setReg] = useState("");
    const [imgtext, setimgtext] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selected_img, setselected_img] = useState(null);
    const [cropped_img, setcropped_img] = useState(null);
    const [vin_verified, setvin_verified] = useState(false);
    const [carData, setCarData] = useState({
        name: "CAR NAME",
        color: "COLOR",
        city: "CITY",
        fuelType: "FUEL TYPE",
        length: "LENGTH",
        state: "STATE",
        model: "MODEL",
        year: "YEAR",
    });

    const handleSubmit = () => {
        // event.preventDefault();
        // alert(vin)
        const headers = {
            "Content-Type": "text/plain",
        };
        setLoad(true)
        let emailVal = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
        if (email ? emailVal : true) {
            if (username != ""
                //  && email != "" && phone != "" && !/^[A-Z]+$/i.test(phone)
            ) {
                console.log(username, email, phone, vin);
                addCustomer(ventor_id, username, email, phone, (vin || '-'), reg)
                    .then((res) => {

                        try {
                            setLoad(false)
                            if (res["data"]["shop_wizard"]["status"] == "0") {
                                alert(res["data"]["shop_wizard"]["msg"])
                            }
                            if (res["data"]["shop_wizard"]["status"] == "1") {
                                // notifySuccess("Customer Added Successfully");
                                console.log({ func_objects, res });
                                setVehicleData(
                                    [
                                        ...vehicleData,
                                        {
                                            customer_id: res["data"]["shop_wizard"]["customer_id"],
                                            customer_name: username,
                                            vin_number: vin,
                                            reg_number: reg,
                                        }
                                    ]
                                );
                                setCustomerId(res["data"]["shop_wizard"]["customer_id"])
                                setVin_app(vin);
                                (document.getElementById("date_input") && document.getElementById("date_input")?.focus());
                                setReg_app(reg);
                                if (setSelectValue) {
                                    setSelectValue({
                                        label: username,
                                        value: {
                                            customer_id: res["data"]["shop_wizard"]["customer_id"],
                                            customer_name: username,
                                            vin_number: vin,
                                            reg_number: reg,
                                        }
                                    })
                                }
                                onClose();

                                // alert("Customer Added Successfully")
                                // history.push("/customer");
                            }
                        } catch (error) {
                            console.log(error);
                            alert("Something went wrong!")
                            // console.log("cust add",error)
                        }
                    });
            } else {
                setLoad(false)
                let validate = [
                    { name: "Username", value: username },
                    // { name: "Email", value: email },
                    // { name: "Phone", value: phone }
                    // { name: "VIN no.", value: vin }
                ]
                validate.some(v => {
                    if (v.value) {
                        return false
                    } else {
                        setLoad(false);
                        alert("Please enter " + v.name)
                        // alert("Please enter " + v.name);
                        return true
                    }
                })
            }
        }
        else {
            alert("Enter Correct Email");
            setLoad(false);
        }
    };

    useEffect(() => {
        let vinN = vin
        if (vin) {
            clearTimeout(interval)
            interval = setTimeout(() => {
                getVINData(vinN).then((data) => {
                    if (data.data.success) {
                        let carDetails = data.data
                        let name = carDetails.attributes.make
                        let color = carDetails.attributes.exterior_color.length ? carDetails.attributes.exterior_color[0] : ""
                        let city = carDetails.attributes.made_in_city
                        let fuelType = carDetails.attributes.fuel_type
                        let length = carDetails.attributes.overall_length
                        let state = carDetails.attributes.made_in
                        let model = carDetails.attributes.model
                        let year = carDetails.attributes.year
                        setCarData({
                            name: name ? name : carData.name,
                            color: color ? color : carData.color,
                            city: city ? city : carData.city,
                            fuelType: fuelType ? fuelType : carData.fuelType,
                            length: length ? length : carData.length,
                            state: state ? state : carData.state,
                            model: model ? model : carData.model,
                            year: year ? year : carData.year,
                        })
                    }
                })
            }, 500)
        }
    }, [vin]);

    const check_vin_num = (vin) => {
        hitCarsApi(vin).then((resp) => {
            // console.log(resp);
            setLoad(false);
            setvin_verified(resp.data?.shop_wizard?.status);
            setVin(vin);
        }).catch((err) => {
            setLoad(false);
            console.log({ err });
        });
    }

    useEffect(() => {
        if (imgtext) {
            if (VINRegx.test(imgtext)) {
                check_vin_num(imgtext);
                // setVin(imgtext);
            }
            else {
                setLoad(false);
                notifyError(`Couldn't get the text, use a clearer picture please!`)
            }
        }
    }, [imgtext]);

    useEffect(async () => {
        if (cropped_img) {
            // processImage(cropped_img);
            setLoad(true);
            const text = await extractTextFromImage(cropped_img);
            const result = text.replace(/[^A-Z0-9]/g, '')
            setimgtext(result);
            // console.log({ text: text.replace(/[^A-Z0-9]/g, '') });
        }
    }, [cropped_img]);

    return (
        <>
            <Loader load={load} />
            <div
                style={{ backgroundColor: "#fff", padding: "8px", borderRadius: "5px", width: "500px" }}
                className="container addCustomer__content mt-5">
                <div style={{ borderBottom: "1px solid #c9c9c9", textAlign: "center" }}>
                    <h5>Create Customer</h5>
                </div>
                <div className="row mt-3">
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                    >
                                        <i class="fa fa-user-o" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control login__input"
                                    placeholder="Customer Name"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="username"
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                    >
                                        <img
                                            src={emailIcon}
                                            alt=""
                                            className="img-fluid"
                                            style={{ height: "15px" }}
                                        />
                                    </span>
                                </div>
                                <input
                                    type="email"
                                    className="form-control login__input"
                                    placeholder="Email"
                                    aria-label="email"
                                    aria-describedby="basic-addon1"
                                    name="email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                    >
                                        <img
                                            src={phoneIcon}
                                            alt=""
                                            className="img-fluid login__img"
                                        />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control login__input"
                                    placeholder="Mobile"
                                    aria-label="mobile"
                                    aria-describedby="basic-addon1"
                                    name="phone"
                                    minLength="10"
                                    maxLength="10"
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                    >
                                        <img
                                            src={passwordIcon}
                                            alt=""
                                            className="img-fluid login__img"
                                        />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control login__input"
                                    placeholder="VIN No./Car Reg No."
                                    aria-label="mobile"
                                    aria-describedby="basic-addon1"
                                    name="vin"
                                    maxLength="17"
                                    value={vin}
                                    onChange={(event) => setVin(event.target.value)}
                                />
                                {
                                    (!vin_verified) && (imgtext) && (vin) &&
                                    <div className="input-group-append" style={{ marginTop: "-10px" }}>
                                        <span
                                            className="input-group-text login__span"
                                            id="basic-addon1"
                                            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >
                                            <img src={crossIcon}
                                                style={{ cursor: "pointer", height: "10px" }}
                                                alt=""
                                                className="img-fluid login__img"
                                            // onClick={handleIconClick}
                                            />
                                            &nbsp;
                                            <span style={{ color: "red", fontSize: "14px" }}>Invalid</span>
                                        </span>
                                    </div>
                                }

                                {
                                    vin_verified &&
                                    <div className="input-group-append" style={{ marginTop: "-10px" }}>
                                        <span
                                            className="input-group-text login__span"
                                            id="basic-addon1"
                                            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >
                                            <img src={checkIcon}
                                                style={{ cursor: "pointer", height: "10px" }}
                                                alt=""
                                                className="img-fluid login__img"
                                            // onClick={handleIconClick}
                                            />
                                            &nbsp;
                                            <span style={{ color: "green", fontSize: "14px" }}>Verified</span>
                                        </span>
                                    </div>
                                }
                                <div className="input-group-append" style={{ marginTop: "-10px" }}>
                                    <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                    >
                                        <img src={scannerIcon}
                                            style={{ cursor: "pointer" }}
                                            alt=""
                                            className="img-fluid login__img"
                                            onClick={handleIconClick}
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                ResizeFile(e.target.files[0]).then((result) => {
                                                    setselected_img(result);
                                                    setIsOpen(true);
                                                }).catch((err) => {
                                                    console.log(err);
                                                })
                                            }}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                    </span>
                                </div>
                            </div>


                            {/* <div className="row mt-5">
                    <div className="col-5 offset-1 bottomBorder mt-3">
                      <h6 className="text-dark">{carData.name}</h6>
                    </div>
                    <div className="col-5 offset-1 ml-1 d-flex justify-content-end bottomBorder mt-3">
                      <h6 className="profile__content__right text-dark">
                        {carData.year}
                      </h6>
                    </div>
                  </div> */}

                            {/* <div className="row mt-4">
                    <div className="col-5 offset-1 bottomBorder">
                      <h6 className="text-dark">{carData.model}</h6>
                    </div>
                    <div className="col-5 offset-1 ml-1 d-flex justify-content-end bottomBorder">
                      <h6 className="profile__content__right text-dark">
                        {carData.state}
                      </h6>
                    </div>
                  </div> */}

                            {/* <div className="row mt-4">
                    <div className="col-5 offset-1 bottomBorder">
                      <h6 className="text-dark">{carData.color}</h6>
                    </div>
                    <div className="col-5 offset-1 ml-1 d-flex justify-content-end bottomBorder">
                      <h6 className="profile__content__right text-dark">
                        {carData.city}
                      </h6>
                    </div>
                  </div> */}

                            {/* <div className="row mt-4">
                    <div className="col-5 offset-1 bottomBorder">
                      <h6 className="text-dark">{carData.fuelType}</h6>
                    </div>
                    <div className="col-5 ml-1 d-flex justify-content-end bottomBorder">
                      <h6 className="profile__content__right text-dark">
                        {carData.length}
                      </h6>
                    </div>
                  </div> */}
                        </div>
                        <div className="row my-2">
                            <div className="col-6">
                                <button className="btn close_add_cust_btn__button" onClick={onClose}>
                                    Close</button>

                            </div>
                            <div className="col-6">
                                <button className="btn addAppointment__button" onClick={handleSubmit}>Save</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                selected_img &&
                <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} buttonDisplay="none" >
                    <Img_to_text src={selected_img} setCropped_img={setcropped_img} setIsOpen={setIsOpen} />
                </DialogBox>
            }
        </>
    );
}

export default AddCustomer_popup;
