import React, { useEffect } from "react";
import Email from "../assets/email.png";
import emailIcon from "../assets/email.png";
import passwordImage from "../assets/password.png";
import "./Login.css";
import Load from './Loader'
// import { LoginUser } from '../services/httpService'
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { useState } from "react";
import { notifyError, notifySuccess } from "../util/Util";
import { forgotPassword, LoginUser } from "../services/httpService";
import logo from "../assets/sw_logo.png";
import shop_pic from "../assets/shop.jpg"

import { useMediaQuery } from '@mui/material';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


function Login() {
  const history = useHistory();
  const matches_820 = useMediaQuery('(max-width:820px)');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false)
  const [type, setType] = useState("password");
  const [showAlert, setShowAlert] = useState(false);

  function formatDateFromMilliseconds(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email && password) {
      setLoad(true)
      LoginUser(email, password).then(async (res) => {
        console.log(res["data"]["shop_wizard"]);
        try {
          if (res["data"]["shop_wizard"]["status"] == "0") {
            setLoad(false)
            notifyError(res["data"]["shop_wizard"]["msg"]);
          }
          if (res["data"]["shop_wizard"]["status"] == "1") {
            console.log(res["data"]["shop_wizard"]);
            const sub_arr = await checkSubscriptionStatus(res["data"]["shop_wizard"]["customer_id"], res["data"]["shop_wizard"]["susbscription_id"]);
            console.log(sub_arr);
            // const subscription_data = sub_arr?.data.filter((el) => {
            //   return el.id == res["data"]["shop_wizard"]["susbscription_id"]
            // })[0]
            const subscription_data = sub_arr;
            // get_data(res["data"]["shop_wizard"]["susbscription_id"])
            const today = new Date().getTime();
            const valid_till = res["data"]["shop_wizard"]["valid_till"]
            localStorage.setItem(
              "vendor_id",
              res["data"]["shop_wizard"]["vendor_id"]
            );
            localStorage.setItem('subscription_cancelled', res["data"]["shop_wizard"]["subscription_cancelled"])
            localStorage.removeItem('vendor_data');
            const subscription_till = formatDateFromMilliseconds(subscription_data?.end_date);
            if ((res["data"]["shop_wizard"]['is_trial'] == 1) || ((valid_till >= 0) && res["data"]["shop_wizard"]["susbscription_id"])) {
              localStorage.setItem('subscription_data', JSON.stringify({
                sub_id: res["data"]["shop_wizard"]["susbscription_id"],
                valid_till: res["data"]["shop_wizard"]["subscription_till"].slice(0, 10)
              }));
              history.push("/dashboard");
            }
            else if (subscription_data?.end_date >= today) {
              fetch('https://demoapps.in/index.php/Shop_controller/update_subscribe_date', {
                method: 'POST',
                body: JSON.stringify({
                  shop_wizard: {
                    is_trial: 0, subscription_till, vendor_id: res["data"]["shop_wizard"]["vendor_id"]
                  }
                })
              }).then((res) => {
                if (!(res["data"]["shop_wizard"]["shop_state"] != 0)) {
                  localStorage.setItem(
                    "vendor_name",
                    res["data"]["shop_wizard"]["name"]
                  );
                  history.push("/vendor-profile");
                }
                else {
                  localStorage.setItem('subscription_data', JSON.stringify({
                    sub_id: res["data"]["shop_wizard"]["susbscription_id"],
                    valid_till: subscription_till
                  }))
                  history.push("/dashboard");
                }
              }).catch((err) => {
                // console.log(err);
                notifyError('Some error occured')
              });
            }
            else if (!(res["data"]["shop_wizard"]["shop_state"] != 0)) {
              localStorage.setItem(
                "vendor_name",
                res["data"]["shop_wizard"]["name"]
              );
              history.push("/vendor-profile");
            }
            else {
              localStorage.setItem('vendor_reg_email', email);
              localStorage.setItem(
                "vendor_name",
                res["data"]["shop_wizard"]["name"]
              );
              history.push("/payment");
            }

            // console.log(subscription_data);

            setLoad(false);

          }
        } catch (error) {
          console.log(error);
          notifyError("Something went wrong")
        }
      });
    } else {
      let validate = [{ name: "Email", value: email }, { name: "Password", value: password }]
      validate.some(v => {
        if (v.value) {
          return false
        } else {
          notifyError("Please enter " + v.name)
          return true
        }
      })
      // notifyError("Please enter values in all the fields")
    }
  };

  const get_sub_status = (customerId) => {
    const secretKey = 'sk_test_51NoyikIw3CIjvUYbi2pZbw5UlRaFvsWgICLsnp0exzWkZaMKZ1Sx1caguKCbYNsU8gO9hsvJB9JRfGfar2k1NsP800NXK0Ze6o';

    // const subscriptionScheduleId = `sub_1NvzSuIw3CIjvUYbIvNw9QId`;



    return fetch(`https://api.stripe.com/v1/subscriptions?customer=${customerId}&status=active`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${secretKey}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
  }

  async function checkSubscriptionStatus(customerId, sub_id) {
    if (customerId.length > 0) {
      const secretKey = 'sk_test_51NoyikIw3CIjvUYbi2pZbw5UlRaFvsWgICLsnp0exzWkZaMKZ1Sx1caguKCbYNsU8gO9hsvJB9JRfGfar2k1NsP800NXK0Ze6o';
      // const apiKey = Constants.sk_test_key;
      try {
        const apiUrl = `https://api.stripe.com/v1/subscriptions?customer=${customerId}&status=active`;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${secretKey}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          this.props.navigation.navigate('SubscribeScreen');
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.data && data.data.length > 0) {
          return {
            hasActiveSubscription: true,
            end_date: data.data.filter((el) => {
              return el.id == sub_id
            })[0].current_period_end * 1000,
          };
        } else {
          return { hasActiveSubscription: false };
        }
      } catch (error) {
        console.error('Error checking subscription status:', error);
        throw error;
      }
    }
    else {
      return;
    }
  }


  return (
    // <div className="login">
    <>
      <Load load={load} />
      <section className="login_section">
        {/*<div className="container">
          <div className="row">
            <div className="col-12 mt-5 ml-3 py-4">
              <h2 className="font-weight-bold">Log in</h2>
            </div>
          </div>
  </div>*/}
        <div style={{ width: "100hw" }}>
          <div className="row">
            {
              !matches_820 &&
              <div className="col-4 p-0" style={{
                background: "#1c1b3b url(" + shop_pic + ") no-repeat bottom center",
                // backgroundImage: "url(" + shop_pic + ")",
                position: "relative",
                backgroundPosition: "bottom 0px ;",
                // back
                backgroundSize: "460px",
                backgroundRepeat: "no-repeat",
                display: "flex", justifyContent: "center", backgroundColor: "#1c1b3b", alignItems: "center"
              }}>
                <div style={{ position: "absolute", width: "100%", left: "90px", backgroundColor: "#1c1b3b", bottom: "15px", fontSize: "40px" }}>
                  <span>Service at it's best!</span>
                </div>
                <img src={logo} style={{ position: "absolute", top: "90px" }} />
              </div>
            }
            <div className={matches_820 ? "col-12 p-0" : "col-8 p-0"}>
              <div className="card login_card" style={{ paddingTop: "15%" }}>
                <div className="card-body">
                  <div className="container mt-3" style={{ width: matches_820 ? "100%" : "60%" }} >
                    <div className="row" >
                      <h3 style={{ marginLeft: "20px", color: "#000", width: "100%" }}>Welcome Back!</h3>
                      <p style={{ marginLeft: "20px", color: "#000", width: "100%" }}>Please enter your credentials to sign in!</p>
                      <div className="col-12">
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1" className="ml-3">
                            </label>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text login__span px-2"
                                  id="basic-addon1"
                                >
                                  <img
                                    src={Email}
                                    // style={{backgroundColor:"#fff"}}
                                    alt=""
                                    className="img-fluid login__img email_img"
                                  />
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control login__input"
                                placeholder="Email id"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="email"
                                value={email}
                                autoComplete="password"
                                onChange={(event) => setEmail(event.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group mt-5">
                            <div className="input-group mb-3"
                            //  style={{border:"1px solid #fff",borderRadius:"4px"}}
                            >
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text login__span"
                                  id="basic-addon1"
                                >
                                  <img
                                    src={passwordImage}
                                    // style={{backgroundColor:"#fff"}}
                                    alt=""
                                    className="img-fluid login__img"
                                  />
                                </span>
                              </div>
                              <input
                                type={type}
                                className="form-control login__input"
                                placeholder="Password"
                                aria-label="Password"
                                aria-describedby="basic-addon1"
                                name="password"
                                autoComplete="password"
                                value={password}
                                onChange={(event) => {
                                  console.log(event.target.value.length < 16)
                                  let value = (event.target.value.length < 16) ? event.target.value : password
                                  console.log(value)
                                  setPassword(v => (value))
                                }
                                }
                              />
                              <div className="input-group-append password_toggle" >
                                <span class="input-group-text login__span cursor-pointer" onClick={e => setType(v => v === "password" ? "text" : "password")}>
                                  {type === "password" ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                </span>
                              </div>
                            </div>
                            <small className="login__small cursor-pointer" data-toggle="modal" data-target="#exampleModal">
                              Forget Password
                            </small>
                          </div>

                          <div className="row mt-5 login__submit__row">
                            <div className="col-12">
                              <button className="btn login__submit_button">
                                Login
                              </button>
                            </div>
                          </div>

                          <div className="row login__submit__text mt-3">
                            <div className="col-12">
                              <p>
                                Don’t have an Account?{" "}
                                <b>
                                  <Link to="/Signup">Sign up here</Link>
                                </b>
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="false"
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content mx-auto" style={{ width: "410px", marginTop: "300px", marginLeft: '20px' }}>
              {showAlert ? (
                <div class="modal-body" >
                  <h4>Alert</h4>
                  <p className="text-dark font-weight-bolder mt-4">Rocovery link send to your mail successfully</p>
                </div>
              ) : (<div class="modal-body">
                <div className="container mt-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="ml-3">
                          Vender’s Name
                        </label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text login__span"
                              id="basic-addon1"
                            >
                              <img
                                src={emailIcon}
                                alt=""
                                className="img-fluid login__img"
                              />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control login__input"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="basic-addon1"
                            name="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
              {showAlert ? (
                <div class="modal-footer" style={{ border: 'none', }}>
                  <div className="d-flex ml-auto mr-3">
                    <p className="text-dark font-weight-bold" data-dismiss="modal" onClick={() => setShowAlert(false)}>OK</p>
                  </div>
                </div>
              ) : (<div class="modal-footer" style={{ border: 'none', }}>
                <div className="row w-100">
                  <div className="col-5">
                    <button type="button" class="btn btn cancel_btn" data-dismiss="modal">Close</button>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-5">
                    <button type="button" class="btn btn login__submit_button" onClick={(e) => {
                      forgotPassword(email).then(res => {
                        if (res["data"]["shop_wizard"]["status"] == "0") {
                          notifyError(res["data"]["shop_wizard"]["msg"])
                        }
                        if (res["data"]["shop_wizard"]["status"] == "1") {
                          setShowAlert(true)
                        }
                      })
                    }}>Send Link</button>
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
