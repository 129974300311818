import axios from "axios"
// import { useState } from "react";
const BASE_URL = "https://demoapps.in/index.php/";
// const BASE_URL = "http://192.168.29.52:44/demoapps.in/";

export const LoginUser = (email, password) => {
    console.log(email, password);
    return axios.post(BASE_URL + "Shop_controller/login",
        {
            shop_wizard: {
                email: email,
                password: password,
            },
        }
    )
}

// export const changeState = ()=>{
//     const [num, setnum] = useState(0);
//     return ({
//         num:num,
//         setnum:setnum
//     })
// }

export const getDashBoard = (vendor_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/dashboard", {
            shop_wizard: {
                vendor_id,
            },
        })
}

export const signUp = (name, email, contact, password) => {
    return axios
        .post(
            BASE_URL + "Shop_controller/vendor_sign_up",
            {
                shop_wizard: {
                    name,
                    email,
                    contact,
                    password,
                },
            }
        )
}

export const addAppointment = (customer_id, vendor_id, vin_number, reg_number, date_time) => {
    console.log(customer_id, vendor_id, vin_number, reg_number, date_time);
    return axios
        .post(
            BASE_URL + "Shop_controller/customer_appointment",
            {
                shop_wizard: {
                    customer_id,
                    vendor_id,
                    vin_number,
                    reg_number,
                    date_time,
                },
            }
        )
}

export const editAppointment = (appointment_id, date_time, customer_id, vin_number, customer_name) => {
    return axios
        .post(
            BASE_URL + "Shop_controller/edit_appointment",
            {
                shop_wizard: {
                    appointment_id,
                    date_time,
                    customer_id,
                    vin_number,
                    customer_name
                },
            }
        )
}

export const sendEmail = (emails, subject, message,flyer) => {
    console.log(emails, subject, message);
    const recipients = emails.map(email => {
        return email

    })
    return axios
        .post(
            BASE_URL + "Shop_controller/send_email",
            {
                shop_wizard: {
                    recipients, subject, message,flyer
                },

            }
        )
}

export const sendInvoiceEmail = (email, invoice_number, message) => {
    // const email_ids = emails.map(email=>{
    //     return {
    //         email: email
    //     }
    // })
    return axios
        .post(
            BASE_URL + "Shop_controller/email_invoice",
            {
                shop_wizard: {
                    email, invoice_number, message
                },
            }
        )
}

export const addCustomer = (
    vendor_id,
    name,
    email,
    contact,
    vin_number,
    reg_number) => {
    return axios
        .post(
            BASE_URL + "Shop_controller/customer_registration",
            {
                shop_wizard: {
                    vendor_id,
                    name,
                    email,
                    contact,
                    vin_number,
                    reg_number,
                },
            }
        )
}

export const getProfile = (vendor_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/get_profile", {
            shop_wizard: {
                vendor_id,
            },
        })
}

export const getAllAppointment = (vendor_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/all_appointment", {
            shop_wizard: {
                vendor_id,
            },
        })
}


export const getAllCustomer = (vendor_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/customer_list", {
            shop_wizard: {
                vendor_id,
            },
        })
}


export const getAllVehicle = (vendor_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/all_vehicle", {
            shop_wizard: {
                vendor_id,
            },
        })
}

export const getAllInvoice = (vendor_id, invoice_status, date_from, date_to) => {
    // alert(invoice_status == "" || invoice_status == undefined ? "" : parseInt(invoice_status))
    console.log('dfgdf');
    console.log({
        shop_wizard: {
            vendor_id: vendor_id,
            invoice_status: invoice_status == "" || invoice_status == undefined ? -1 : parseInt(invoice_status),
            date_from: date_from == "" || date_from == undefined ? "" : date_from,
            date_to: date_to == "" || date_to == undefined ? "" : date_to,
        }
    });
    return axios
        .post(BASE_URL + "Shop_controller/all_invoice", {
            shop_wizard: {
                vendor_id: vendor_id,
                invoice_status: invoice_status == "" || invoice_status == undefined ? -1 : parseInt(invoice_status),
                date_from: date_from == "" || date_from == undefined ? "" : date_from,
                date_to: date_to == "" || date_to == undefined ? "" : date_to,
            },




        })
}


export const searchCustomer = (vendor_id, name) => {
    return axios
        .post(BASE_URL + "Shop_controller/search_customer", {
            shop_wizard: {
                vendor_id,
                name
            },
        })
}

export const deleteCustomer = (customer_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/delete_customer", {
            shop_wizard: {
                customer_id,
            },
        })
}

export const getVINData = (vin) => {
    return axios.post(BASE_URL + "Shop_controller/car_detail", {
        "shop_wizard": {
            "vin_number": vin
            // "vin_number":"1FTEW1CP3KKE75913"
        }
    })
}

export const getAllMessages = (vendor_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/get_all_message", {
            shop_wizard: {
                vendor_id,
            },
        })
}


export const getAllEstimate = (vendor_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/all_estimate", {
            shop_wizard: {
                vendor_id,
            },
        })
}




export const generateInvoice = (data) => {
    console.log(data);
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    var today = dd + '-' + mm + '-' + yyyy;
    return axios
        .post(BASE_URL + "Shop_controller/generate_invoice", {
            shop_wizard: { ...data, date_time: today },
        })
}


export const updateInvoice = (data) => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    var today = dd + '-' + mm + '-' + yyyy;
    return axios
        .post(BASE_URL + "Shop_controller/update_invoice", {
            shop_wizard: { ...data, date_time: today },
        })
}


export const saveEstimate = (data) => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    var today = dd + '-' + mm + '-' + yyyy;
    return axios
        .post(BASE_URL + "Shop_controller/invoice", {
            shop_wizard: { ...data, date_time: today },
        })
}

export const getInvoiceDetail = (id) => {
    return axios
        .post(BASE_URL + "Shop_controller/invoice_detail", {
            shop_wizard: {
                invoice_id: id
            },
        })
}

export const printInvoice = (id, vin_num, cust_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/print_invoice", {
            shop_wizard: {
                invoice_id: id,
                vin_number: vin_num,
                customer_id: cust_id
            },
        })
}



export const editMessage = (template_id, desc, title) => {
    return axios
        .post(BASE_URL + "Shop_controller/edit_message", {
            shop_wizard: {
                template_desc: desc,
                template_id,
                subject: title
            },
        })
}
export const addMessage = (vendor_id, type, desc, title) => {
    return axios
        .post(BASE_URL + "Shop_controller/create_message", {
            shop_wizard: {
                vendor_id,
                template_desc: desc,
                template_type: type,
                subject: title
            },
        })
}

export const deleteMessage = (template_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/delete_message", {
            shop_wizard: {
                template_id,
            },
        })
}

export const deleteAppointment = (appointment_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/delete_appointment", {
            shop_wizard: {
                appointment_id,
            },
        })

}

export const getCustomerDetail = (customer_id, vendor_id) => {
    return axios
        .post(BASE_URL + "Shop_controller/customer_detail", {
            shop_wizard: {
                customer_id,
                vendor_id
            },
        })
}


export const forgotPassword = (email) => {
    return axios
        .post(BASE_URL + "Shop_controller/forget_password", {
            shop_wizard: {
                email,
            },
        })
}


export const getAllStates = () => {
    return axios
        .post(BASE_URL + "Shop_controller/get_all_state")
}

export const updateProfile = ({ image, name, id, state, address, url, tax }) => {
    let formData = new FormData()
    if (image) {
        formData.append("profile_pic", image)
    }
    formData.append("name", name)
    formData.append("vendor_id", id)
    formData.append("shop_state", state)
    formData.append("address", address)
    formData.append("tax", tax)
    formData.append("google_review_url", url)
    return axios
        .post(BASE_URL + "Vendor/update_profile", formData, { headers: { "Content-Type": "multipart/form-data" } })
}

export const updateCustomerDetail = (customer_id, name, email, contact) => {
    return axios.post(
        BASE_URL + "Shop_controller/update_customer_detail",
        {
            shop_wizard: {
                customer_id,
                name,
                email,
                contact
            },
        }
    );
};

export const payInvoice = (invoice_id) => {
    return axios.post(
        BASE_URL + "Shop_controller/print_invoice",
        {
            shop_wizard: {
                invoice_id
            },
        }
    );
};

export const updateCustomerVehicle = (vehicle_id, vin_number, reg_number) => {
    return axios.post(
        BASE_URL + "Shop_controller/update_customer_vehicle",
        {
            shop_wizard: {
                vehicle_id,
                vin_number,
                reg_number,
            },
        }
    );
};



export const createProfile = ({ image, open, close, id, state, address, url, tax }) => {
    let formData = new FormData()
    // if (image) {
    formData.append("profile_pic", image)
    formData.append("vendor_id", id)
    formData.append("address", address)
    formData.append("shop_state", state)
    formData.append("google_review_url", url)
    formData.append("tax", tax)
    // }
    formData.append("shop_open_time", open)
    formData.append("shop_close_time", close)
    return axios
        .post(BASE_URL + "Vendor/vendor_profile", formData, { headers: { "Content-Type": "multipart/form-data" } })
}



export const Verify_token = (reset_key) => {
    // console.log(email, password);
    return axios.post(BASE_URL + "Shop_controller/verify_reset_key",
        {
            shop_wizard: {
                reset_key
            }
        }
    )
}

export const Reset_pass = (vendor_id, password) => {
    console.log(vendor_id, password);
    return axios.post(BASE_URL + "Shop_controller/reset_password",
        {
            shop_wizard: {
                vendor_id: vendor_id,
                password: password,
            },
        }
    )
}

export const get_invoice_summary_api = (data) => {
    // console.log(vendor_id, password);
    return axios.post(BASE_URL + "Shop_controller/get_invoices",
        {
            shop_wizard: data,
        }
    )
}

export const hitCarsApi = (vin) => {
    return axios.post(BASE_URL + "Shop_controller/check_vin",
        {
            shop_wizard: {
                vin_number: vin
            }
        }
    )
};

export const save_notes_api = (notes, invoice_id) => {
    return axios.post(BASE_URL + "Shop_controller/save_invoice_notes",
        {
            shop_wizard: {
                invoice_id,
                notes
            }
        }
    )
};

export const delete_notes_api = (notes_id) => {
    return axios.post(BASE_URL + "Shop_controller/delete_invoice_notes",
        {
            shop_wizard: {
                notes_id
            }
        }
    )
};

export const save_vendor_policy = (vendor_id, policy) => {
    return axios.post(BASE_URL + "Shop_controller/save_vendor_policy",
        {
            shop_wizard: {
                vendor_id, policy
            }
        }
    )
};

export const delete_vendor_policy = (policy_id) => {
    return axios.post(BASE_URL + "Shop_controller/delete_vendor_policy",
        {
            shop_wizard: {
                policy_id
            }
        }
    )
};

export const update_vendor_policy = (policy_id, policy) => {
    return axios.post(BASE_URL + "Shop_controller/update_vendor_policy",
        {
            shop_wizard: {
                policy_id, policy
            }
        }
    )
};

export const get_vendor_policy = (vendor_id) => {
    return axios.post(BASE_URL + "Shop_controller/get_vendor_policy",
        {
            shop_wizard: {
                vendor_id
            }
        }
    )
};

export const get_customer_account = (vendor_id) => {
    return axios.post(BASE_URL + "Shop_controller/customer_account",
        {
            shop_wizard: {
                vendor_id
            }
        }
    )
};

export const create_email_templateProfile = (image, vendor_id, type, desc, title) => {
    console.log({ image, vendor_id, type, desc, title });
    let formData = new FormData()
    if (image) {
        formData.append("flyer", image)
    }
    // if (image) {
    formData.append("vendor_id", vendor_id)
    formData.append("template_desc", desc)
    formData.append("template_type", type)
    formData.append("subject", title)
    return axios
        .post(BASE_URL + "Vendor/create_message", formData, { headers: { "Content-Type": "multipart/form-data" } })
}

export const edit_email_templateProfile = ({ image, vendor_id, type, desc, title, template_id }) => {
    let formData = new FormData()
    if (image) {
        formData.append("flyer", image)
    }
    // if (image) {
    formData.append("vendor_id", vendor_id)
    formData.append("template_desc", desc)
    formData.append("template_type", type)
    formData.append("subject", title)
    formData.append("template_id", template_id)
    return axios
        .post(BASE_URL + "Vendor/edit_message", formData, { headers: { "Content-Type": "multipart/form-data" } })
}

