import React, { useState } from "react";
import "./Profile.css";
import back from "../assets/back.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import humburger from "../assets/humburger.png";
import profileIcon from "../assets/profileIcon.png";
import home from "../assets/home.png";
import homeGrey from "../assets/homeGrey.png";
import add from "../assets/add.png";
import userProfile from "../assets/profilePage.png";
import googleButton from "../assets/googleButton.png";
import { useEffect } from "react";
import { getProfile, getDashBoard, save_vendor_policy, delete_vendor_policy, update_vendor_policy, get_vendor_policy } from "../services/httpService";
import Loader from "./Loader";
import Navbar from "./Navbar";
import SideBar from "./Sidebar";
import { useMediaQuery } from "@mui/material";
import { notifyError, notifySuccess, showConfirmDelete, showConfirm_cancelAlert } from "../util/Util"
import DialogBox from "./DialogBox";

function Profile() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const [isOpen, setisOpen] = useState(false);
  const [edit_isOpen, setedit_isOpen] = useState(false);
  const [policy, setpolicy] = useState('');
  const [policy_id, setpolicy_id] = useState('');
  const [upd_policy, setupd_policy] = useState('')
  const [policy_arr, setpolicy_arr] = useState([]);
  const [is_trial, setis_trial] = useState(0);
  const get_policies = () => {
    get_vendor_policy(ventor_id).then((res) => {
      if (res.data.shop_wizard.status == 1) {
        setpolicy_arr(res.data.shop_wizard.policy);
      }
    }).catch((err) => {
      console.log(err);
    })
  }


  const add_policy = () => {
    if (policy.length > 0) {
      save_vendor_policy(ventor_id, policy).then((res) => {
        if (res.data.shop_wizard.status == 1) {
          setpolicy_arr([{ policy, policy_id: res.data.shop_wizard.policy_id }, ...policy_arr]);
          setpolicy('')
        }
      }).catch((err) => {
        console.log(err);
      })
      // setpolicy('');
    }

  }

  const delete_policy = (item) => {
    // showConfirmDelete
    delete_vendor_policy(item).then((res) => {
      if (res.data.shop_wizard.status == 1) {
        setpolicy_arr(policy_arr.filter((el) => {
          return el.policy_id != item
        }));
        // setpolicy_arr([{ policy, policy_id: res.data.shop_wizard.policy_id }, ...policy_arr]);
      }
    }).catch((err) => {
      console.log(err);
    })

  }

  const update_policy = () => {
    update_vendor_policy(policy_id, upd_policy).then((res) => {
      if (res.data.shop_wizard.status == 1) {

        setpolicy_arr(policy_arr.map((el) => {
          if (el.policy_id == policy_id) {
            return {
              ...el,
              policy: upd_policy
            }
          }
          else {
            return el
          }
        }));
        setTimeout(() => {
          setupd_policy('');
          setpolicy_id('');
          setedit_isOpen(false)
        }, 50);
        // setpolicy_arr([{ policy, policy_id: res.data.shop_wizard.policy_id }, ...policy_arr]);
      }
    }).catch((err) => {
      console.log(err);
    })
  }


  const history = useHistory();
  const location = useLocation()

  const [profileData, setProfileData] = useState({});
  const [load, setLoad] = useState(false);
  const ventor_id = localStorage.getItem("vendor_id");
  useEffect(() => {
    setLoad(true)
    getProfile(ventor_id)
      .then((res) => {
        if (res["data"]["shop_wizard"]["status"] == "1") {
          setLoad(false)
          setProfileData(res["data"]["shop_wizard"]);
        }
        setLoad(false)
      });

    // getDashBoard(ventor_id)
    //   .then((res) => {
    //     if (res["data"]["shop_wizard"]["status"] == "1") {
    //       // localStorage.setItem("tax", res.data.shop_wizard.tax)
    //     }
    //   }).catch(err => {
    //     // notifyError("Something went wrong")
    //   });
    return () => { };
  }, []);

  console.log("prfl", profileData)

  function formatDateFromMilliseconds(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const local_sub_data = localStorage.getItem('subscription_data')
  const sub_data = local_sub_data ? JSON.parse(local_sub_data) : "";

  async function _cancel_subscription() {
    var myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      'Basic c2tfdGVzdF81MU5veWlrSXczQ0lqdlVZYmkycFpidzVVbFJhRnZzV2dJQ0xzbnAwZXh6V2taYU1LWjFTeDFjYWd1S0NiWU5zVThnTzloc3ZKQjlKUmZHZmFyMmsxTnNQODAwTlhLMFplNm86',
    );
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };
    const subs_id = sub_data?.sub_id;
    fetch('https://api.stripe.com/v1/subscriptions/' + subs_id, requestOptions)
      .then(async (sub_result) => {
        let string_data = await sub_result.text();
        // const parsed_data = JSON.parse(string_data);
        // const { shop_wizard } = parsed_data;
        // const { msg } = shop_wizard
        fetch('https://demoapps.in/index.php/Shop_controller/subscription_cancelled', {
          method: 'POST',
          body: JSON.stringify({
            shop_wizard: {
              vendor_id: localStorage.getItem('vendor_id')
            }
          })
        }).then((res) => {
          localStorage.removeItem('subscription_id');
          localStorage.removeItem('customer_id');
          localStorage.removeItem('vendor_name');
          localStorage.removeItem('vendor_id');
          localStorage.removeItem('vendor_reg_email');
          notifySuccess('Your subscription is cancelled');
          history.push('/')
        }).catch((err) => {
          // console.log(err);
          notifyError('Some error occured')
        });
        console.log(sub_result);
      })
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  const format_date = (inputDate) => {
    const dateParts = inputDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    const months = [
      "Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"
    ];

    const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
    return formattedDate;
  }

  const subscribed_flag = localStorage.getItem('subscription_cancelled');



  return (
    <>
      <DialogBox isOpen={isOpen} setIsOpen={setisOpen} buttonDisplay={'none'}>
        <div style={{ width: "100%" }}>
          <div style={{ width: '100%', textAlign: "start", padding: "4px 10px", fontWeight: "600", borderBottom: "1px solid #c9c9c9" }}>
            Manage Vendor Policies
          </div>
          <div style={{ width: "500px", marginTop: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <input placeholder="type policy here..." type="text" style={{ width: "430px" }}
              value={policy} onChange={(e) => { setpolicy(e.target.value) }} />
            <button onClick={add_policy}
              style={{ width: "62px", backgroundColor: "#1050e6", border: "1px solid blue", borderRadius: "5px", color: "#fff", fontSize: "16px", marginLeft: "7px", fontWeight: "600" }}>Add</button>
          </div>
          <div style={{ minHeight: "300px", maxHeight: "400px", overflowY: "auto", width: "100%" }}>
            <table style={{ width: "100%", marginTop: "10px" }}>
              <thead>
                <tr style={{ backgroundColor: "#696969", color: "#fff" }}>
                  <th style={{ paddingLeft: "10px" }}>#</th>
                  <th>Policies</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  policy_arr.length > 0 &&
                  policy_arr.map((el, index) => {
                    return (
                      <tr style={{ fontSize: "14px", borderBottom: "1px solid #c9c9c9" }}>
                        <td style={{ paddingLeft: "10px" }}>{index + 1}</td>
                        <td>{el.policy}</td>
                        <td>
                          <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                            <i className="fa fa-trash"
                              style={{ cursor: "pointer" }}
                              onClick={() => { showConfirmDelete(() => { delete_policy(el.policy_id) }) }}
                            />
                            <i className="fa fa-edit"
                              onClick={() => {
                                setupd_policy(el.policy);
                                setedit_isOpen(true);
                                setpolicy_id(el.policy_id)
                              }}
                              style={{ marginTop: "2px", cursor: "pointer" }} />
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </DialogBox>
      <DialogBox isOpen={edit_isOpen} setIsOpen={setedit_isOpen} buttonDisplay={'none'}>
        <div style={{ width: "100%" }}>
          <div style={{ width: '100%', textAlign: "start", fontWeight: "600", padding: "4px 10px", borderBottom: "1px solid #c9c9c9" }}>
            Edit policy
          </div>
          <div style={{ width: "300px", marginTop: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <input placeholder="type policy here..." type="text" style={{ width: "230px" }}
              value={upd_policy} onChange={(e) => { setupd_policy(e.target.value) }} />
            <button onClick={update_policy}
              style={{
                width: "68px", backgroundColor: "#1050e6", border: "1px solid blue",
                borderRadius: "5px", color: "#fff", fontSize: "16px", marginLeft: "7px", fontWeight: "600"
              }}>
              Update</button>
          </div>
        </div>
      </DialogBox>
      <Loader load={load} />
      <section className="profile__section">
        <Navbar heading='Profile' height="40px" />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80.9%" }}>
            <div className="profile__content container-fluid"  >
              <div className="row">
                <div className="col-12 p-0">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-4 d-flex justify-content-center align-items-center">
                          {/* <i class="fa fa-sign-out text-primary fa-2x cursor-pointer" aria-hidden="true" onClick={e => {
                        localStorage.clear()
                        history.push("/")
                      }}></i> */}
                          <a className="text-primary font-weight-bolder cursor-pointer h3 " onClick={e => {
                            if (window.confirm("Do you want to log out?")) {
                              localStorage.clear()
                              history.push("/")
                            }
                          }}>Logout</a>
                        </div>
                        <div className="col-4 d-flex justify-content-center align-items-center">
                          <img src={profileData.logo_url ? profileData.logo_url : userProfile} alt="" className="roundImage" />
                        </div>
                        <div className="col-4 d-flex justify-content-center align-items-center">
                          <i class="fa fa-pencil text-white bg-primary d-flex cursor-pointer justify-content-center align-items-center editIcon" aria-hidden="true"
                            onClick={e => {
                              history.push("/edit-profile")
                            }}
                          ></i>
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <h4 className="font-weight-bold">{profileData.name}</h4>
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <p className="font-weight-bold profile__content__para">
                            Vendor’s ID- {ventor_id}
                          </p>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-5 offset-1 profile__content_col">
                          <small className="profile__content__left">Email</small>
                        </div>
                        <div className="col-5 offset-1 ml-1 d-flex justify-content-end profile__content_col">
                          <small className="profile__content__right">
                            {profileData.email}
                          </small>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-5 offset-1 profile__content_col">
                          <small className="profile__content__left">
                            Phone Number
                          </small>
                        </div>
                        <div className="col-5 offset-1 ml-1 d-flex justify-content-end profile__content_col">
                          <small className="profile__content__right">
                            {profileData.contact}
                          </small>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-5 offset-1 profile__content_col">
                          <small className="profile__content__left">Address</small>
                        </div>
                        <div className="col-5 offset-1 ml-1 d-flex justify-content-end profile__content_col">
                          <small className="profile__content__right">
                            {profileData.address}
                          </small>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-5 offset-1 profile__content_col">
                          <small className="profile__content__left">State</small>
                        </div>
                        <div className="col-5 offset-1 ml-1 d-flex justify-content-end profile__content_col">
                          <small className="profile__content__right">
                            {profileData.state}
                          </small>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-5 offset-1 profile__content_col">
                          <small className="profile__content__left">Tax</small>
                        </div>
                        <div className="col-5 offset-1 ml-1 d-flex justify-content-end profile__content_col">
                          <small className="profile__content__right">
                            {profileData.state_tax}%
                          </small>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-12 d-flex justify-content-center align-items-center cursor-pointer" >
                          <img
                            onClick={e => {
                              window.open(profileData.google_review_url, "_blank")
                            }}
                            src={googleButton}
                            alt=""
                            className="img-fluid"
                            style={{ height: "60px" }}
                          />
                          <button style={{
                            marginTop: '-10px',
                            border: "0px",
                            borderRadius: "20px",
                            height: "34px",
                            width: "150px",
                            textAlign: "center",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                          }}
                            onClick={() => {
                              setisOpen(true);
                              get_policies();
                            }}
                          >
                            Invoice Policies
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              profileData?.is_trial == 1 &&
              <>

                <div style={{ width: "100%",gap:5, display: "flex", alignItems: "center", justifyContent: "center", color: "#000", padding: "2px 0px" }}>
                  <div
                    // onClick={handleSubmit}
                    // disabled={true}
                    className="btn sub_trial_button "
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    style={{ maxWidth: "290px" }}>
                    <div style={{ fontSize: "14px", marginTop: "-2px" }}>
                      Your trial will end in {format_date(sub_data?.valid_till)}
                    </div>
                  </div>
                  <button
                    // onClick={handleSubmit}
                    // disabled={load}
                    className="btn sub_pay_button "
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem('vendor_reg_email', profileData.email);
                      localStorage.getItem('vendor_name', profileData.name)
                      history.push('/payment-new')

                    }}
                    style={{ maxWidth: "290px" }}>
                    <div style={{ fontSize: "14px", marginTop: "-2px" }}>Subscribe Now <b>just at $59.99/month</b></div>
                  </button>
                </div>
              </>
            }
            {
              profileData?.is_trial != 1 && subscribed_flag == 0 &&
              <>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "#000", padding: "2px 0px" }}>
                  <span>
                    Your current susbscription is valid till {format_date(sub_data?.valid_till)}, you can stop subscription anytime before next billing cycle starts.

                  </span>
                </div>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "#000", padding: "2px 0px" }}>

                  <button
                    // onClick={handleSubmit}
                    // disabled={load}
                    className="btn sub_cancel_button "
                    onClick={(e) => {
                      e.preventDefault();
                      showConfirm_cancelAlert(() => {
                        _cancel_subscription();
                      })

                    }}
                    style={{ maxWidth: "250px" }}>
                    <div style={{ fontSize: "14px", marginTop: "-2px" }}>CANCEL SUBSCRIPTION NOW</div>
                  </button>
                </div>
              </>
            }
            {
              subscribed_flag == 1 &&
              <>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "#000", padding: "2px 0px" }}>
                  <span>
                    Your current susbscription will expired on {format_date(sub_data?.valid_till)} .

                  </span>
                </div>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "#000", padding: "2px 0px" }}>

                  <button
                    // onClick={handleSubmit}
                    // disabled={load}
                    className="btn sub_pay_button "
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem('vendor_reg_email', profileData.email);
                      localStorage.getItem('vendor_name', profileData.name)
                      history.push('/payment')

                    }}
                    style={{ maxWidth: "290px" }}>
                    <div style={{ fontSize: "14px", marginTop: "-2px" }}>Subscribe Now <b>just at $59.99/month</b></div>
                  </button>
                </div>
              </>
            }

          </div>
        </div >
      </section >
    </>
  );
}

export default Profile;
