import React, { useEffect, useState } from "react";
import Select from 'react-select'
import "./Estimate.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import id from "../assets/id.png";
import name from "../assets/name.png";
import password from "../assets/password.png";
import wrong from "../assets/wrong.png";
import { getAllCustomer, getAllVehicle, generateInvoice, saveEstimate } from "../services/httpService";
import { notifyError, notifySuccess, showConfirmAlert } from "../util/Util";
import Loader from "./Loader";
import Navbar from "./Navbar";
import SideBar from "./Sidebar";
import { useMediaQuery } from "@mui/material";
const CustomOption = ({ innerRef, innerProps, ...rest }) => {
  return (
    <div ref={innerRef} {...innerProps}>
      <div className="card customer__card m-2">
        <div className="card-body customer__cardBody">
          <div className="row mt-2 mb-2" style={{ height: "50px" }}>
            <div className="col-12 text-secondary">{rest.data.label}</div>
            <div className="col-12 text-dark">{rest.data.value.vin_number}</div>
          </div>
        </div>
      </div>
    </div>)
}
function NewCustomerEstimate() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const history = useHistory()
  const [vehicleData, setVehicleData] = useState([]);
  const [load, setLoad] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [items, setItems] = useState([{
    item_name: "",
    price: "",
    tax: ""
  }]);
  const [customerId, setCustomer] = useState("")
  const [total, setTotal] = useState(0)
  const [vin, setVin] = useState("")
  const [reg, setReg] = useState("")
  const ventor_id = localStorage.getItem("vendor_id");
  const [counter, setCounter] = useState(0);
  const [itemsUI, setItemsUI] = useState(null)

  document.onkeydown = function (e) {
    console.log(e.code)
    if (e.code == 'Quote' || e.code == 'Minus'|| e.code == "NumpadSubtract" || e.code == 'NumpadAdd' || e.code == 'Equal') {
      return false
    }

  }
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("addEstimateData"))
    if (data) {
      setSelectValue({
        label: data.name, value: {
          customer_id: data.customer_id,
          customer_name: data.name,
          vin_number: data.vin,
          reg_number: data.reg,
        }
      })
      setVin(data.vin)
      setReg(data.reg)
      setCustomer(data.customer_id)
    }
    setLoad(true)
    getAllVehicle(ventor_id)
      .then((res) => {
        setLoad(false)
        if (res["data"]["shop_wizard"]["status"] == "1") {
          setVehicleData(res["data"]["shop_wizard"]["vehicles"]);
        }
      });
  }, [])


  useEffect(() => {
    setItemsUI(getUI())
  }, [counter, items.length])

  let handleSubmit = () => {
    setLoad(true)
    console.log(items);
    generateInvoice({
      customer_id: customerId, total_amount: (total + total * parseFloat((localStorage.getItem("tax") / 100))).toFixed(2), vin_number: vin, reg_number: reg, particulars: items, vendor_id: ventor_id, appointment_id: "0", invoice_id: "0"
    }).then(res => {
      if (res.data.shop_wizard.status == "1") {
        setLoad(false)
        notifySuccess("Invoice generated successfully")
        localStorage.removeItem("addEstimateData")
        history.push("/invoice")
      } else {
        setLoad(false)
        notifyError(res.data.shop_wizard.msg)
      }
    })
  }

  let save = () => {
    setLoad(true)
    saveEstimate({
      customer_id: customerId, total_amount: total, vin_number: vin, reg_number: reg, particulars: items, vendor_id: ventor_id, appointment_id: "0", invoice_id: "0"
    }).then(res => {
      if (res.data.shop_wizard.status === "1") {
        setLoad(false)
        notifySuccess("Invoice generated successfully")
        localStorage.removeItem("addEstimateData")
        history.push("/estimate-list")
      } else {
        setLoad(false)
        notifyError(res.data.shop_wizard.msg)
      }
    })
  }
  const Validate = () => {
    let validate = [
      { name: "Customer", value: customerId }
    ]
    items.map(v => {
      validate.push({
        name: "Issue name", value: v.item_name
      })
      validate.push({
        name: "Enter amount", value: v.price
      })
    })
    validate.some(v => {
      if (v.value) {
        return false
      } else {
        notifyError("Please enter " + v.name)
        return true
      }
    })
  }

  let getUI = () => {
    return (
      <>
        {items.map((val, i) => (
          <div className="row">
            <div className="col-2 p-0 text-center d-flex justify-content-center align-items-center">
              <span className="font-weight-bolder text-dark">{i + 1}.</span>
            </div>
            <div className="col-4 p-0">
              <input
                type="text"
                placeholder="Type service"
                className="estimate__input"
                value={val.item_name}
                onChange={e => {
                  setItems(data => {
                    data[i] = {
                      ...data[i],
                      item_name: e.target.value
                    }
                    return data
                  })
                  setCounter(c => c + 1)
                }
                }
              />
            </div>
            <div className="col-4 p-0">
              <input
                type="number"
                placeholder="Amount"
                className="estimate__input"
                value={val.price ? val.price : null}
                onPaste={(e) => {
                  e.preventDefault()
                  return false;
                }
                }
                onChange={e => setItems(data => {
                  let value = parseFloat(e.target.value)
                  data[i] = {
                    ...data[i],
                    price: Math.abs(value),
                    tax: (value * parseFloat(localStorage.getItem("tax")) / 100).toFixed(2)
                  }
                  setTotal(items.map(v => v.price ? v.price : 0).reduce((t, v) => (parseFloat(t) + parseFloat(v))))
                  setCounter(c => c + 1)
                  return data
                })}
              />
            </div>
            <div className="col-2 p-0  d-flex justify-content-center align-items-center">
              <img
                src={wrong}
                alt=""
                className="img-fluid"
                style={{ display: items.length > 1 ? "block" : "none", height: "20px", cursor: "pointer" }}
                onClick={e => {
                  setItems(items.filter((item, index) => {
                    if (index == i) {
                      // console.log(item);
                      return;
                    }
                    else {
                      return item
                    }
                  }))

                  setTotal(
                    items.filter((item, index) => {
                      if (index == i) {
                        // console.log(item);
                        return;
                      }
                      else {
                        return item
                      }
                    })
                      .map((v) => v.price ? v.price : 0)
                      .reduce((t, v) => (parseFloat(t) + parseFloat(v)))
                  );
                  setCounter(c => c + 1)

                }}
              />
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Loader load={load} />
      <section className="estimate__section">
      <Navbar heading='Add New Estimate' />
      <div style={{display:"flex"}}>
      {
        !matches_820 &&
        <div style={{ width: "19%" }}>
          <SideBar />
        </div>
      }
        <div style={{width:matches_820 ? "100%" : "81%",position:"relative",height:"91vh"}}>
          <i class="fa fa-check" aria-hidden="true" style={{ fontSize: "20px" }} onClick={e =>
            customerId && items.filter(v => !v.item_name || !v.price).length == 0 ?
              showConfirmAlert(handleSubmit) :
              Validate()
          }></i>
        <div className="estimate__content container">
          <div className="row mt-3">
            <div className="col-2 offset-1 d-flex justify-content-center align-items-center estimate__col">
              <img
                src={id}
                alt=""
                className="img-fluid"
                style={{ height: "20px" }}
              />
            </div>
            <div className="col-7 pl-0 estimate__col">
              {/* <small className="estimate__dark">Customer ID</small>
            <p className="estimate__light">GRGCU0215</p> */}
              <Select
                isDisabled={selectValue ? true : false}
                options={vehicleData.map(val => ({
                  label: val.customer_name,
                  value: {
                    customer_id: val.customer_id,
                    customer_name: val.customer_name,
                    vin_number: val.vin_number,
                    reg_number: val.reg_number,
                  }
                }))}
                placeholder="Customer"
                isOptionDisabled={option => {
                  if (JSON.parse(localStorage.getItem("addEstimateData"))) {
                    return option.value.customer_id == customerId ? false : true
                  } else {
                    return false
                  }
                }}
                components={{
                  Option: CustomOption,
                  // DropdownIndicator:() => null, IndicatorSeparator:() => null
                }}
                onChange={v => {
                  if (v) {
                    setCustomer(v.value.customer_id)
                    setVin(v.value.vin_number)
                    setReg(v.value.reg_number)
                    setSelectValue(v)
                  }
                }}
                value={selectValue ? selectValue : null}
                styles={{
                  container: (c) => ({ ...c, color: "black", background: "none", border: "none" }),
                  // control: (c) => ({ ...c,background:"none",border:"none" }),
                }}
              ></Select>
            </div>
          </div>
          {/* <div className="row mt-5">
          <div className="col-2 offset-1 d-flex justify-content-center  estimate__col">
            <img
              src={name}
              alt=""
              className="img-fluid"
              style={{ height: "20px" }}
            />
          </div>
          <div className="col-7 pl-0 estimate__col">
            <p className="estimate__light">Name</p>
          </div>
        </div> */}
          <div className="row mt-3">
            <div className="col-2 offset-1 d-flex justify-content-center estimate__col">
              <img
                src={password}
                alt=""
                className="img-fluid"
                style={{ height: "20px" }}
              />
            </div>
            <div className="col-7 pl-0 estimate__col">
              <p className="estimate__light">&nbsp;&nbsp; {vin ? vin : "VIN No./Car Reg No."}</p>
              {/* <p className="estimate__light">VIN No./Reg No. {vin}</p> */}
              {/* <p className="estimate__light">Car Ragistration Number {reg}</p> */}
            </div>
          </div>
        </div>
        <div className="container estimate__bottom__card mt-5">
          <div className="row">
            <div className="col-12 p-0">
              <div className="card estimate__card">
                <div className="card-body pb-5">
                  <p
                    className="font-weight-bold text-dark d-flex justify-content-between"
                    style={{ fontSize: "12px" }}
                  >
                    SERVICE WITH CARS
                    <i class="fa fa-plus-circle fa-2x mr-3" style={{ color: "#1050e6", cursor: "pointer" }} aria-hidden="true" onClick={e => {
                      if (items[items.length - 1].price != '' || items[items.length - 1].item_name != '') {
                      setItems(data => ([...data, {
                      item_name: "",
                      price: "",
                      tax: ""
                    }]))}}}></i>
                  </p>
                  {itemsUI}
                  {/* <div className="row mt-5 mb-5 text-secondary">
                    <div className="col-6 d-flex justify-content-center">
                      <small>Service:{items.length}</small>
                    </div>

                    <div className="col-6 pl-5">
                      <small>Amount:{total}</small>
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                      <button className="btn estimate__addIssue" >Add Issue</button>
                    </div>
                    <div className="col-6">
                      <button className="btn estimate__save" >Save</button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="generate-invoice_container">
          <div className="generate-invoice-data d-flex justify-content-between px-3 py-1">
            <p className="data">Service:{items.length}</p>
                <p className="data">Amount:{(total + total * parseFloat((localStorage.getItem("tax") / 100))).toFixed(2)}</p>

            <p className="data">Tax:{(total * parseFloat((localStorage.getItem("tax")) / 100)).toFixed(2)}</p>
          </div>
          <button className="generate-invoice-btn text-center" disabled={items[0].item_name == '' || items[0].price == '' ? true : false} onClick={e =>
            customerId && items.filter(v => !v.item_name || !v.price).length == 0 ?
              showConfirmAlert(handleSubmit) :
              Validate()
          }>Generate Invoice</button>
        </div>
        </div>
        </div>
      </section>
    </>
  );
}

export default NewCustomerEstimate;
