import React, { useState, useRef, useEffect } from 'react'

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

export default function Img_to_text({ src, setIsOpen,setCropped_img }) {
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const hiddenAnchorRef = useRef(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(undefined)
    const [cropped_img, setcropped_img] = useState(null)

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e) {
        // if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, 16/9))
        // }
    }

    async function onDownloadCropClick() {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }

        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const blob = await offscreen.convertToBlob({
            type: 'image/png',
        })
        // setcropped_img(URL.createObjectURL(blob));
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64Image = event.target.result;
            // this.setState({ imageBase64: base64Image });
            // setcropped_img(base64Image)
            setCropped_img(base64Image);
            setIsOpen(false);
            console.log({ blob, url: URL.createObjectURL(blob), base64Image });
        };

        reader.readAsDataURL(blob);

        // if (blobUrlRef.current) {
        //   URL.revokeObjectURL(blobUrlRef.current)
        // }
        // blobUrlRef.current = URL.createObjectURL(blob)
        // hiddenAnchorRef.current!.href = blobUrlRef.current
        // hiddenAnchorRef.current!.click()
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
        onDownloadCropClick
    );



    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else {
            setAspect(16 / 9)

            if (imgRef.current) {
                const { width, height } = imgRef.current
                const newCrop = centerAspectCrop(width, height, 16 / 9)
                setCrop(newCrop)
                // Updates the preview
                setCompletedCrop(convertToPixelCrop(newCrop, width, height))
            }
        }
    }

    return (
        <>

            {src && (
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => {
                        setCompletedCrop(c);
                        // setTimeout(() => {
                        //     onDownloadCropClick();
                        // }, 200);
                    }}
                // aspect={aspect}
                // minWidth={400}
                // minHeight={200}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={src}
                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)`,maxHeight:"400px" }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}
            {!!completedCrop && (
                <>
                    <div>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                display: "none",
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </div>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <button
                            onClick={() => setIsOpen(false)}
                            style={{ backgroundColor: "red", color: "#fff", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                            Close
                        </button>
                        &nbsp;
                        &nbsp;
                        <button
                            onClick={onDownloadCropClick}
                            style={{ backgroundColor: "blue", color: "#fff", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                            Submit
                        </button>
                    </div>
                    {/*<img
                        src={cropped_img}
                        style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: completedCrop.width,
                            height: completedCrop.height,
                        }}
                    />
                    <div>
                        <button onClick={onDownloadCropClick}>Download Crop</button>
                        <a
                            href="#hidden"
                            ref={hiddenAnchorRef}
                            download
                            style={{
                                position: 'absolute',
                                top: '-200vh',
                                visibility: 'hidden',
                            }}
                        >
                            Hidden download
                        </a>
                        </div>*/}
                </>
            )}
        </>
    )
}
