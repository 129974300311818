import React, { useContext } from "react";
import "./Appointment.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import add from "../assets/add.png";
import { useState } from "react";
import { useEffect } from "react";
import { deleteMessage, getAllAppointment, deleteAppointment } from "../services/httpService";
import Loader from "./Loader";
import { showConfirmDelete } from "../util/Util";
import Ncontext from "../ContextC";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";

function Appointment() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const context = useContext(Ncontext);
  const { mApp, setmApp, hApp, sethApp } = context;
  const [appointment, setAppointment] = useState([]);
  const [load, setLoad] = useState(false);
  const [showData, setShow] = useState([]);
  const ventor_id = localStorage.getItem("vendor_id");
  const [query, setQuery] = useState("");
  const history = useHistory()
  useEffect(() => {
    setLoad(true)
    getAllAppointment(ventor_id)
      .then((res) => {
        setLoad(false)
        if (res["data"]["shop_wizard"]["status"] === "1") {
          console.log(res["data"]["shop_wizard"]["items"]);
          setAppointment(res["data"]["shop_wizard"]["items"]);
          setShow(res["data"]["shop_wizard"]["items"]);
        }
      }).catch(err => setLoad(false));
    return () => { };
  }, []);

  useEffect(() => {
    if (query) {
      setShow(appointment.filter(v => v.customer_name.toLowerCase().includes(query.toLowerCase()) || JSON.stringify(v.vin_number).toLowerCase().includes(query.toLowerCase()) || JSON.stringify(v.reg_number).toLowerCase().includes(query.toLowerCase()) || JSON.stringify(v.contact).toLowerCase().includes(query.toLowerCase())))
    } else {
      setShow(appointment)
    }
  }, [query])
  return (
    <>
      <Loader load={load} />
      <section className="appointment__section">
        <Navbar heading='Appointments' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80%" }}>
            <div class="input-group col-md-12 search-bar_container py-3">
              <input class="form-control py-2 border-right-0 border" type="search" onChange={e => setQuery(e.target.value)} placeholder="Search" id="example-search-input" />
              <span class="input-group-append">
                <button class="btn btn-outline-secondary border-left-0 border" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </span>
              <span class="input-group-append">
                <Link to="/add-appointment">
                  <button class="btn btn-outline-secondary border-left-0 border" type="button">
                    <i class="fa fa-plus"></i>
                  </button>
                </Link>
              </span>
            </div>
            <div className="container appointment__content" style={{ height:"80vh",overflowY:"auto"}}>

              <div className="row">
                {showData?.map(function (appoint) {
                  return (
                    <div className="col-12 mt-2">
                      <div className="card customer__card">
                        <div className="card-body customer__cardBody py-1">
                          <div className="row py-2">
                            <div className="col-5 pl-4 cursor-pointer"
                              onClick={e => {
                                localStorage.removeItem("addEstimateData")
                                localStorage.setItem("addEstimateData", JSON.stringify({ vin: appoint.vin_number, name: appoint.customer_name, customer_id: appoint.customer_id, reg: appoint.reg_number }))
                                history.push("/new-cust-estimate/")
                              }}
                            >
                              <p className="mb-0 appointment__content__paraDark">{appoint.customer_name + " - " + appoint.appointment_id}</p>
                              <p className="mb-0 appointment__content__paraLight">
                                {appoint.contact}
                              </p>
                              <p className="mb-0 appointment__content__paraLight">
                                {appoint.email}
                              </p>
                            </div>
                            <div className="col-5 cursor-pointer"
                              onClick={e => {
                                localStorage.removeItem("addEstimateData")
                                localStorage.setItem("addEstimateData", JSON.stringify({ vin: appoint.vin_number, name: appoint.customer_name, customer_id: appoint.customer_id, reg: appoint.reg_number }))
                                history.push("/new-cust-estimate/")
                              }}
                            >
                              <p className="mb-0 appointment__content__paraLight text-right">
                                {appoint.time}
                              </p>
                              <p className="mb-0 appointment__content__paraLight text-right">
                                {appoint.date}
                              </p>
                            </div>
                            <div className="col-2 d-flex flex-column justify-content-center align-items-end">
                              <i className="fa fa-edit text-primary d-block mb-1 cursor-pointer"
                                onClick={
                                  () => {
                                    sethApp(parseInt(appoint.time.split(' ')[0] > 10 ? appoint.time.split(' ')[0] : appoint.time.split(' ')[0].slice(1, 2)));
                                    setmApp(parseInt(appoint.time.split(' ')[1] > 10 ? appoint.time.split(' ')[1] : appoint.time.split(' ')[1].slice(1, 2)))
                                    // alert(parseInt(appoint.time.split(' ')[0]>10?appoint.time.split(' ')[0]:appoint.time.split(' ')[0].slice(1,2)))
                                    history.push("/edit-appointment/" + appoint.appointment_id)
                                  }
                                }
                              ></i>
                              <i className="fa fa-trash text-danger d-block mt-1 cursor-pointer"
                                onClick={
                                  () => {
                                    showConfirmDelete(
                                      () => {
                                        deleteAppointment(appoint.appointment_id)
                                          .then(res => {
                                            getAllAppointment(ventor_id)
                                              .then((res) => {
                                                if (res["data"]["shop_wizard"]["status"] === "1") {
                                                  setLoad(false)
                                                  setAppointment(res["data"]["shop_wizard"]["items"]);
                                                  setShow(res["data"]["shop_wizard"]["items"]);
                                                }
                                                else {
                                                  setAppointment([]);
                                                  setShow([]);
                                                }
                                              });
                                          })
                                      }
                                    )
                                  }
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row"> */}

        {/* <div >
          <img
            src={add}
            alt=""
            style={{ left: "61%!important" }}
            className="img-fluid appointement_plus__button"
          />
          </div> */}

        {/* </div> */}
      </section>
    </>
  );
}

export default Appointment;
