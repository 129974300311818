import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./Estimate.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import id from "../assets/id.png";
import name from "../assets/name.png";
import password from "../assets/password.png";
import wrong from "../assets/wrong.png";
import {
  getAllCustomer,
  getAllVehicle,
  generateInvoice,
  saveEstimate,
  getInvoiceDetail,
  payInvoice,
} from "../services/httpService";
import { notifyError, notifySuccess, removeSpacesAndAlphabets, showConfirmAlert } from "../util/Util";
import Loader from "./Loader";
import Navbar from "./Navbar";
import SideBar from "./Sidebar";
import { useMediaQuery } from "@mui/material";
const CustomOption = ({ innerRef, innerProps, ...rest }) => {
  return (
    <div ref={innerRef} {...innerProps}>
      <div className="card customer__card m-2">
        <div className="card-body customer__cardBody">
          <div className="row mt-2 mb-2" style={{ height: "50px" }}>
            <div className="col-12 text-secondary">{rest.data.label}</div>
            <div className="col-12 text-dark">{rest.data.value.vin_number}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
function Estimate(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const history = useHistory();
  const [vehicleData, setVehicleData] = useState([]);
  const [load, setLoad] = useState(false);
  const [itemsUI, setItemsUI] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [paidAmount, setPaidAmount] = useState("0");
  const [counter, setCounter] = useState(0);
  const [no_tax, setno_tax] = useState(false);
  const [items, setItems] = useState([
    {
      item_name: "",
      price: "",
      tax: "",
    },
  ]);
  const [customerId, setCustomer] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [total, setTotal] = useState(0);
  const [balance, setbalance] = useState(0);
  const [vin, setVin] = useState("");
  const [reg, setReg] = useState("");
  const ventor_id = localStorage.getItem("vendor_id");
  const [total_amt, settotal_amt] = useState(0);
  const [tax, settax] = useState(0);
  const [stored_balance, setstored_balance] = useState(0);

  document.onkeydown = function (e) {
    // alert(e.code)
    if (e.code == 'Quote' || e.code == 'Minus' || e.code == "NumpadSubtract" || e.code == 'NumpadAdd' || e.code == 'Equal') {
      return false
    }

  }

  useEffect(() => {
    // let data = JSON.parse(localStorage.getItem("addEstimateData"));
    // if (data) {
    //   setSelectValue({
    //     label: data.name,
    //     value: {
    //       customer_id: data.customer_id,
    //       customer_name: data.name,
    //       vin_number: data.vin,
    //       reg_number: data.reg,
    //     },
    //   });
    //   setVin(data.vin);
    //   setReg(data.reg);
    //   setCustomer(data.customer_id);
    // }
    // setLoad(true);
    // getAllVehicle(ventor_id).then((res) => {
    //   if (res["data"]["shop_wizard"]["status"] === "1") {
    //     setLoad(false);
    //     setVehicleData(res["data"]["shop_wizard"]["vehicles"]);
    //   }
    // });
    let data = JSON.parse(localStorage.getItem("estimateEdit2"))
    setVin(data.vin)
    // setItemsUI(getUI());
    setReg(data.reg)
    setTotal(data.total)
    settotal_amt(data.total);
    setCustomer(data.customer)
    setCustomerName(data.customer_name)
    setLoad(true)
    getInvoiceDetail(props.match.params.id)
      .then((res) => {
        if (res["data"]["shop_wizard"]["status"] === "1") {
          let tax = localStorage.getItem("tax") ? localStorage.getItem("tax") : 10
          setLoad(false)
          setItems(res["data"]["shop_wizard"]["items"].map(v => ({ ...v, tax: v.tax ? v.tax : v.price * (parseInt(tax) / 100) })))
          settax(res["data"]["shop_wizard"]["items"].map((v) => (v.tax ? v.tax : 0))
            .reduce((t, v) => parseFloat(t) + parseFloat(v)))
          console.log(res.data.shop_wizard);
          setbalance(parseFloat(res.data.shop_wizard.balance_amount - res["data"]["shop_wizard"]["items"].map((v) => (v.tax ? v.tax : 0))
            .reduce((t, v) => parseFloat(t) + parseFloat(v))));
          setstored_balance(res.data.shop_wizard.balance_amount);
          setItemsUI(res.data.shop_wizard.items.map((val, i) => (
            <div className="row">
              <div className="col-2 p-0 text-center d-flex justify-content-center align-items-center">
                <span className="font-weight-bolder text-dark">
                  {i + 1}.
                </span>
              </div>
              <div className="col-4 p-0">
                <input
                  type="text"
                  placeholder="Type services"
                  className="estimate__input"
                  value={val.item_name}
                  readOnly
                  onChange={(e) =>
                    setItems((data) => {
                      data[i] = {
                        ...data[i],
                        item_name: e.target.value,
                      };
                      setCounter(c => c + 1)
                      return data;
                    })
                  }
                />
              </div>
              <div className="col-4">
                <input
                  type="number"
                  placeholder="Amount"
                  className="estimate__input"
                  value={val.price}
                  readOnly
                  onChange={(e) =>
                    setItems((data) => {
                      let value = e.target.value;
                      let tax = localStorage.getItem("tax")
                        ? localStorage.getItem("tax")
                        : 10;
                      data[i] = {
                        ...data[i],
                        price: value,
                        tax: value ? value * (parseInt(tax) / 100) : 0,
                      };
                      setTotal(
                        items
                          .map((v) => parseInt(v.price ? v.price : 0))
                          .reduce((t, v) => t + v)
                      );
                      setCounter(c => c + 1)
                      return data;
                    })
                  }
                />
              </div>
              {/*
              <div className="col-2 p-0  d-flex justify-content-center align-items-center">
                {
                  items.length > 1 ?
                    <img
                      src={wrong}
                      alt=""
                      className="img-fluid"
                      style={{ height: "20px" }}
                      onClick={() => {
                        if (i !== 0) {
                          console.log("aaaaaa")
                          setItems((data) => {
                            data.splice(i, 1);
                            return data;
                          });
                          setCounter(c => c + 1)
                          console.log("aaaaa2", items)
                        }
                      }}
                    />
                    : null
                }
              </div>
              */}
            </div>
          )
          ))
        }
      });
  }, []);

  const new_b = !no_tax ? stored_balance : balance;

  let handleSubmit = () => {
    if (
      customerId
      // items.filter((v) => !v.item_name || !v.price).length === 0
    ) {
      if (paidAmount.toString().includes('-') || paidAmount.toString().includes('+') || paidAmount.toString().includes('/') || paidAmount.toString().includes('+') || paidAmount.toString().split('.').length > 2 || paidAmount == 0 || paidAmount == 0.0) {
        notifyError("Enter correct amount")
      } else {
        setLoad(true);
        generateInvoice({
          customer_id: customerId,
          total_amount: total,
          vin_number: vin,
          reg_number: "",
          particulars: items,
          paid_amount: parseFloat(Math.abs(paidAmount)).toFixed(2),
          vendor_id: ventor_id,
          appointment_id: "0",
          invoice_id: props.match.params.id,
          no_tax: no_tax ? 1 : 0,
          invoice_status: parseFloat(paidAmount) < total ? 2 : 1
        }).then((res) => {
          // if (res.data.shop_wizard.status === "1") {
          setLoad(false);
          notifySuccess("Payment successfully")
          localStorage.removeItem("estimateEdit2");
          history.push("/invoice");
          // } else {
          //   setLoad(false);
          //   notifyError(res.data.shop_wizard.msg);
          // }
        });
        // payInvoice(props.match.params.id)
      }
    } else {
      let validate = [{ name: "Customer", value: customerId }];
      items.map((v) => {
        validate.push({
          name: "Issue name",
          value: v.item_name,
        });
        validate.push({
          name: "Enter amount",
          value: v.price,
        });
      });
      validate.some((v) => {
        if (v.value) {
          return false;
        } else {
          notifyError("Please enter " + v.name);
          return true;
        }
      });
      // notifyError("Empty fields not allowed")
    }
  };
  let save = () => {
    if (
      customerId &&
      items.filter((v) => !v.item_name || !v.price).length === 0
    ) {
      setLoad(true);
      saveEstimate({
        customer_id: customerId,
        total_amount: total,
        vin_number: vin,
        reg_number: reg,
        particulars: items,
        vendor_id: ventor_id,
        appointment_id: "0",
        invoice_id: "0",
      }).then((res) => {
        if (res.data.shop_wizard.status === "1") {
          setLoad(false);
          localStorage.removeItem("addEstimateData");
          history.push("/estimate-list");
        } else {
          setLoad(false);
          notifyError(res.data.shop_wizard.msg);
        }
      });
    } else {
      let validate = [{ name: "Customer", value: customerId }];
      items.map((v) => {
        validate.push({
          name: "Issue name",
          value: v.item_name,
        });
        validate.push({
          name: "Enter amount",
          value: v.price,
        });
      });
      validate.some((v) => {
        if (v.value) {
          return false;
        } else {
          notifyError("Please enter " + v.name);
          return true;
        }
      });
      // notifyError("Empty fields not allowed")
    }
  };

  useEffect(() => {
    setItemsUI(getUI())
  }, [counter, items.length]);

  const getUI = () => {
    return (
      items.map((val, i) => (
        <div className="row">
          <div className="col-2 p-0 text-center d-flex justify-content-center align-items-center">
            <span className="font-weight-bolder text-dark">
              {i + 1}.
            </span>
          </div>
          <div className="col-4 p-0">
            <input
              type="text"
              placeholder="Type service"
              className="estimate__input"
              value={val.item_name}
              readOnly
              onChange={(e) =>
                setItems((data) => {
                  data[i] = {
                    ...data[i],
                    item_name: e.target.value,
                  };
                  setCounter(c => c + 1)
                  return data;
                })
              }
            />
          </div>
          <div className="col-4">
            <input
              type="number"
              placeholder="Amount"
              className="estimate__input"
              value={val.price}
              readOnly
              onChange={(e) =>
                setItems((data) => {
                  let value = e.target.value;
                  let tax = localStorage.getItem("tax")
                    ? localStorage.getItem("tax")
                    : 10;
                  data[i] = {
                    ...data[i],
                    price: value,
                    tax: value ? value * (parseInt(tax) / 100) : 0,
                  };
                  setTotal(
                    items
                      .map((v) => parseInt(v.price ? v.price : 0))
                      .reduce((t, v) => t + v)
                  );
                  setCounter(c => c + 1)
                  return data;
                })
              }
            />
          </div>
          <div className="col-2 p-0  d-flex justify-content-center align-items-center">
            {
              items.length > 1 ?
                <img
                  src={wrong}
                  alt=""
                  className="img-fluid"
                  style={{ height: "20px" }}
                  onClick={() => {
                    if (i !== 0) {
                      console.log("aaaaaa")
                      setItems((data) => {
                        data.splice(i, 1);
                        return data;
                      });
                      setTotal(
                        items
                          .filter((item, index) => {
                            if (index == i) {
                              // console.log(item);
                              return;
                            }
                            else {
                              return item
                            }
                          })
                          .map((v) => parseInt(v.price ? v.price : 0))
                          .reduce((t, v) => t + v)
                      );
                      setCounter(c => c + 1)
                      console.log("aaaaa2", items)
                    }
                  }}
                />
                : null
            }
          </div>
        </div>
      ))
    )
  };



  return (
    <>
      <Loader load={load} />
      <section className="estimate__section">
        <Navbar heading='Pay Estimate' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "81%", position: "relative", height: "91vh" }}>
            <div className="col-6 p-0 d-flex align-items-center justify-content-end">
              {/* <img
            src={humburger}
            alt="Notification"
            className="img-fluid ml-auto notification_img navbar__img"
          /> */}
              <i
                class="fa fa-check"
                aria-hidden="true"
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={(e) => {
                  if (paidAmount <= 0 || paidAmount == '' || parseFloat(paidAmount) > parseFloat(new_b)) {
                    notifyError("Enter correct amount")
                  }
                  else {
                    showConfirmAlert(handleSubmit)
                  }
                }}
              ></i>
            </div>
            <div className="estimate__content container">
              <div className="row mt-3">
                <div className="col-2 offset-1 d-flex justify-content-center align-items-center estimate__col">
                  <img
                    src={id}
                    alt=""
                    className="img-fluid"
                    style={{ height: "20px" }}
                  />
                </div>
                <div className="col-7 pl-0 estimate__col">
                  {/* <small className="estimate__dark">Customer ID</small>
            <p className="estimate__light">GRGCU0215</p> */}
                  <Select
                    options={vehicleData.map((val) => ({
                      label: val.customer_name,
                      value: val.customer_id,
                    }))}
                    placeholder="Customer"
                    isOptionDisabled={(option) => {
                      if (JSON.parse(localStorage.getItem("addEstimateData"))) {
                        return option.value.customer_id === customerId
                          ? false
                          : true;
                      } else {
                        return false;
                      }
                    }}
                    components={{
                      Option: CustomOption, DropdownIndicator: () => null, IndicatorSeparator: () => null
                    }}
                    onChange={(v) => {
                      if (v) {
                        setCustomer(v.value.customer_id);
                        setVin(v.value.vin_number);
                        setReg(v.value.reg_number);
                        setSelectValue(v);
                      }
                    }}
                    isDisabled={true}
                    value={{ label: customer_name, value: customerId }}
                    styles={{
                      container: (c) => ({ ...c, color: "black", border: "none", background: "none" }),
                      control: (c) => ({ ...c, color: "black", border: "none", background: "none" })
                    }}
                  ></Select>
                </div>
              </div>
              {/* <div className="row mt-5">
          <div className="col-2 offset-1 d-flex justify-content-center  estimate__col">
            <img
              src={name}
              alt=""
              className="img-fluid"
              style={{ height: "20px" }}
            />
          </div>
          <div className="col-7 pl-0 estimate__col">
            <p className="estimate__light">Name</p>
          </div>
        </div> */}
              <div className="row mt-3">
                <div className="col-2 offset-1 d-flex justify-content-center estimate__col">
                  <img
                    src={password}
                    alt=""
                    className="img-fluid"
                    style={{ height: "20px" }}
                  />
                </div>
                <div className="col-7 pl-0 estimate__col">
                  <p className="estimate__light">VIN No./Reg No. {vin}</p>
                  {/* <p className="estimate__light">Car Ragistration Number {reg}</p> */}
                </div>
              </div>
            </div>
            <div className="container estimate__bottom__card mt-5">
              <div className="row">
                <div className="col-12 p-0">
                  <div className="card estimate__card">
                    <div className="card-body pb-5">
                      <p
                        className="font-weight-bold text-dark d-flex justify-content-between"
                        style={{ fontSize: "12px" }}
                      >
                        SERVICE WITH CARS
                        <span style={{ fontSize: "13px" }}>Balance: {new_b}</span>
                      </p>
                      {itemsUI}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="generate-invoice_container">
              <div
                // style={{borderTop:"1px solid black"}}
                style={{ position: "relative" }}
                className="generate-invoice-data d-flex align-items-center justify-content-center px-3 py-1">
                <p className="data">$ &nbsp; Enter pay amount:</p>
                <input className=" bg-transparent ml-2" onPaste={(e) => {
                  e.preventDefault()
                  return false;
                }
                }
                  value={paidAmount}
                  onChange={e => {

                    setPaidAmount(removeSpacesAndAlphabets(e.target.value));
                  }} style={{
                    borderRadius: "4px",
                    width: "100px",
                    border: "1px solid #c9c9c9"
                  }} type="text" />
                <div style={{ position: "absolute", right: "15px" }}>
                  <div className="d-flex align-items-center justify-content-center ">
                    <p htmlFor="myCheckbox" style={{ color: "#000", marginTop: "17px" }}>No tax:</p>
                    &nbsp;
                    <input type="checkbox" id="myCheckbox" checked={no_tax} name="myCheckbox"
                      onChange={(e) => {
                        // console.log(e.target.value);
                        // setno_tax(!no_tax);
                        if (no_tax) {
                          setno_tax(false);
                        }
                        else {
                          setno_tax(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ fontWeight: "600", borderTop: "2px solid #c9c9c9" }}
                className="generate-invoice-data d-flex justify-content-between px-3 py-1">
                <p className="data">SERVICE: {items.length}</p>
                <p className="data">TO BE PAID: {new_b > 0 ? new_b : total == total_amt ? total_amt : parseInt(total) + (!no_tax ? ((parseInt(total) * parseInt(localStorage.getItem("tax"))) / 100) : 0)}</p>
                <p className="data">
                  TAX: {!no_tax ? parseFloat(tax).toFixed(2) : 0.00}
                </p>
              </div>
              <button
                className="generate-invoice-btn text-center"
                onClick={(e) => showConfirmAlert(handleSubmit)}
                disabled={paidAmount <= 0 || paidAmount == '' || parseFloat(paidAmount) > parseFloat(new_b) ? true : false}
              >
                Pay Invoice
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Estimate;
