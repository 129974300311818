import React from "react";
import profile from "../assets/profile.png";
import passwordIcon from "../assets/password.png";
import emailIcon from "../assets/email.png";
import phoneIcon from "../assets/phone.png";
import "./SignUp.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { signUp } from "../services/httpService";
import { notifyError, notifySuccess } from "../util/Util";
import Loader from "./Loader";
import logo from "../assets/sw_logo.png";
import shop_pic from "../assets/shop.jpg"

import { useMediaQuery } from '@mui/material';


function SignUp() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const passwordRegex = /^(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    let emailVal = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    if (email && username && password && password.length >= 5 && password.length <= 15 && phone && emailVal) {
      if (password === confirmPassword) {
        if (passwordRegex.test(password)) {
          setLoad(true)
          signUp(username, email, phone, password).then((res) => {
            try {
              if (res["data"]["shop_wizard"]["status"] == "0") {
                notifyError(res["data"]["shop_wizard"]["msg"])
                setLoad(false)
              }
              if (res["data"]["shop_wizard"]["status"] == "1") {
                setLoad(false)
                localStorage.setItem(
                  "vendor_id",
                  res["data"]["shop_wizard"]["vendor_id"]
                );
                localStorage.setItem(
                  "vendor_reg_email",
                  email
                );
                localStorage.setItem(
                  "vendor_name",
                  username
                );

                // localStorage.setItem
                history.push("/vendor-profile");
              }
            } catch (error) {
              // notifyError("Something went wrong!")
            }
          });
        }
        else {
          notifyError("Password should contain atleast one number and one special symbol!")
        }
      } else {
        notifyError("Password dont match!")
      }
    } else {
      let validate = [
        { name: "Username", value: username },
        { name: "Email", value: email },
        { name: "Valid email", value: emailVal },
        { name: "Phone", value: phone },
        { name: "Password", value: password },
        { name: "Password at least 8 character", value: password.length >= 8 },
      ]
      validate.some(v => {
        if (v.value) {
          return false
        } else {
          notifyError("Please enter " + v.name)
          return true
        }
      })
      // notifyError("Please fill all the fields!")
    }
  };

  return (
    <>
      <Loader load={load}></Loader>
      <section className="singup_section">
        {/*<div className="container">
          <div className="row">
            <div className="col-12 mt-5 ml-3">
              <h2 className="font-weight-bold">Sign up</h2>
            </div>
          </div>
  </div>*/}
        <div style={{ width: "100hw", height: "100vh" }}>
          <div className="row">
            {
              !matches_820 &&
              <div className="col-4 p-0" style={{
                background: "#1c1b3b url(" + shop_pic + ") no-repeat bottom center",
                // backgroundImage: "url(" + shop_pic + ")",
                position: "relative",
                backgroundPosition: "bottom 0px ;",
                // back
                height: "100vh",
                backgroundSize: "460px",
                backgroundRepeat: "no-repeat",
                display: "flex", justifyContent: "center", backgroundColor: "#1c1b3b", alignItems: "center"
              }}>
              <div style={{ position: "absolute",width:"400px",left:"90px", backgroundColor: "#1c1b3b",bottom:"15px",fontSize:"40px" }}>
                  <span>Service at it's best!</span>
              </div>
                <img src={logo} style={{ position: "absolute", top: "90px" }} />
              </div>
            }
            <div className={matches_820 ? "col-12 p-0" : "col-8 p-0"}>
              <div className="container mt-4" style={{ paddingTop: "8%", width: "70%" }}>
                <div className="row">
                  <h3 style={{ marginLeft: "42px", color: "#000", width: "100%" }}>Vendor Sign Up</h3>
                  <div className="col-12 p-0">
                    <div className="card signup_card">
                      <div className="card-body pt-0">
                        <div className="container mt-3">
                          <div className="row">
                            <div className="col-12">
                              <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1" className="ml-3">
                                    Vender’s Name
                                  </label>
                                  <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                      >
                                        <img
                                          src={profile}
                                          alt=""
                                          className="img-fluid login__img"
                                        />
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control login__input"
                                      placeholder="Username"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      autoComplete="off"
                                      name="username"
                                      value={username}
                                      onChange={(event) =>
                                        setUsername(event.target.value.length < 16 ? event.target.value : username)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1" className="ml-3">
                                    Email
                                  </label>
                                  <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                      >
                                        <img
                                          src={emailIcon}
                                          alt=""
                                          className="img-fluid"
                                          style={{ height: "13px" }}
                                        />
                                      </span>
                                    </div>
                                    <input
                                      type="email"
                                      className="form-control login__input"
                                      placeholder="Email"
                                      aria-label="Email"
                                      aria-describedby="basic-addon1"
                                      autoComplete="password"
                                      name="email"
                                      value={email}
                                      onChange={(event) => setEmail(event.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1" className="ml-3">
                                    Phone
                                  </label>
                                  <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                      >
                                        <img
                                          src={phoneIcon}
                                          alt=""
                                          className="img-fluid login__img"
                                        />
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      pattern="[1-9]{1}[0-9]{9}"
                                      maxLength={10}
                                      className="form-control login__input"
                                      placeholder="Phone"
                                      autoComplete="password"
                                      aria-label="Phone"
                                      aria-describedby="basic-addon1"
                                      name="phone"
                                      value={phone}
                                      onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mt-5">
                                  <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                      >
                                        <img
                                          src={passwordIcon}
                                          alt=""
                                          className="img-fluid login__img"
                                        />
                                      </span>
                                    </div>
                                    <input
                                      type={type}
                                      className="form-control login__input"
                                      placeholder="Password"
                                      aria-label="Password"
                                      aria-describedby="basic-addon1"
                                      autoComplete="off"
                                      name="password"
                                      minLength={"8"}
                                      maxLength={"15"}
                                      value={password}
                                      onChange={(event) =>
                                        setPassword(5 < event.target.value.length < 16 ? event.target.value : password)
                                      }
                                    />
                                    <div className="input-group-append">
                                      <span class="input-group-text login__span cursor-pointer" onClick={e => setType(v => v === "password" ? "text" : "password")}>
                                        {type === "password" ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group mt-5">
                                  <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text login__span"
                                        id="basic-addon1"
                                      >
                                        <img
                                          src={passwordIcon}
                                          alt=""
                                          className="img-fluid login__img"
                                        />
                                      </span>
                                    </div>
                                    <input
                                      type={type1}
                                      className="form-control login__input"
                                      placeholder="Confirm Password"
                                      aria-label="Confirm Password"
                                      aria-describedby="basic-addon1"
                                      autoComplete="off"
                                      minLength={"5"}
                                      maxLength={"15"}
                                      name="confirm_password"
                                      value={confirmPassword}
                                      onChange={(event) =>
                                        setConfirmPassword(event.target.value)
                                      }
                                    />
                                    <div className="input-group-append">
                                      <span class="input-group-text login__span cursor-pointer" onClick={e => setType1(v => v === "password" ? "text" : "password")}>
                                        {type === "password" ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="row mt-5">
                                  <div className="col-12">
                                    <button className="btn login__submit_button mt-md-1">
                                      Sign up
                                    </button>
                                  </div>
                                </div>

                                <div className="row login__submit__text mt-md-2">
                                  <div className="col-12">
                                    <p>
                                      Already have an Account?
                                      <b>
                                        <Link to="/">Login here</Link>
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignUp;
