import React, { useContext, useRef, useState } from "react";
import "./Customer.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import down from "../assets/down.png";
import add from "../assets/add.png";
import { useEffect } from "react";
import {
  getAllCustomer,
  getAllVehicle,
  get_customer_account,
  searchCustomer,
} from "../services/httpService";
import Loader from "./Loader";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";
import Ncontext from "../ContextC";
import { useReactToPrint } from "react-to-print";
import Print_Vehicle from "./Print_vehicle";

function Vehicle() {

  const componentRef = useRef();
  const [printflag, setprintflag] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setprintflag(true)
    },
    onAfterPrint: () => {
      setprintflag(false)
    }
  });

  const vendor_data = localStorage.getItem('vendor_data');
  const parsed_data = vendor_data ? JSON.parse(vendor_data) : '';
  const vendor_name = parsed_data ? parsed_data?.name : '';

  const context = useContext(Ncontext);

  const { setname } = context;

  const matches_820 = useMediaQuery('(max-width:820px)');
  const [vehicleData, setVehicleData] = useState([]);
  const [load, setLoad] = useState(false);
  const [showData, setShowData] = useState([]);
  const [query, setQuery] = useState("");
  const ventor_id = localStorage.getItem("vendor_id");
  const history = useHistory();
  useEffect(() => {
    setLoad(true);
    get_customer_account(ventor_id).then((res) => {
      setLoad(false);
      if (res["data"]["shop_wizard"]["status"] === "1") {
        console.log(res["data"]);
        setVehicleData(res["data"]["shop_wizard"]["customer_account"]);
        setShowData(res["data"]["shop_wizard"]["customer_account"]);
      }
    });
  }, []);

  useEffect(() => {
    if (query) {
      setShowData(
        vehicleData.filter((v) =>
          v.name.toLowerCase().includes(query.toLowerCase())
          || JSON.stringify(v.email).toLowerCase().includes(query.toLowerCase())
          || JSON.stringify(v.contact).toLowerCase().includes(query.toLowerCase())
          // || JSON.stringify(v.reg_number).toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      setShowData(vehicleData);
    }
  }, [query]);

  const matches_545 = useMediaQuery('(max-width:545px)');

  const thead_padding = matches_545 ? { paddingRight: "5px", paddingLeft: "5px" } : {};


  return (
    <>
      <Loader load={load} />
      <section className="customer__section">
        <Navbar heading={"Accounts"} />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80%" }}>
            <div class="input-group col-md-12 search-bar_container py-3">
              <input
                class="form-control py-2 border-right-0 border"
                type="search"
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                id="example-search-input"
              />
              <span class="input-group-append">
                <button
                  class="btn btn-outline-secondary border-left-0 border"
                  type="button"
                >
                  <i class="fa fa-search"></i>
                </button>
              </span>
              <span class="input-group-append">
                <button
                  class="btn btn-outline-secondary border-left-0 border"
                  type="button"
                  onClick={handlePrint}
                >
                  <i class="fa fa-print"></i>
                </button>
              </span>
            </div>

            <div className="customer__content" style={{ height: "80vh", overflowY: "auto" }}>
              <div style={{ display: "none" }}>
                <div ref={componentRef} style={{ width: "100%", padding: "0px 0px 0px 12px", height: "80vh", overflowY: "auto" }}>
                  <Print_Vehicle data={showData} />
                </div>
              </div>
              <div style={{ width: "100%", padding: "0px 0px 0px 12px", height: "80vh", overflowY: "auto" }}>
                <table style={{ width: "100%", color: "#000" }}>
                  <thead style={{ borderBottom: "1px solid #c9c9c9", borderTop: "1px solid #c9c9c9", fontSize: matches_545 ? "14px" : "" }}>
                    <th style={{ padding: "3px 3px 3px 8px", ...thead_padding }} className="text-uppercase">#</th>
                    <th style={{ padding: "3px", ...thead_padding }} className="text-uppercase">Customer Name</th>
                    <th className="text-uppercase" style={thead_padding} >Email</th>
                    <th className="text-uppercase" style={{ ...thead_padding, textAlign: "center" }} >Phone</th>
                    <th className="text-uppercase" style={{ ...thead_padding, textAlign: "center" }} >Fleet</th>
                    <th className="text-uppercase" style={{ ...thead_padding, textAlign: "center" }} >Invoices</th>
                    <th className="text-uppercase" style={{ ...thead_padding, textAlign: "right", paddingRight: "16px" }} >Amount</th>
                    <th className="text-uppercase" style={{ ...thead_padding, textAlign: "right", paddingRight: "16px" }} >Due</th>
                    <th className="text-uppercase" style={{ ...thead_padding, textAlign: "center" }} >Status</th>
                  </thead>

                  <tbody>
                    {
                      showData.length > 0 &&
                      showData.map((el, index) => {
                        return (
                          <tr
                            onClick={
                              () => {
                                setname(el.name);
                                history.push(`/invoice`);
                              }}
                            style={{ cursor: "pointer", background: ((index % 2) == 0) ? "#fff" : "#f5f5f5", fontSize: matches_545 ? "14px" : "16px" }} >
                            <td style={{ paddingLeft: "8px" }}>{index + 1}</td>
                            <td>{el.name}</td>
                            <td>{el.email}</td>
                            <td style={{ textAlign: "center" }}>{el.contact}</td>
                            <td style={{ textAlign: "center" }}>{el.fleets}</td>
                            <td style={{ textAlign: "center" }}>{el.total_invoices}</td>
                            <td style={{ textAlign: "right", paddingRight: "16px" }}>{parseFloat(el.invoice_amount).toFixed(2)}</td>
                            <td style={{ textAlign: "right", paddingRight: "16px" }}>{parseFloat(el.due_amount).toFixed(2)}</td>
                            <td style={{ textAlign: "center" }}>{el.customer_status == 1 ? 'Active' : 'Inactive'}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </section >
    </>
  );
}

export default Vehicle;
