import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import back from "../assets/back.png";
import "./Estimate2.css";
import "./AddTemplate.css";
import { editMessage, getAllMessages } from "../services/httpService";
import { notifyError, notifySuccess } from "../util/Util";
import Loader from "./Loader";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";

function AddTemplate(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const [desc, setMessage] = useState("");
  const [load, setLoad] = useState(false);
  const ventor_id = localStorage.getItem("vendor_id");
  const [title, settitle] = useState('');
  const history = useHistory()
  useEffect(() => {
    setLoad(true)
    getAllMessages(ventor_id)
      .then((res) => {
        if (res["data"]["shop_wizard"]["status"] == "1") {
          setLoad(false)
          if (!res["data"]["shop_wizard"].hasOwnProperty('sms_template')) {
            setMessage(res["data"]["shop_wizard"]["email_template"].filter(v => v.template_id === props.match.params.id)[0].template_desc);
            settitle(res["data"]["shop_wizard"]["email_template"].filter(v => v.template_id === props.match.params.id)[0].subject);
          } else if (!res["data"]["shop_wizard"].hasOwnProperty('email_template')) {
            setMessage((res.data.shop_wizard.sms_template).filter(v => v.template_id === props.match.params.id)[0].template_desc);
            settitle((res.data.shop_wizard.sms_template).filter(v => v.template_id === props.match.params.id)[0].subject);
          }
          else {
            setMessage(res["data"]["shop_wizard"]["email_template"].concat(res.data.shop_wizard.sms_template).filter(v => v.template_id === props.match.params.id)[0].template_desc);
            settitle(res["data"]["shop_wizard"]["email_template"].concat(res.data.shop_wizard.sms_template).filter(v => v.template_id === props.match.params.id)[0].subject);
          }
          // setMessage(res["data"]["shop_wizard"]["email_template"].concat(res.data.shop_wizard.sms_template).filter(v => v.template_id === props.match.params.id)[0].template_desc);
        }
      });
    return () => { };
  }, []);
  return (
    <>
      <Loader load={load} />
      <section className="estimate__section">
        <Navbar heading='Edit Template' />
        <div style={{ display: "flex" }}>
        {
          !matches_820 &&
          <div style={{ width: "19%" }}>
            <SideBar />
          </div>
        }
          <div style={{ width:matches_820 ? "100%" : "80.9%" }}>
            <div className="add-template_container d-flex flex-wrap" style={{ padding: "0px 5px" }}>
              <form onSubmit={e => {
                e.preventDefault()
                if (desc && title) {
                  setLoad(true)
                  editMessage(props.match.params.id, desc, title).then((res) => {
                    setLoad(false)
                    notifySuccess("Template updated successfully")
                    history.push("/message")
                  }).catch(err => {
                    notifyError("Something went wrong!")
                  })
                } else {
                  notifyError("Please enter all fields")
                }
              }} style={{ width: "100%" }} >
                <div className="col-12 pt-3">
                  <label htmlFor="" className="d-block mb-2 text-secondary">
                    <span style={{ fontSize: "14px" }} >Title</span>
                  </label>
                  <input
                    name=""
                    id="formDesc"
                    className="form-control"
                    value={title}
                    placeholder="title"
                    style={{ boxShadow: "0 0 5px 1px #ddd", fontSize: "12px", }}
                    onChange={e => settitle(e.target.value)}
                  />
                </div>
                <div className="col-12 pt-3">
                  <label htmlFor="" className="d-block mb-2 text-secondary">
                    <span style={{ fontSize: "14px" }} >Description</span>
                  </label>
                  <textarea
                    name=""
                    // cols="55"
                    // rows="10"
                    className="form-control"
                    value={desc}
                    id="formDesc"
                    placeholder="Please type your message"
                    onChange={e => setMessage(e.target.value)}
                    style={{ fontSize: "12px" }}
                  ></textarea>
                  <button className="filled-btn mt-4">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddTemplate;
