import React, { useContext, useState } from "react";
import "./Dashboard.css";
import back from "../assets/back.png";
import { Link } from "react-router-dom";
import humburger from "../assets/humburger.png";
import ProfileIcon from "../assets/profileIcon.png";
import customer from "../assets/customer.png";
import car from "../assets/CAR.png";
import est from "../assets/est.png";
import invoice from "../assets/invoice.png";
import app from "../assets/appoint.png";
import msg from "../assets/message.png";
import profileIcon from "../assets/profileIcon.png";
import home from "../assets/home.png";
import add from "../assets/add.png";
import { useEffect } from "react";
import { getDashBoard } from "../services/httpService";
import { notifyError } from "../util/Util";
import Loader from "./Loader";
import notification from "../assets/notification.png";
import { useHistory } from "react-router-dom";
import Ncontext from "../ContextC";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";
import { sendSMS } from "../utilities/Send_sms";

function Dashboard() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const matches_545 = useMediaQuery('(max-width:545px)');
  const history = useHistory();
  const [data, setData] = useState({});
  const context = useContext(Ncontext);
  const { total_cust, settotal_cust } = context;
  const [load, setLoad] = useState(false);
  const ventor_id = localStorage.getItem("vendor_id");
  useEffect(() => {
    // alert(ventor_id)
    setLoad(true)
    getDashBoard(ventor_id)
      .then((res) => {
        if (res["data"]["shop_wizard"]["status"] == "1") {
          // alert(res["data"]["shop_wizard"].length);
          settotal_cust(res["data"]["shop_wizard"].total_customer)
          setData(res["data"]["shop_wizard"]);
          // alert(res.data.shop_wizard.tax)
          localStorage.setItem("tax", res.data.shop_wizard.tax)
          console.log(res["data"]["shop_wizard"]);
        }
        setLoad(false)
      }).catch(err => {
        // notifyError("Something went wrong")
      });
    return () => { };
  }, []);

  console.log(data)

  return (
    <>
      <Loader load={load} />
      <section className="dashboard_section">
        <Navbar heading='DASHBOARD' />
        {/*<button onClick={() => { sendSMS('+916386711139','Hello') }}>Click</button>*/}


        <div style={{ display: "flex", alignItems: "center" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }

          <div className="container-fluid mt-2 dashboard__content" style={{ width: !matches_820 ? "80%" : "100%" }}>
            <div className="row">
              <div className="col-12 mt-2 mb-3">
                <div className="card dashboard__card">
                  <div className="card-body pt-2 pb-3 p-1 px-4">
                    <Link to="/invoice">
                      <p className="mt-2 font-weight-bold mb-0" style={{ fontSize: "20px" }}>
                        Sale's Summary
                      </p>
                    </Link>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <p className="mt-2 mb-0">
                        <span style={{ color: '#777', fontWeight: "600" }}> Total Invoice:  </span> <strong>{data.todays_invoice}</strong>
                      </p>
                      <p className="mt-2 mb-0">
                        <span className="sales_summry" style={{ color: '#777', fontWeight: "600" }}> Total Paid:</span> <strong> {data.total_paid_amount}</strong>
                      </p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <p className="mt-2 mb-0">
                        <span className="sales_summry" style={{ color: '#777', fontWeight: "600" }}>Total Amount:  </span><strong> {data.total_invoice_amount}</strong>
                      </p>
                      <p className="mt-2 mb-0">
                        <span className="sales_summry" style={{ color: '#777', fontWeight: "600" }}>  Total Balance:</span><strong> {data.balance_amount}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-6 mt-2">
                <Link to="/customer">
                  <div className="card dashboard__card">
                    <div className="card-body">
                      <img src={customer} alt="" className="img-fluid" />
                      <div className="mt-3" style={matches_545 ? { display: "flex", alignItems: "center" } : {}}>
                        <p className="font-weight-bold mb-0">Customer</p>
                        <p className="mb-0">({data.total_customer})</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-6 mt-2">
                {data.total_customer == 0 ?
                  <div className="card dashboard__card">
                    <div className="card-body">
                      <img src={car} alt="" className="img-fluid" />
                      <div className="mt-3" style={matches_545 ? { display: "flex", alignItems: "center" } : {}}>
                        <p className="font-weight-bold mb-0">Vehicles</p>
                        <p className="mb-0">({data.total_vehicles})</p>
                      </div>
                    </div>
                  </div>
                  : <Link to="/vehicle">
                    <div className="card dashboard__card">
                      <div className="card-body">
                        <img src={car} alt="" className="img-fluid" />
                        <div className="mt-3" style={matches_545 ? { display: "flex", alignItems: "center" } : {}}>
                          <p className="font-weight-bold mb-0">Vehicles</p>
                          <p className="mb-0">({data.total_vehicles})</p>
                        </div>
                      </div>
                    </div>
                  </Link>}
              </div>
              <div className="col-6 mt-3">
                <Link to="/estimate-list">
                  <div className="card dashboard__card">
                    <div className="card-body">
                      <img src={est} alt="" className="img-fluid" />
                      <div className="mt-3" style={matches_545 ? { display: "flex", alignItems: "center" } : {}}>
                        <p className="font-weight-bold mb-0">Estimate</p>
                        <p className="mb-0">({data.total_estimate})</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-6 mt-3">
                {data.total_customer == 0 ?
                  <div className="card dashboard__card">
                    <div className="card-body">
                      <img src={app} alt="" className="img-fluid" />
                      <div className="mt-3" style={matches_545 ? { display: "flex", alignItems: "center" } : {}}>
                        <p className="font-weight-bold mb-0">Appointment</p>
                        <p className="mb-0">({data.total_appointment})</p>
                      </div>
                    </div>
                  </div>
                  : <Link to="/appointment">
                    <div className="card dashboard__card">
                      <div className="card-body">
                        <img src={app} alt="" className="img-fluid" />
                        <div className="mt-3" style={matches_545 ? { display: "flex", alignItems: "center" } : {}}>
                          <p className="font-weight-bold mb-0">Appointment</p>
                          <p className="mb-0">({data.total_appointment})</p>
                        </div>
                      </div>
                    </div>
                  </Link>}
              </div>
              <div className="col-6 mt-3">
                <Link to="/invoice">
                  <div className="card dashboard__card">
                    <div className="card-body">
                      <img src={invoice} alt="" className="img-fluid" />
                      <div className="mt-3" style={matches_545 ? { display: "flex", alignItems: "center" } : {}}>
                        <p className="font-weight-bold mb-0">Invoice</p>
                        <p className="mb-0">({data.total_invoice})</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-6 mt-3">
                <Link to="/message">
                  <div className="card dashboard__card">
                    <div className="card-body">
                      <img src={msg} alt="" className="img-fluid" />
                      <div className="mt-3" style={matches_545 ? { display: "flex", alignItems: "center" } : {}}>
                        <p className="font-weight-bold mb-0">Marketing</p>
                        <p className="mb-0">({data.total_message})</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 mt-3 mb-5">
                {/* <Link to="/message"> */}

                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>

        {/*<nav className="navbar navbar-inverse dashboard-nav fixed-bottom navbar-light bg-white p-0">
          <div className="navbar-collapse">
            <ul className="navbar-nav d-flex align-items-strech justify-content-between">
              <li className="nav-item d-flex justify-content-center align-items-center">
                <Link
                  to="/dashboard"
                // style={{
                //   backgroundColor: "rgba(16, 80, 230, 0.04)",
                //   padding: "5px 10px",
                //   borderRadius: "15px",
                // }}
                >
                  <img
                    src={home}
                    alt=""
                    className="img-fluid"
                    style={{ height: "20px" }}
                  />{" "}
                </Link>
              </li>
              <li className="nav-item">
                {
                  data.total_customer == 0 ?
                    <img
                      src={add}
                      alt=""
                      className="img-fluid dashboard_add_img"
                    /> :
                    <Link to="/add">
                      <img
                        src={add}
                        alt=""
                        className="img-fluid dashboard_add_img"
                      />
                    </Link>
                }
              </li>
              <li className="nav-item d-flex justify-content-center align-items-center">
                <Link to="/profile">
                  <img
                    src={data.logo_url ? data.logo_url : ProfileIcon}
                    alt=""
                    className="bottomImage"
                  />
                </Link>
              </li>
            </ul>
          </div>
              </nav>*/}
      </section>
    </>
  );
}

export default Dashboard;
