import logo from "./logo.svg";
import React, { useEffect, useState } from 'react'
import "./App.css";
import Logo from './assets/sw_logo.png'
import Login from "./components/Login";
import { Route, Switch } from "react-router-dom";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import Add from "./components/Add";
import Profile from "./components/Profile";
import Appointment from "./components/Appointment";
import Customer from "./components/Customer";
import CustomerDetail from "./components/CustomerDetail";
import Estimate from "./components/Estimate";
import Message from "./components/Message";
import AddAppointment from "./components/AddAppointment";
import AddCustomer from "./components/AddCustomer";
import EditProfile from "./components/EditProfile";
import EstimateList from './components/Estimate2'
import EstimateEdit from './components/EstimateEdit'
import PayEstimate from './components/PayEstimate'
import Invoice from './components/Invoice'
import Vehicle from './components/Vehicle'
import VendorProfile from './components/VendorProfile'
import AddTemplate from './components/AddTemplate'
import EditTemplate from './components/EditTemplate'
import PrivateRoute from "./ValidationRoute";
import EditAppointment from "./components/EditAppointment";
import Send from "./components/Send";
import NewEstimate from "./components/NewEstimate";
import InvoiceDetail from "./components/InvoiceDetail";
// import BillPage from "./co"
// import { changeState } from "./services/httpService";
import InvoiceBillPage from './components/InvoiceBill'
import Ncontext from "./ContextC";
import NewCustomerEstimate from "./components/NewCustomerEstimate";
import Stripe_Payment from "./components/Stripe_Payment/index";
import Reset_password from "./components/Reset_password";
import Invoice_Summary from "./components/Invoice_summary";
import Img_to_text from "./components/Img_to_text";
import Payment_Page_new from "./components/Stripe_Payment/Payment_page_new";


function App() {
  var interval;
  const [progress, setProgress] = useState(0)
  const [start, setStart] = useState(false);
  const [name, setname] = useState('');

  window.addEventListener("DOMContentLoaded", e => {
    setStart(true)
    setTimeout(() => {
      document.querySelector(".splashScreen")?.classList.remove("d-flex")
      document.querySelector(".splashScreen")?.classList.add("d-none")
      clearInterval(interval)
    }, 1500)
  })
  useEffect(() => {
    interval = setInterval(() => {
      setProgress(p => {
        // console.log(p)
        if ((p + 1) > 100) {
          clearInterval(interval)
        }
        return (p + 1)
      })
    }, 10)
  }, [start])

  const [InvoiceNumbill, setInvoiceNumbill] = useState([]);
  const [itemDetailbill, setItemDetailbill] = useState([]);
  const [vendorDetailbill, setVendorDetailbill] = useState("");
  const [customerDetailbill, setCustomerDetailbill] = useState("")
  const [templateTypeNum, settemplateTypeNum] = useState("");
  const [total_cust, settotal_cust] = useState(0);
  const [hApp, sethApp] = useState(0);
  const [mApp, setmApp] = useState(0)

  document.onkeydown = function (e) {
    console.log(e.code)
    if (e.code == 'Quote') {
      return false
    }
  }

  return (
    <Ncontext.Provider value={{ mApp, setmApp, hApp, sethApp, total_cust, settotal_cust, templateTypeNum, settemplateTypeNum, name, setname, InvoiceNumbill, setInvoiceNumbill, itemDetailbill, setItemDetailbill, customerDetailbill, setCustomerDetailbill, vendorDetailbill, setVendorDetailbill }} >

      <div className="app">
        {/* <Login /> */}
        <div className="splashScreen d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img src={Logo} style={{ width: "100px" }} />
            <p style={{ color: "blue" }} className="my-auto pb-3 pt-5">LOADING...</p>
            <div className="mx-auto my-auto py-auto progression" style={{ borderRadius: "3px", overflow: "hidden", height: "3px", width: "220px", background: "#ddd" }} >
              <div className="" style={{ width: `${progress}%`, height: "100%", background: "blue" }}>
              </div>
            </div>
          </div>
        </div>
        <Switch>
          {/* <Route exact path="/" component={BillPage}></Route> */}
          <Route exact path="/" component={Login}></Route>
          <Route path="/Signup" component={SignUp}></Route>
          <Route path="/reset_pass/:token" component={Reset_password}></Route>
          <Route path="/vendor-profile" component={VendorProfile}></Route>
          <Route path="/payment" component={Stripe_Payment}></Route>
          <Route path="/payment-new" component={Payment_Page_new}></Route>
          <PrivateRoute path="/dashboard" component={Dashboard}></PrivateRoute>
          <PrivateRoute path="/add" component={Add}></PrivateRoute>
          <PrivateRoute path="/profile" component={Profile}></PrivateRoute>
          <PrivateRoute path="/vehicle" component={Vehicle}></PrivateRoute>
          <PrivateRoute path="/edit-profile" component={EditProfile}></PrivateRoute>
          <PrivateRoute path="/appointment" component={Appointment}></PrivateRoute>
          <PrivateRoute path="/add-template" component={AddTemplate}></PrivateRoute>
          <PrivateRoute path="/edit-template/:id" component={EditTemplate}></PrivateRoute>
          <PrivateRoute path="/customer" exact component={Customer}></PrivateRoute>
          <PrivateRoute path="/customer-detail/:id" exact component={CustomerDetail}></PrivateRoute>
          {/*<PrivateRoute path="/estimate" component={Estimate}></PrivateRoute>*/}
          <PrivateRoute path="/new-estimate" component={NewEstimate}></PrivateRoute>
          <PrivateRoute path="/edit-estimate/:id" component={EstimateEdit}></PrivateRoute>
          <PrivateRoute path="/pay-estimate/:id" component={PayEstimate}></PrivateRoute>
          <PrivateRoute path="/estimate-list" component={EstimateList}></PrivateRoute>
          <PrivateRoute path="/invoice" component={Invoice}></PrivateRoute>
          <PrivateRoute path="/invoice-detail/:id" component={InvoiceDetail}></PrivateRoute>
          <PrivateRoute path="/message" component={Message}></PrivateRoute>
          <PrivateRoute path="/send/:id" component={Send}></PrivateRoute>
          <PrivateRoute path="/add-appointment" component={AddAppointment}></PrivateRoute>
          <PrivateRoute path="/edit-appointment/:appoint_id" component={EditAppointment}></PrivateRoute>
          <PrivateRoute path="/add-customer" component={AddCustomer}></PrivateRoute>
          <PrivateRoute path="/bill" component={InvoiceBillPage}></PrivateRoute>
          <PrivateRoute path="/new-cust-estimate" component={NewCustomerEstimate}></PrivateRoute>
          <PrivateRoute path="/invoice-summary" component={Invoice_Summary}></PrivateRoute>
        </Switch>
      </div>
    </Ncontext.Provider>
  );
}

export default App;
