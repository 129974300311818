import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddCustomer_popup from "../components/AddCustomer_popup";
// export const notifySuccess = (text) => {
//     Swal.fire({
//         title: "Success",
//         text,
//         icon: "success",
//         confirmButtonText: "Ok"
//     });
// }

// export const notifyError = (text) => {
//     Swal.fire({
//         title: "Error!",
//         text,
//         icon: "error",
//         confirmButtonText: "Ok"
//     });
// }

export const img_to_text_url = 'http://api.ocr.space/parse/image';

export const removeSpacesAndAlphabets = (input) => {
    // Use a regular expression to remove spaces and alphabets
    const cleanedInput = input.replace(/[^0-9.]/g, '');

    // Split the string into two parts: before and after the decimal point
    const parts = cleanedInput.split('.');

    // If there are multiple decimal points, keep only the first one
    if (parts.length > 2) {
        return parts[0] + '.' + parts.slice(1).join('');
    }

    return cleanedInput;
}


const ResizeFile = (file, l, b) => {

    const length = l || 700;
    const breadth = b || 800;

    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            length,
            breadth,
            "PNG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    })
};

export default ResizeFile;

export const notifySuccess = (text) => {
    confirmAlert({
        title: 'Success',
        message: text,
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui d-flex flex-column' style={{ width: "300px", background: "white", padding: "20px", border: "1px solid #ebebeb", color: "#595959" }}>
                    <h3 className="text-success">Success</h3>
                    <p>{text}</p>
                    <button className="btn btn-primary ml-auto" onClick={onClose}>OK</button>
                </div>
            );
        }
    });
}


export const notifyError = (text) => {
    confirmAlert({
        title: 'Error',
        message: text,
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui d-flex flex-column' style={{ width: "300px", background: "white", padding: "20px", border: "1px solid #ebebeb", color: "#595959", borderRadius: "10px" }}>
                    <h3 className="text-danger">Error</h3>
                    <p>{text}</p>
                    <button className="btn btn-primary ml-auto" onClick={onClose}>OK</button>
                </div>
            );
        }
    });
}


export const showConfirmAlert = (cb) => {
    confirmAlert({
        // title: 'Error',
        // message: text,
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui d-flex flex-column align-items-start' style={{ width: "300px", background: "white", padding: "20px", border: "1px solid #ebebeb", color: "#4f4f4f", borderRadius: "10px" }}>
                    {/* <h3 className="text-danger">Error</h3> */}
                    <small className="font-weight-bold mb-4" style={{ color: "black", fontSize: "0.85em" }}>{"Are you sure you want to generate invoice"}</small>
                    <div className="d-flex justify-content-between w-100">

                        <button className="btn alertBtn" style={{ color: "#1658e6", background: "#ddd" }} onClick={onClose}>Cancel</button>
                        <button className="btn btn-primary alertBtn" style={{ background: "#1658e6", color: "white" }} onClick={e => {
                            onClose()
                            cb()
                        }}>
                            Yes
                        </button>
                    </div>
                </div>
            );
        }
    });
}

export const showConfirm_cancelAlert = (cb) => {
    confirmAlert({
        // title: 'Error',
        // message: text,
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui d-flex flex-column align-items-start' style={{ width: "300px", background: "white", padding: "20px", border: "1px solid #ebebeb", color: "#4f4f4f", borderRadius: "10px" }}>
                    {/* <h3 className="text-danger">Error</h3> */}
                    <small className="font-weight-bold mb-4" style={{ color: "black", fontSize: "0.85em" }}>{"You are about to cancel the subscription. Are you sure?"}</small>
                    <div className="d-flex justify-content-between w-100">

                        <button className="btn alertBtn" style={{ color: "#1658e6", background: "#ddd" }} onClick={onClose}>Cancel</button>
                        <button className="btn btn-primary alertBtn" style={{ background: "#1658e6", color: "white" }} onClick={e => {
                            onClose()
                            cb()
                        }}>
                            Yes
                        </button>
                    </div>
                </div>
            );
        }
    });
}

export const showConfirmDelete = (cb) => {
    confirmAlert({
        // title: 'Error',
        // message: text,
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui d-flex flex-column align-items-start' style={{ width: "300px", background: "white", padding: "20px", border: "1px solid #ebebeb", color: "#4f4f4f", borderRadius: "10px" }}>
                    {/* <h3 className="text-danger">Error</h3> */}
                    <small className="font-weight-bold mb-4" style={{ color: "black", fontSize: "0.85em" }}>{"Are you sure you want to delete?"}</small>
                    <div className="d-flex justify-content-between w-100">

                        <button className="btn alertBtn" style={{ color: "#1658e6", background: "#ddd" }} onClick={onClose}>Cancel</button>
                        <button className="btn btn-primary alertBtn" style={{ background: "#1658e6", color: "white" }} onClick={e => {
                            onClose()
                            cb()
                        }}>
                            Yes
                        </button>
                    </div>
                </div>
            );
        }
    });
}


export const createCustomerBox = (func_objects) => {
    confirmAlert({
        // title: 'Error',
        // message: text,
        customUI: ({ onClose }) => {
            return (
                <AddCustomer_popup func_objects={func_objects} onClose={onClose} />
            );
        }
    });
}

export const filter_invoice_summary = (from_date, setfrom_date, to_date, setto_date, get_invoice_summary) => {
    confirmAlert({
        // title: 'Error',
        // message: text,
        customUI: ({ onClose }) => {
            return (
                <></>
            );
        }
    });
}