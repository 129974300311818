import React, { useContext, useEffect, useState } from 'react'
import { getDashBoard } from '../services/httpService';
import { notifyError } from '../util/Util';
import { useHistory } from 'react-router-dom';
import ProfileIcon from "../assets/profileIcon.png";
import CollapsedNavbar from './CollapsedSidebar';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from "../assets/sw_logo.png";
import User_Dropdown from './User_Dropdown';
import logoutPic from "../assets/logout_1.png";

const Navbar = ({ heading, height }) => {

    const matches_820 = useMediaQuery('(max-width:820px)');
    const matches_545 = useMediaQuery('(max-width:545px)');

    const history = useHistory();

    // const [data, setData] = useState({});
    const data = localStorage.getItem('vendor_data') ? JSON.parse(localStorage.getItem('vendor_data')) : {};
    const ventor_id = localStorage.getItem("vendor_id");
    useEffect(() => {
        if (!localStorage.getItem('vendor_data')) {
            getDashBoard(ventor_id)
                .then((res) => {
                    if (res["data"]["shop_wizard"]["status"] == "1") {
                        // setData(res["data"]["shop_wizard"]);
                        localStorage.setItem('vendor_data', JSON.stringify(res["data"]["shop_wizard"]))
                        localStorage.setItem("tax", res.data.shop_wizard.tax)
                    }
                }).catch(err => {
                    notifyError("Something went wrong")
                });
        }
        // return () => { };
    }, []);

    const logout = () => {
        if (window.confirm("Do you want to log out?")) {
            localStorage.clear()
            history.push("/")
        }
    }

    return (
        <>
            <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 30 }}>
                <nav className="navbar navbar-expand-lg navbar-light top__navbar">
                    <div className="col-12 p-0 px-2 d-flex align-items-center" style={{ justifyContent: "space-between", position: "relative" }}>
                        {/* <Link to="/">
            <img src={back} alt="" />
          </Link> */}
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {matches_820 && <CollapsedNavbar />}
                            <img src={logo} style={{ height: "40px", background: "#fff" }} />
                            <span>
                                {!matches_820 && <b style={{ color: "#fff", marginLeft: "10px", fontSize: "20px" }}>ShopWizard</b>}
                                {matches_545 && <b style={{ color: "#fff", marginLeft: "10px", fontSize: "20px" }}>{data.name}</b>}<br />
                                {matches_545 && <p className="mb-0 pr-1 d-lg-inline-block title_get" style={{ lineHeight: "15px", marginTop: "-5px", paddingLeft: "12px" }}>
                                <span style={{ fontSize: "11px" }}>Last login: {data.time_ago}</span>
                                </p>}
                            </span>
                        </div>
                        {!matches_545 && <h3 style={{ position: "absolute", right: "43%", top: "3px", color: "#fff" }}>{heading}</h3>}
                        {!matches_545 && <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p className="mb-0 pl-0 pr-1 d-lg-inline-block title_get" style={{ lineHeight: "15px", color: "#fff" }}>
                                <span style={{ fontSize: "16px" }}>welcome</span>&nbsp;<span style={{ fontWeight: "600" }}>{data.name},</span><br />
                                <span style={{ fontSize: "11px" }}>Last login: {data.time_ago}</span>
                            </p>
                            &nbsp;&nbsp;
                            <User_Dropdown data={data} />
                        </div>
                        }
                        {
                            matches_545 &&
                            <img src={logoutPic} style={{ height: "30px" }} onClick={logout} />
                        }
                    </div>
                </nav>
            </div>
            <div style={{ height: "70px" }}></div>
        </>
    )
}

export default Navbar