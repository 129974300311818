import React, { useEffect, useState } from "react";
import Load from '../Loader'
import back from "../../assets/back.png";
import location from "../../assets/location.png";
import googleLink from "../../assets/Link.png";
import addLight from "../../assets/addLight.png";
import add from "../../assets/2997933.png"
import Tax from "../../assets/tax.png";
import "../EditProfile.css";
import "../VendorProfile.css";
import { Link, useHistory } from "react-router-dom";
import { createProfile, getAllStates } from "../../services/httpService";
import { notifyError, notifySuccess } from "../../util/Util";
// import TimePicker from 'react-time-picker';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

// import Loader from "../Loader";
import logo from "../../assets/sw_logo.png";
import shop_pic from "../../assets/shop.jpg"

import { useMediaQuery } from '@mui/material';
import Payment_component from "./Payment_component";
import down_arrow from "../../assets/down_arrow.webp"


function Payment_Page() {
    const matches_820 = useMediaQuery('(max-width:820px)');
    const history = useHistory()
    const [image, setImage] = useState(null)
    const [load, setLoad] = useState(true)
    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState("");
    const [address, setAddress] = useState("");
    const [open, setOpen] = useState("");
    const [close, setClose] = useState("");
    const [url, setUrl] = useState("");
    const [tax, setTax] = useState("");
    const ventor_id = localStorage.getItem("vendor_id");

    useEffect(() => {
        getAllStates().then(res => {
            if (res.data.shop_wizard.status === "1") {
                setLoad(false)
                setStates(res.data.shop_wizard.state_data)
                // console.log(res.data.shop_wizard.state_data);
            }
        })
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (stateId && address && open && close && image) {
            createProfile({ image, id: ventor_id, state: stateId, address, url, open, close, tax }).then((res) => {
                try {
                    if (res["data"]["shop_wizard"]["status"] == "0") {
                        notifyError(res["data"]["shop_wizard"]["msg"])
                    }
                    if (res["data"]["shop_wizard"]["status"] == "1") {
                        history.push("/dashboard")
                    }
                } catch (error) {
                    notifyError("Something went wrong!")
                }
            });
        } else {
            let validate = [
                { name: "Opening time", value: open },
                { name: "Closing time", value: close },
                { name: "Address", value: address },
                { name: "State", value: stateId },
                { name: "Tax", value: tax },
                { name: "Image", value: image }
            ]
            validate.some(v => {
                if (v.value) {
                    return false
                } else {
                    notifyError("Please enter " + v.name)
                    return true
                }
            })
            // notifyError("Please fill all the fields!")
        }
    };

    const [clientSecret, setclientSecret] = useState('');


    const getKeys = async () => {
        setLoad(true)
        const res = await fetch('https://demoapps.in/index.php/Shop_controller/subscribe_user', {
            method: 'POST',
            body: JSON.stringify({
                shop_wizard: {
                    email: localStorage.getItem('vendor_reg_email')
                }
            })
        });
        setLoad(false);
        let string_data = await res.text();
        const parsed_data = JSON.parse(string_data);
        console.log(parsed_data);
        const { shop_wizard } = parsed_data;
        const { client_secret_key, susbscription_id, customer_id } = shop_wizard;
        setclientSecret(client_secret_key);

        localStorage.setItem('subscription_id', susbscription_id)
        localStorage.setItem('customer_id', customer_id)
    }

    useEffect(() => {
        if (localStorage.getItem('vendor_reg_email')) {
            getKeys();
        }
        else {
            history.push('/')
        }
    }, [])

    return (
        <>
            <Load load={load} />
            <section className="singup_section">
                <div style={{ width: "100hw", height: "100vh" }}>
                    <div className="row" >
                        {
                            !matches_820 &&
                            <div className="col-4 p-0" style={{
                                background: "#1c1b3b url(" + shop_pic + ") no-repeat bottom center",
                                // backgroundImage: "url(" + shop_pic + ")",
                                position: "relative",
                                backgroundPosition: "bottom 0px ;",
                                // back
                                height: "100vh",
                                backgroundSize: "460px",
                                backgroundRepeat: "no-repeat",
                                display: "flex", justifyContent: "center", backgroundColor: "#1c1b3b", alignItems: "center"
                            }}>
                            <div style={{ position: "absolute",width:"400px",left:"90px", backgroundColor: "#1c1b3b",bottom:"15px",fontSize:"40px" }}>
                                <span>Service at it's best!</span>
                            </div>
                                <img src={logo} style={{ position: "absolute", top: "90px" }} />
                            </div>
                        }
                        <div className={matches_820 ? "col-12 p-0" : "col-8 p-0"}>
                            <div className="container" style={{ padding: "8% 10% 0px 10%", color: "#000" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/*<img src={left_arrow} style={{ height: "20px", marginTop: "-7px", cursor: "pointer" }}
                                        onClick={() => {
                                            history.push('/');
                                        }}
                                    />
                                    &nbsp;&nbsp;*/}
                                    <h2>Hii {localStorage.getItem("vendor_name")}!</h2>
                                </div>
                                <span>Upgrade to our Premium Monthly Subscription and unlock even more benefits. Don't miss out! </span>
                                <div className="my-3" style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <span>Subscribe Now </span>
                                        <b>just at $59.99/month</b>
                                    </div>
                                    &nbsp;
                                    <img src={down_arrow} style={{ height: "18px" }} />
                                </div>
                                <div className="my-3" style={{ width: "100%" }}>
                                    <div style={{ width: "100%" }}>
                                        {
                                            clientSecret &&
                                            <Payment_component load_2={load} clientSecret={clientSecret} />
                                        }

                                    </div>
                                    {/* {
                                        !clientSecret &&
                                        <div style={{ width: "100%", marginTop: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <button
                                                // onClick={handleSubmit}
                                                // disabled={load}
                                                className="btn payment_cancel_button mt-md-1"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    localStorage.removeItem('subscription_id');
                                                    localStorage.removeItem('customer_id');
                                                    localStorage.removeItem('vendor_name');
                                                    localStorage.removeItem('vendor_id');
                                                    history.push('/')
                                                }}
                                                style={{ maxWidth: "150px", backgroundColor: "red", marginRight: "10px" }}>
                                                Cancel
                                            </button>
                                            <button
                                                // onClick={handleSubmit}
                                                onClick={getKeys}
                                                className="btn login__submit_button mt-md-1" style={{ maxWidth: "350px" }}>
                                                Subscribe Now
                                            </button>
                                        </div>
                                    }*/}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Payment_Page;
