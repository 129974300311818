import React, { useRef } from 'react'
import { Component } from 'react';
// import ReactToPrint from "react-to-print";
import { useReactToPrint } from 'react-to-print';

import Logo from "../assets/sw_logo.png";
import Ncontext from '../ContextC';
import './billPage.css';


class InvoiceBillPage extends Component {
    static contextType = Ncontext;
    render() {
        // let { customerDetailbill, itemDetailbill, vendorDetailbill, InvoiceNumbill } = this.props
        // const context = useContext(Ncontext);
        const { customerDetailbill, setCustomerDetailbill, InvoiceNumbill, setInvoiceNumbill, itemDetailbill, setItemDetailbill, vendorDetailbill, setVendorDetailbill } = this.context;
        return (
            <div className="bill-page" >
                <div className="bill-container d-flex flex-wrap">
                    <div className="col-3">
                        <img src={vendorDetailbill.logo_url} style={{ width: "200px", height: "200px", objectFit: "cover" }} />
                    </div>
                    <div className="col-3 text-left company-details" >
                        <h4>{vendorDetailbill.name}</h4>
                        <h5>{vendorDetailbill.address}</h5>
                        {/* <h5>katy, TX 77449</h5> */}
                        <h5>{vendorDetailbill.contact}</h5>
                        <h5>{vendorDetailbill.email}</h5>
                        {/* <h5>www.excelautostyling.com</h5> */}
                    </div>
                    <div className="invoice col-6 text-right">
                        <h1>Invoice#{InvoiceNumbill}</h1>
                        {/* <h5>Created:8/4/2020</h5> */}
                        {/* <h5>Invoiced:8/4/2020</h5> */}
                        <h5>service Writer: {customerDetailbill.name}</h5>
                    </div>
                </div>
                <div className="d-flex mt-5">
                    <div className="col-6 text-left company-details" >
                        <h4>{customerDetailbill.name}</h4>
                        <h5>Mobile: {customerDetailbill.contact}</h5>
                    </div>
                    <div className="invoice col-6">
                        {/* <h4>2015 DODGE Charger(White)</h4> */}
                        <h5 className="d-flex justify-content-between"><span>VIN:</span><span>{customerDetailbill.vin_number}</span></h5>
                    </div>
                </div>
                {itemDetailbill.map(val => (<div className="tint-table mt-4">
                    <h5 className="table-heading pl-4 py-1" style={{ background: "#ddd" }}>{val.item_name}</h5>
                    <div className="d-flex" style={{ borderBottom: "1px solid black" }}>
                        <div className="col-1"></div>
                        <div className="col-6">
                            <h5>Description</h5>
                        </div>
                        <div className="col-2">
                            <h5 className="price">Price</h5>
                        </div>
                        <div className="col-1">
                            <h5>Tax</h5>
                        </div>
                        <div className="col-2">
                            <h5>Sub Total</h5>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-1">
                            <h5>1</h5>
                        </div>
                        <div className="col-6">
                            <h5>{val.item_name}</h5>
                            {/* <h5>Note: LIFE TIME WARRANTY</h5>
                            <h5>NOT STREET LEGAL</h5> */}
                        </div>
                        <div className="col-2">
                            <h5 className="price">{val.amount}</h5>
                        </div>
                        <div className="col-1">
                            <h5>{val.tax}</h5>
                        </div>
                        <div className="col-2">
                            <h5>{val.amount + val.tax}</h5>
                        </div>
                    </div>
                    <h5 className="tab-foot pl-4 py-1" style={{ background: "#ddd" }}>
                        Total: {val.amount + val.tax}
                    </h5>
                </div>
                ))}
                {/* <div className="tint-table mt-5">
                    <h5 className="table-heading pl-4 py-1" style={{ background: "#ddd" }}>Window Tint CS 5%</h5>
                    <div className="d-flex" style={{ borderBottom: "1px solid black" }}>
                        <div className="col-1"></div>
                        <div className="col-6">
                            <h5>Description</h5>
                        </div>
                        <div className="col-2">
                            <h5 className="price">Price</h5>
                        </div>
                        <div className="col-1">
                            <h5>QTY</h5>
                        </div>
                        <div className="col-2">
                            <h5>Sub Total</h5>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-1">
                            <h5>1</h5>
                        </div>
                        <div className="col-6">
                            <h5>CS 5% ALL AROUND</h5>
                        </div>
                        <div className="col-2">
                            <h5 className="price">$175.00</h5>
                        </div>
                        <div className="col-1">
                            <h5>1</h5>
                        </div>
                        <div className="col-2">
                            <h5>$175.00</h5>
                        </div>
                    </div>
                    <h5 className="tab-foot pl-4 py-1" style={{ background: "#ddd" }}>
                        Total: $175.00
                        </h5>
                </div>
                 */}
                <div className="d-flex mt-5">
                    <div className="col-7">
                        <p>Thank you for your business. Your Payment is due upon vehicle pickup</p>
                        <div className="d-flex align-items-end">
                            <h5 className="col-3">Signature</h5>
                            <div className="col-9" style={{ height: "1px", background: "black" }} />
                        </div>
                    </div>
                    <div className="col-5">
                        <div style={{ border: "1px solid rgba(0,0,0,.2)" }}>
                            <h5 className="p-3">
                                {/* <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Parts</span>
                                    <span>{itemDetailbill.map(v=>(v.amount+val.tax)).reduce((total,a)=>(parseInt(total)+parseInt(a)))}</span>
                                </div> */}
                                {/* <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Labour</span>
                                    <span>$0.00</span>
                                </div> */}
                                <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Sub total</span>
                                    <span>{itemDetailbill.length ? itemDetailbill.map(v => (v.amount)).reduce((total, a) => (parseInt(total) + parseInt(a))) : 0}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Tax</span>
                                    <span>{itemDetailbill.length ? itemDetailbill.map(v => (v.tax)).reduce((total, a) => (parseInt(total) + parseInt(a))) : 0}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Grand Total</span>
                                    <span>{itemDetailbill.length ? itemDetailbill.map(v => (parseInt(v.amount) + parseInt(v.tax))).reduce((total, a) => (parseInt(total) + parseInt(a))) : 0}</span>
                                </div>
                                {/* <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Paid To Date</span>
                                    <span>($210.00)</span>
                                </div> */}
                            </h5>
                            <div className="d-flex justify-content-between px-3 py-1" style={{ background: "#ddd" }}>
                                <h6 className="font-weight-bold mb-0">Total Amount</h6>
                                <h6 className="font-weight-bold mb-0">{itemDetailbill.length ? itemDetailbill.map(v => (parseInt(v.amount) + parseInt(v.tax))).reduce((total, a) => (parseInt(total) + parseInt(a))) : 0}</h6>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            // </section>
        )
    }
}

// const Example = () => {
//     const componentRef = useRef();
//     const handlePrint = useReactToPrint({
//         content: () => componentRef.current,
//       });
//     return (
//         <div>
//             <div className=""><BillPage ref={componentRef} /></div>
//             <button onClick={handlePrint} style={{zIndex:100,position:"absolute",bottom:"0",left:"0"}}>Print this out!</button>
//         </div>
//     );
// };

export default InvoiceBillPage
