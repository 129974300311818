import React from 'react'

export default (props) => {
    const [show, setshow] = React.useState(false)
    return (
        <p className="message__content__p" style={{paddingRight:"10px"}}>
            {show?props.desc:props.desc.substr(0, 115)}

            {(props.desc.length > 115) ? <a href="#." onClick={e => {
                e.preventDefault()
                setshow(!show)
            }}> view {!show ? "more" : "less"}</a> : ""}
        </p>
    )
}