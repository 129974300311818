import React, { useEffect } from "react";
import linkIcon from "../assets/Link.png";
import nameIcon from "../assets/name.png";
import emailIcon from "../assets/email.png";
import phoneIcon from "../assets/phone.png";
import locationIcon from "../assets/loc2.png";
import userProfile from "../assets/profilePage.png";
import Tax from "../assets/tax.png";
import back from "../assets/back.png";
import "./EditProfile.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { signUp, updateProfile } from "../services/httpService";
import { notifyError, notifySuccess, removeSpacesAndAlphabets } from "../util/Util";
import { getProfile, getAllStates, getDashBoard } from "../services/httpService";
import Loader from "./Loader";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";

function EditProfile() {
    const matches_820 = useMediaQuery('(max-width:820px)');
    const history = useHistory();
    const [profileData, setProfileData] = useState({});
    const [states, setStates] = useState([]);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [image, setImage] = useState(null)
    const [load, setLoad] = useState(false)
    const [stateId, setStateId] = useState("");
    const [address, setAddress] = useState("");
    const [tax, setTax] = useState(localStorage.getItem("tax"));
    const [url, setUrl] = useState("");
    const ventor_id = localStorage.getItem("vendor_id");
    useEffect(() => {
        setLoad(true)
    setTax(localStorage.getItem("tax"));
    getProfile(ventor_id)
            .then((res) => {
                if (res["data"]["shop_wizard"]["status"] === "1") {
                    setLoad(false)
                    setProfileData(res["data"]["shop_wizard"]);
                }
            });
        getAllStates().then(res => {
            if (res.data.shop_wizard.status === "1") {
                setStates(res.data.shop_wizard.state_data)
            }
        })
        getDashBoard(ventor_id)
            .then((res) => {
                if (res["data"]["shop_wizard"]["status"] === "1") {
                    //   setLoad(false)
                    //   setData(res["data"]["shop_wizard"]);
                    // localStorage.setItem("tax", res.data.shop_wizard.tax)
                }
            }).catch(err => {
                // notifyError("Something went wrong")
            });
    }, []);

    // useEffect(() => {
    // }, [])

    useEffect(() => {
        if (profileData.email) {
            setUsername(profileData.name)
            setEmail(profileData.email)
            setPhone(profileData.contact)
            setStateId(profileData.state_id)
            setAddress(profileData.address)
            setUrl(profileData.google_review_url)
        }
    }, [profileData])
    console.log(states)
    const handleSubmit = (event) => {
        event.preventDefault();
        if (username && stateId && address && tax) {
            updateProfile({ image, name: username, id: ventor_id, state: stateId, address, url, tax }).then((res) => {
                // try {
                //     if (res["data"]["shop_wizard"]["status"] == "0") {
                //         notifyError(res["data"]["shop_wizard"]["msg"])
                //     }
                //     if (res["data"]["shop_wizard"]["status"] == "1") {
                //         history.push("/profile")
                //         localStorage.setItem("tax", tax)
                //     }
                // } catch (error) {
                //     notifyError("Something went wrong!")
                // }
                history.push("/profile")
                localStorage.setItem("tax", tax)
            });
        } else {
            let validate = [
                { name: "Username", value: username },
                { name: "Address", value: address },
                { name: "State", value: stateId },
                { name: "Tax", value: tax }
                // { name: "Google review url", value: url }
            ]
            validate.some(v => {
                if (v.value) {
                    return false
                } else {
                    notifyError("Please enter " + v.name)
                    return true
                }
            })
            // notifyError("Please fill all the fields!")
        }
    };

    return (
        <>
            <Loader load={load} />
            {/*<section className="singup_section" style={{backgroundColor:"#fff"}}>*/}
            <Navbar heading='Edit Profile' />
            <div style={{ display: "flex" }}>
                {
                    !matches_820 &&
                    <div style={{ width: "19%" }}>
                        <SideBar />
                    </div>
                }
                <div style={{ width: matches_820 ? "100%" : "80%" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="card signup_card">
                                    <div className="card-body">
                                        <div className="container mt-0">
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-center align-items-center editImage">
                                                    <div className="roundImage">
                                                        <img className="imageSet" src={(profileData.logo_url || image) ? (image ? URL.createObjectURL(image) : profileData.logo_url) : userProfile} alt="" />
                                                    </div>
                                                    <input type="file" onChange={e => setImage(e.target.files[0])} />
                                                </div>

                                                <div className="col-12 d-flex justify-content-center align-items-center">
                                                    <p className="font-weight-bold profile__content__para">
                                                        Change profile
                                                    </p>
                                                </div>
                                                <div className="col-12">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group m-1">
                                                            <label htmlFor="exampleInputEmail1" className="ml-3">
                                                                Vender’s Name
                                                            </label>
                                                            <div className="input-group mb-1">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text login__span"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <img
                                                                            src={nameIcon}
                                                                            alt=""
                                                                            className="img-fluid login__img"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control login__input"
                                                                    placeholder="Username"
                                                                    aria-label="Username"
                                                                    aria-describedby="basic-addon1"
                                                                    name="username"
                                                                    value={username}
                                                                    onChange={(event) =>
                                                                        setUsername(event.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group m-1">
                                                            <label htmlFor="exampleInputEmail1" className="ml-3">
                                                                Email
                                                            </label>
                                                            <div className="input-group mb-1">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text login__span"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <img
                                                                            src={emailIcon}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                            style={{ height: "13px" }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="email"
                                                                    className="form-control login__input"
                                                                    placeholder="Email"
                                                                    disabled
                                                                    aria-label="Email"
                                                                    aria-describedby="basic-addon1"
                                                                    name="email"
                                                                    value={email}
                                                                    onChange={(event) => setEmail(event.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group m-1">
                                                            <label htmlFor="exampleInputEmail1" className="ml-3">
                                                                Phone
                                                            </label>
                                                            <div className="input-group mb-1">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text login__span"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <img
                                                                            src={phoneIcon}
                                                                            alt=""
                                                                            className="img-fluid login__img"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control login__input"
                                                                    placeholder="Phone"
                                                                    disabled
                                                                    aria-label="Phone"
                                                                    aria-describedby="basic-addon1"
                                                                    name="phone"
                                                                    value={phone}
                                                                    onChange={(event) => setPhone(event.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group m-1">
                                                            <label htmlFor="" className="ml-3">
                                                                State
                                                            </label>
                                                            <div className="input-group mb-1">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text login__span"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <img
                                                                            src={locationIcon}
                                                                            style={{ opacity: 0.7 }}
                                                                            alt=""
                                                                            className="img-fluid login__img"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <select className="form-control login__input" onChange={e => {
                                                                    setTax(e.target.value.split("/")[1]);
                                                                    setStateId(e.target.value.split("/")[0])
                                                                }}>
                                                                    <option disabled>Select state</option>
                                                                    {states.map(val => (
                                                                        <option value={val.state_id + "/" + val.state_tax} selected={stateId === val.state_id}>{val.state_name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group m-1">
                                                            <label htmlFor="exampleInputEmail1" className="ml-3">
                                                                Address
                                                            </label>
                                                            <div className="input-group mb-1">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text login__span"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <img
                                                                            src={locationIcon}
                                                                            style={{ opacity: 0.7 }}
                                                                            alt=""
                                                                            className="img-fluid login__img"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control login__input"
                                                                    placeholder="Address"
                                                                    aria-label="Address"
                                                                    aria-describedby="basic-addon1"
                                                                    name="address"
                                                                    value={address}
                                                                    onChange={(event) =>
                                                                        setAddress(event.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group m-1">
                                                            <label htmlFor="exampleInputEmail1" className="ml-3">
                                                                Tax
                                                            </label>
                                                            <div className="input-group mb-1">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text login__span"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <img
                                                                            src={Tax}
                                                                            style={{ opacity: 0.7 }}
                                                                            alt=""
                                                                            className="img-fluid login__img"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    // disabled
                                                                    className="form-control login__input"
                                                                    placeholder="Tax"
                                                                    aria-label="Tax"
                                                                    aria-describedby="basic-addon1"
                                                                    name="tax"
                                                                    value={tax}
                                                                    onChange={(event) =>
                                                                        setTax(removeSpacesAndAlphabets(event.target.value))
                                                                    }
                                                                />
                                                                <div className="input-group-append" style={{marginTop:"-10px"}}>
                                                                    <span
                                                                        className="input-group-text login__span"
                                                                        id="basic-addon1"
                                                                    >
                                                                        %
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="form-group m-1">
                                                            <label htmlFor="" className="ml-3">
                                                                Google review URL
                                                            </label>
                                                            <div className="input-group mb-1">
                                                                <div className="input-group-prepend" >
                                                                    <span
                                                                        className="input-group-text login__span"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <img
                                                                            src={linkIcon}
                                                                            style={{ opacity: 0.7 }}
                                                                            alt=""
                                                                            className="img-fluid login__img"
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control login__input"
                                                                    placeholder="Google review"
                                                                    aria-label="Google review"
                                                                    aria-describedby="basic-addon1"
                                                                    name="reviewUrl"
                                                                    value={url}
                                                                    onChange={(event) =>
                                                                        setUrl(event.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row mt-2">
                                                            <div className="col-6 mx-auto">
                                                                <button className="btn login__submit_button mt-md-3"
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </section >*/}
        </>
    );
}

export default EditProfile;
