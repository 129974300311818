import React from 'react'

const DialogBox = (props) => {

    // console.log({ props });
    const { isOpen, setIsOpen, width, height,buttonDisplay } = props;
    const closeDialog = () => {
        setIsOpen(false);
    }

    return (
        isOpen &&
        <div className="dialog">
            <div className="dialog-content" >
                <span onClick={closeDialog} style={{ color: "#000",fontSize:"24px",fontWeight:"600" }} className="close-button">
                    &times;
                </span>
                <div >
                    {props.children}
                    {
                        buttonDisplay != 'none' &&
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <button
                                onClick={closeDialog}
                                style={{ backgroundColor: "red", color: "#fff", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                                Close
                            </button>
                            &nbsp;
                            &nbsp;
                            <button style={{ backgroundColor: "blue", color: "#fff", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                                Submit
                            </button>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default DialogBox