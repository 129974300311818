import React, { useEffect, useState } from "react";
import "./Estimate.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import id from "../assets/id.png";
import name from "../assets/name.png";
import password from "../assets/password.png";
import wrong from "../assets/wrong.png";
import { getAllInvoice, getInvoiceDetail, updateInvoice, saveEstimate, generateInvoice } from "../services/httpService";
import { notifyError, notifySuccess, showConfirmAlert } from "../util/Util";
import Loader from "./Loader";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";


function Estimate(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const history = useHistory()
  const [items, setItems] = useState([])
  const [customerId, setCustomer] = useState("")
  const [itemsUI, setItemsUI] = useState(null)
  const [load, setLoad] = useState(false)
  const [total, setTotal] = useState(0)
  const [counter, setCounter] = useState(0)
  const [vin, setVin] = useState("")
  const [reg, setReg] = useState("")
  const ventor_id = localStorage.getItem("vendor_id");
  const [name, setname] = useState('');
  const [total_amt, settotal_amt] = useState(0);
  const [total_tax, settotal_tax] = useState(0);
  const [bool, setbool] = useState(false);

  document.onkeydown = function (e) {
    console.log(e.code)
    if (e.code == 'Quote' || e.code == 'Minus' || e.code == "NumpadSubtract" || e.code == 'NumpadAdd' || e.code == 'Equal') {
      return false
    }

  }
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("estimateEdit"))
    console.log(data);
    setname(data.name);
    // console.log(JSON.parse(localStorage.getItem('addEstimateData')));
    setVin(data.vin)
    setItemsUI(getUI())
    setReg(data.reg)
    settotal_amt(data.total);
    setTotal(data.total)
    setCustomer(data.customer)
    setLoad(true)
    getInvoiceDetail(props.match.params.id)
      .then((res) => {
        if (res["data"]["shop_wizard"]["status"] === "1") {
          let tax = localStorage.getItem("tax") ? localStorage.getItem("tax") : 10
          setLoad(false)
          setItems(res["data"]["shop_wizard"]["items"].map(v => ({ ...v, tax: v.tax ? v.tax : v.price * (parseInt(tax) / 100) })))
          console.log(res.data.shop_wizard);
          settotal_tax(res["data"]["shop_wizard"]["items"].map(v => v.tax ? v.tax : 0).reduce((t, v) => (parseFloat(t) + parseFloat(v))))
        }
      });
  }, []);

  useEffect(() => {
    setItemsUI(getUI())
  }, [counter, items.length]);

  const Validate = () => {
    let validate = [
      { name: "Customer", value: customerId }
    ]
    items.map(v => {
      validate.push({
        name: "Issue name", value: v.item_name
      })
      validate.push({
        name: "amount", value: v.price
      })
    })
    validate.some(v => {
      if (v.value) {
        return false
      }
      else {
        notifyError("Please enter " + v.name)
        return true
      }
    })
  }

  let handleSubmit = () => {
    if (customerId && items.filter(v => !v.item_name || !v.price).length === 0) {
      setLoad(true)
      generateInvoice({
        customer_id: customerId, total_amount: total, vin_number: vin, reg_number: reg, particulars: items, vendor_id: ventor_id, appointment_id: "0", invoice_id: "0"
      }).then(res => {
        setLoad(false)
        if (res.data.shop_wizard.status === "1") {
          // history.push("/estimate-list")
          notifySuccess("Invoice generated successfully")
          localStorage.removeItem("addEstimateData")
          history.push("/invoice")
        } else {
          notifyError(res.data.shop_wizard.msg)
        }
      })
    } else {
      let validate = [
        { name: "Customer", value: customerId }
      ]
      items.map(v => {
        validate.push({
          name: "Issue name", value: v.item_name
        })
        validate.push({
          name: "Enter amount", value: v.price
        })
      })
      validate.some(v => {
        if (v.value) {
          return false
        } else {
          notifyError("Please enter " + v.name)
          return true
        }
      })
    }
  }


  let save = () => {
    if (customerId) {
      // console.log(items);
      setLoad(true)
      updateInvoice({
        customer_id: customerId, total_amount: bool == false ? total_amt : (total + total * parseFloat((localStorage.getItem("tax") / 100))).toFixed(2), vin_number: vin, reg_number: reg, particulars: items.filter((el) => {
          return (el.price > 0) && (el.item_name != '');
        }), vendor_id: ventor_id, appointment_id: "0", invoice_id: props.match.params.id
      }).then(res => {
        setLoad(false)
        if (res.data.shop_wizard.status === "1") {
          notifySuccess("Invoice updated successfully")
          history.push("/estimate-list")
        } else {
          notifyError(res.data.shop_wizard.msg)
        }
      })

    } else {
      const defaulter_items = items.filter((el) => {
        return (el.item_name && !el.price) || (!el.item_name && el.price)
      })
      if (defaulter_items.length > 0) {
        notifyError("Please enter both fields")
      }
      else {
        let validate = [
          { name: "Customer", value: customerId }
        ]
        // items.map(v => {
        //   validate.push({
        //     name: "Issue name", value: v.item_name
        //   })
        //   validate.push({
        //     name: "Enter amount", value: v.price
        //   })
        // })
        validate.some(v => {
          if (v.value) {
            return false
          } else {
            notifyError("Please enter " + v.name)
            return true
          }
        })
      }

    }
  }

  let getUI = () => {
    return (
      <>
        {items.map((val, i) => (<div className="row">
          <div className="col-2 p-0 text-center d-flex justify-content-center align-items-center">
            <span className="font-weight-bolder text-dark">{i + 1}.</span>
          </div>
          <div className="col-4 p-0">
            <input
              type="text"
              placeholder="Enter services here.."

              className="estimate__input"
              value={val.item_name}
              onChange={e => {
                setItems(data => {
                  data[i] = {
                    ...data[i],
                    item_name: e.target.value
                  }
                  return data
                })
                setCounter(c => c + 1)
              }
              }
            />
          </div>
          <div className="col-4 p-0">
            <input
              type="number"
              placeholder="Amount"
              className="estimate__input"
              value={val.price ? val.price : null}
              min="0"
              onPaste={(e) => {
                e.preventDefault()
                return false;
              }
              }
              onChange={e => setItems(data => {
                let value = e.target.value
                setbool(true);
                data[i] = {
                  ...data[i],
                  price: Math.abs(value),
                  tax: parseFloat(value * localStorage.getItem("tax") / 100).toFixed(2)
                }
                setTotal(items.map(v => v.price ? v.price : 0).reduce((t, v) => (parseFloat(t) + parseFloat(v))))
                setCounter(c => c + 1)
                return data
              })}
            />
          </div>
          <div className="col-2 p-0  d-flex justify-content-center align-items-center">
            <img
              src={wrong}
              alt=""
              className="img-fluid"
              style={{ display: items.length > 1 ? "block" : "none", height: "20px", cursor: "pointer" }}
              onClick={e => {

                setItems(items.filter((item, index) => {
                  if (index == i) {
                    // console.log(item);
                    return;
                  }
                  else {
                    return item
                  }
                }))

                setTotal(
                  items.filter((item, index) => {
                    if (index == i) {
                      // console.log(item);
                      return;
                    }
                    else {
                      return item
                    }
                  })
                    .map((v) => parseInt(v.price ? v.price : 0))
                    .reduce((t, v) => (parseFloat(t) + parseFloat(v)))
                );
                setCounter(c => c + 1)

              }}
            />
          </div>
        </div>
        ))}
      </>
    )
  }


  return (
    <>
      {
        items.length > 0 ?
          <>
            <Loader load={load} />
            <section className="estimate__section">
              <Navbar heading='Edit Estimate' />
              <div style={{ display: "flex" }}>
                {
                  !matches_820 &&
                  <div style={{ width: "19%" }}>
                    <SideBar />
                  </div>
                }
                <div style={{ width: matches_820 ? "100%" : "81%", position: "relative", height: "91vh" }}>
                  <div className="estimate__content container">
                    <div className="row mt-3">
                      <div className="col-2 offset-1 d-flex justify-content-center align-items-center estimate__col">
                        <img
                          src={id}
                          alt=""
                          className="img-fluid"
                          style={{ height: "20px" }}
                        />
                      </div>
                      <div className="col-7 pl-0 estimate__col">
                        <p className="estimate__light" style={{ marginTop: "15px" }}>{name + " (" + customerId + ")"}</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-2 offset-1 d-flex justify-content-center estimate__col">
                        <img
                          src={password}
                          alt=""
                          className="img-fluid"
                          style={{ height: "20px" }}
                        />
                      </div>
                      <div className="col-7 pl-0 estimate__col">
                        <p className="estimate__light">VIN No./Reg No. {vin}</p>
                        {/* <p className="estimate__light">Car Ragistration Number: {reg}</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="container estimate__bottom__card mt-2">
                    <div className="row">
                      <div className="col-12">
                        <div className="card estimate__card">
                          <div className="card-body">
                            <p
                              className="font-weight-bold text-dark  d-flex justify-content-between"
                              style={{ fontSize: "12px" }}
                            >
                              SERVICE WITH CARS
                              <i class="fa fa-plus-circle fa-2x mr-3" style={{ color: "#1050e6", cursor: "pointer" }} aria-hidden="true" onClick={e => {
                                if (items[items.length - 1].price != '' || items[items.length - 1].item_name != '') {
                                  setItems(data => ([...data, {
                                    item_name: "",
                                    price: "",
                                    tax: ""
                                  }]))
                                }
                              }
                              }></i>
                            </p>
                            {itemsUI}
                            {/* <div className="row mt-5 mb-5 text-secondary">
                    <div className="col-6 d-flex justify-content-center">
                      <small>Service:{items.length}</small>
                    </div>

                    <div className="col-6 pl-5">
                      <small>Amount:{total}</small>
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                      <button className="btn estimate__addIssue" >Add Issue</button>
                    </div>
                    <div className="col-6">
                      <button className="btn estimate__save" onClick={handleSubmit}>Save</button>
                    </div>
                  </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="generate-invoice_container">
                    <div
                    style={{fontWeight:"600",borderTop:"2px solid #c9c9c9"}}
                    className="generate-invoice-data d-flex justify-content-between px-3 py-1">
                      <p className="data">SERVICE:{items.length}</p>
                      <p className="data">AMOUNT:{bool == false ? total_amt : (total + total * parseFloat((localStorage.getItem("tax") / 100))).toFixed(2)}</p>
                      <p className="data">TAX:{bool == false ? parseFloat(total_tax).toFixed(2) : (total * parseFloat((localStorage.getItem("tax")) / 100)).toFixed(2)}</p>
                    </div>
                    <button className="generate-invoice-btn text-center" disabled={items[0].item_name == '' || items[0].price == '' ? true : false} onClick={e => {
                      customerId && items.filter((el) => {
                        return (el.item_name && !el.price) || (!el.item_name && el.price)
                      }).length == 0 ?
                        showConfirmAlert(save) :
                        Validate()
                    }}>Generate Invoice</button>
                  </div>
                </div>
              </div>
            </section>
          </>
          : ""}
    </>
  );

}

export default Estimate;
