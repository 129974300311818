import { Menu, MenuItem } from '@mui/material';
import ProfileIcon from "../assets/profileIcon.png";
import React from 'react'
import { useHistory } from 'react-router-dom';

const User_Dropdown = ({ data }) => {
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const go_to_profile = () => {
        history.push("/profile");
        handleClose();
    }

    const logout = () => {
        if (window.confirm("Do you want to log out?")) {
            localStorage.clear()
            history.push("/")
        }
    }

    return (
        <>
            <img
                src={data.logo_url ? data.logo_url : ProfileIcon}
                alt=""
                className="bottomImage"
                style={{ cursor: "pointer" }}
                onClick={handleClick}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={go_to_profile}>Profile</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </>
    )
}

export default User_Dropdown;