import React from 'react'
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({
    component: Component,
    ...rest
}) {
    let mode = null;
    if (localStorage.getItem("vendor_id")) {
        mode = true;
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                mode ? (
                    ((rest.path !== "/") && (rest.path !== "/Signup") && (rest.path !== "/vendor-profile")) ?
                        <Component {...rest} {...props} key={props.key} /> :
                        <Redirect
                            to={{
                                pathname: "/dashboard",
                                state: { from: props.location },
                            }}
                        />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location },
                            }}
                        />
                    )
            }
        />
    )
}