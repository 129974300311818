import React, { useContext } from "react";
import "./Add.css";
import humburger from "../assets/humburger.png";
import customer from "../assets/customer.png";
import car from "../assets/CAR.png";
import est from "../assets/est.png";
import invoice from "../assets/invoice.png";
import app from "../assets/appoint.png";
import msg from "../assets/message.png";
import profileIcon from "../assets/profileIcon.png";
import home from "../assets/home.png";
import add from "../assets/add.png";
import { Link } from "react-router-dom";
import back from "../assets/back.png";
import Ncontext from "../ContextC";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";

function Add() {
  const context = useContext(Ncontext);
  const matches_820 = useMediaQuery('(max-width:820px)');

  return (
    <section className="add__section">
      {/*<nav className="navbar navbar-expand-lg navbar-light top__navbar">
        <div className="col-6 p-0 d-flex align-items-center">
          <Link to="/dashboard"
            onClick={
              (e) => {
                // e.preventDefault()
                // window.history.back()
              }
            }
          >
            <img src={back} alt="" />
          </Link>
          <p className="mb-0 pl-3 pr-3 d-lg-inline-block title_get font-weight-bold">
            Add
          </p>
        </div>
      </nav>*/}
      <Navbar heading='Add' />
      <div style={{ display: "flex" }}>
        {
          !matches_820 &&
          <div style={{ width: "19%" }}>
            <SideBar />
          </div>
        }
        <div style={{ width: matches_820 ? "100%" : "80%" }}>
          <div className="add__content container-fluid">
            <div className="row">
              <div className="col-12 mt-4">
                <Link to="/add-customer">
                  <div className="card add__content__card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-2 d-flex justify-content-start align-items-center">
                          <img src={customer} alt="" className="img-fluid" />
                        </div>
                        <div className="col-8 justify-content-start align-items-center">
                          <p className="mb-1 mt-2 font-weight-bold">Customer</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 mt-3">
                <Link to="/new-estimate" onClick={() => {

                }}>
                  <div className="card add__content__card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-2 d-flex justify-content-start align-items-center">
                          <img src={est} alt="" className="img-fluid" />
                        </div>
                        <div className="col-8 justify-content-start align-items-center">
                          <p className="mb-1 mt-2 font-weight-bold">Estimate</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 mt-3">
                <Link to="/add-appointment">
                  <div className="card add__content__card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-2 d-flex justify-content-start align-items-center">
                          <img src={app} alt="" className="img-fluid" />
                        </div>
                        <div className="col-8 justify-content-start align-items-center">
                          <p className="mb-1 mt-2 font-weight-bold">Appointments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 mt-3">
                <Link to="/add-template">
                  <div className="card add__content__card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-2 d-flex justify-content-start align-items-center">
                          <img src={msg} alt="" className="img-fluid" />
                        </div>
                        <div className="col-8 justify-content-start align-items-center">
                          <p className="mb-1 mt-2 font-weight-bold">Marketing</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-inverse fixed-bottom navbar-light bg-light">
            <div className="navbar-collapse">
              <ul className="navbar-nav mr-lg-4 d-flex align-items-center justify-content-around">
                <li className="nav-item">
                  <Link to="/dashboard">
                    <img
                      src={home}
                      alt=""
                      className="img-fluid"
                      style={{ height: "25px" }}
                    />
                  </Link>
                </li>
                <li className="nav-item ml-3" style={{ height: "50px" }}>
                  {/* <Link to="/add">
                <img
                  src={add}
                  alt=""
                  className="img-fluid dashboard_add_img"
                  style={{ height: "60px" }}
                />
              </Link> */}
                </li>
                <li className="nav-item">
                  <Link to="/profile">
                    <img
                      src={profileIcon}
                      alt=""
                      className="img-fluid"
                      style={{ height: "25px" }}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default Add;
