export const sendSMS = async (to, message) => {
    // Twilio Account SID and Auth Token
    const accountSid = 'ACb7e0553221616beff3e9d2e679a74c74';
    const authToken = '98fad089c0f164b873c65ad557d6746f'; // Replace with your actual Auth Token
    const from = '+18555124865';

    // Twilio endpoint URL
    const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

    // Twilio request data
    const data = new URLSearchParams({
        To: to,
        From: from,
        Body: message,
    });

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: data,
        });

        if (!response.ok) {
            throw new Error(`Twilio API request failed with status ${response.status}`);
        }

        const responseData = await response.json();
        console.log(responseData);
        return responseData;
    } catch (error) {
        console.error('Error sending SMS:', error);
        return { error: error.message };
    }
}