import React, { useContext, useEffect, useState } from 'react'
import back from "../assets/back.png";
import email from "../assets/email.png";
import down from "../assets/drop_g.png";
import { Link, useHistory } from "react-router-dom";
import { editMessage, getAllMessages, sendEmail } from "../services/httpService";
import "./Send.css"
import SearchCustomer from './SearchCustomer';
import { notifyError, notifySuccess } from '../util/Util';
import Ncontext from '../ContextC';
import Csv from "../assets/csv.png";
import Load from './Loader';
import SideBar from './Sidebar';
import Navbar from './Navbar';
import { useMediaQuery } from '@mui/material';
import { sendSMS } from '../utilities/Send_sms';

function Send(props) {
    const matches_820 = useMediaQuery('(max-width:820px)');
    const context = useContext(Ncontext);
    const { templateTypeNum, settemplateTypeNum } = context;
    const [load, setLoad] = useState(false);
    const [message, setMessage] = useState([]);
    const [messageDetail, setMessageDetail] = useState(null);
    const [searchCustomer, setSearchCustomer] = useState(false);
    const ventor_id = localStorage.getItem("vendor_id");
    const [emails, setEmails] = useState([])
    const [body, setBody] = useState("")
    const [subject, setSubject] = useState("")
    const history = useHistory();
    const [title, settitle] = useState('')

    useEffect(() => {
        // alert(templateTypeNum);
        // setLoad(true)
        getAllMessages(ventor_id)
            .then((res) => {
                if (res["data"]["shop_wizard"]["status"] === "1") {
                    setLoad(false)
                    if (!res["data"]["shop_wizard"].hasOwnProperty('sms_template')) {
                        setMessage(res["data"]["shop_wizard"]["email_template"].filter((item) => {
                            if (item == undefined) {
                                return;
                            } else {
                                return item;
                            }
                        }));
                        res["data"]["shop_wizard"]["email_template"].filter((item) => {
                            if (item == undefined) {
                                return;
                            } else {
                                return item;
                            }
                        }).map(val => {
                            if (props.match.params.id === val.template_id) {
                                setMessageDetail(val)
                                setBody(val.template_desc)
                                settitle(val.subject);
                            }
                        })
                    } else if (!res["data"]["shop_wizard"].hasOwnProperty('email_template')) {
                        setMessage(res.data.shop_wizard.sms_template.filter((item) => {
                            if (item == undefined) {
                                return;
                            } else {
                                return item;
                            }
                        }));
                        res.data.shop_wizard.sms_template.filter((item) => {
                            if (item == undefined) {
                                return;
                            } else {
                                return item;
                            }
                        }).map(val => {
                            if (props.match.params.id === val.template_id) {
                                setMessageDetail(val)
                                setBody(val.template_desc)
                                settitle(val.subject);
                            }
                        })
                    }
                    else {
                        setMessage(res["data"]["shop_wizard"]["email_template"].concat(res.data.shop_wizard.sms_template).filter((item) => {
                            if (item == undefined) {
                                return;
                            } else {
                                return item;
                            }
                        }));
                        res["data"]["shop_wizard"]["email_template"].concat(res.data.shop_wizard.sms_template).filter((item) => {
                            if (item == undefined) {
                                return;
                            } else {
                                return item;
                            }
                        }).map(val => {
                            if (props.match.params.id === val.template_id) {
                                setMessageDetail(val)
                                setBody(val.template_desc)
                                settitle(val.subject);
                            }
                        })
                    }
                }
            });
        return () => { };
    }, []);

    console.log(messageDetail);


    const sendMessages = (messages, subject, message) => {
        // console.log(emails, subject, message);
        const recipients = messages.map(msg => {
            return '+1' + msg

        })
        let n = 1;
        recipients.map((el) => {
            sendSMS(el, subject + "-" + message).then(() => {
                if (n == recipients.length) {
                    setLoad(false);
                    notifySuccess('Message sent successfully');
                    history.push("/dashboard")
                }
                else {
                    n = parseInt(n) + 1;
                }
            }).catch((err) => {
                console.log(err);
                if (n == recipients.length) {
                    setLoad(false);
                    notifyError('Some error occured!');
                }
                else {
                    n = parseInt(n) + 1;
                }
            })
        })
        // console.log({
        //     shop_wizard: {
        //         recipients, subject, message
        //     }
        // });
        // return axios
        //     .post(
        //         BASE_URL + "Shop_controller/send_email",
        //         {
        //             shop_wizard: {
        //                 recipients, subject, message
        //             },

        //         }
        //     )
    }

    return (
        <section className="addAppointment__section" style={{ background: "#fff" }}>

            <Load load={load} />
            {
                !searchCustomer ?
                    <>
                        <Navbar heading={messageDetail && messageDetail.template_type == "2" ? "Send Email" : "Send"} />
                        <div style={{ display: "flex" }}>
                            {
                                !matches_820 &&
                                <div style={{ width: "19%" }}>
                                    <SideBar />
                                </div>
                            }
                            <div style={{ width: matches_820 ? "100%" : "80.9%" }}>
                                <div>
                                    {/* <div className="all-emails pl-3 mt-2 mx-3 d-flex align-items-center justify-content-between cursor-pointer" onClick={
                            () => {
                                setSearchCustomer(true)
                            }
                        } style={{ position: "relative", borderRadius: templateTypeNum=="1"?"8px 0px 0px 8px":"8px", width: templateTypeNum == "1" ? "75%" : "97%", padding: "10px 0px", border: "2px solid #ddd" }}>*/}
                                    <div className="all-emails pl-3 mt-2 mx-3 d-flex align-items-center justify-content-between cursor-pointer" onClick={
                                        () => {
                                            setSearchCustomer(true)
                                        }
                                    } style={{ position: "relative", borderRadius: "8px", padding: "10px 0px", border: "2px solid #ddd" }}>
                                        <img src={email} alt="" style={{ width: "30px" }} />
                                        <img className='mx-2' src={down} alt="" style={{ width: "15px", position: "absolute", zIndex: "1000", right: "10px" }} />
                                        {
                                            !emails.length ?
                                                <>
                                                    <p className="customers m-0 p-0 text-dark col-11 cursor-pointer"

                                                    >Recipients</p>
                                                </>
                                                : emails.length == 1 ?
                                                    <div className="emails col-11 d-flex align-items-center" style={{ overflowX: "scroll" }}>
                                                        {
                                                            emails.map(email => {
                                                                return (<div style={{ display: "flex" }} >
                                                                    <div className="email-show border border-1 text-dark px-2 pb-1 mr-2" style={{ fontSize: "12px", background: "rgba(255, 255, 255,.7)", borderRadius: "4px" }}>{email}</div>
                                                                </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    <div className="emails col-11 d-flex align-items-center" style={{ overflowX: "scroll" }}>
                                                        {
                                                            emails.slice(0, 1).map(email => {
                                                                return (<div style={{ display: "flex" }}>
                                                                    <div className="email-show border border-1 text-dark px-2 pb-1 mr-2" style={{ fontSize: "12px", background: "rgba(255, 255, 255,.7)", borderRadius: "4px" }}>{email + " + "}{emails.length - 1}{" more"}</div>
                                                                </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                        }

                                    </div>
                                </div>
                                <div className="form-group p-3">
                                    <div className="form-group">
                                        <label htmlFor="" className="d-block mb-2 text-secondary">
                                            <span style={{ fontSize: "14px" }} >Title</span>
                                        </label>
                                        <input
                                            name=""
                                            id="formDesc"
                                            className="form-control"
                                            placeholder="title"
                                            value={title}
                                            style={{ boxShadow: "0 0 5px 1px #ddd", fontSize: "12px", }}
                                            onChange={e => settitle(e.target.value)}
                                        />
                                        <label htmlFor="" class="d-block mb-2 text-secondary mt-3"><span v style={{ fontSize: "14px" }} >Description</span></label>
                                        <textarea name=""
                                            //  cols="30" rows="10"
                                            id="formDesc" className="form-control" placeholder="Please type your message" style={{ minHeight: "170px", fontSize: "12px", boxShadow: "0 0 5px 1px #ddd" }}
                                            defaultValue={messageDetail && messageDetail.template_desc}
                                            onChange={
                                                e => {
                                                    editMessage(messageDetail.template_id, e.target.value)
                                                    setBody(e.target.value)
                                                }
                                            }
                                        ></textarea>
                                    </div>

                                    <button className="btn addAppointment__button"
                                        onClick={
                                            () => {
                                                if (emails.length && body.length && title.length) {
                                                    setLoad(true);
                                                    if (messageDetail.template_type == 1) {
                                                        sendMessages(emails, title, body);
                                                    }
                                                    else {
                                                        console.log({messageDetail});
                                                        sendEmail(emails, title, body,messageDetail?.flyer)
                                                            .then(res => {
                                                                // console.log(res.data);
                                                                if (res["data"]["shop_wizard"]["status"] == 1) {
                                                                    setLoad(false);
                                                                    notifySuccess("Email sent successfully")
                                                                    history.push("/dashboard")
                                                                }
                                                            })
                                                    }
                                                }
                                                else {
                                                    notifyError("Please fill all fields")
                                                }
                                            }
                                        }
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                    : <SearchCustomer id={props.match.params.id} setEmails={setEmails} emails={emails} setSearchCustomer={setSearchCustomer} messageDetail={messageDetail} />
            }
        </section>
    )
}

export default Send
