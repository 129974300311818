import React, { useEffect, useState } from "react";
import Select from 'react-select'
import "./Estimate.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import id from "../assets/id.png";
import name from "../assets/name.png";
import password from "../assets/password.png";
import wrong from "../assets/wrong.png";
import { getAllCustomer, getAllVehicle, generateInvoice, saveEstimate } from "../services/httpService";
import { notifyError, notifySuccess, showConfirmAlert } from "../util/Util";
import Loader from "./Loader";
import SearchCustomer from "./SearchCustomer";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";
const CustomOption = ({ innerRef, innerProps, ...rest }) => {
  return (
    <div ref={innerRef} {...innerProps}>
      <div className="card customer__card m-2">
        <div className="card-body customer__cardBody">
          <div className="row mt-2 mb-2" style={{ height: "50px" }}>
            <div className="col-12 text-secondary">{rest.data.label}</div>
            <div className="col-12 text-dark">{rest.data.value.vin_number}</div>
          </div>
        </div>
      </div>
    </div>)
}
function Estimate() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const history = useHistory()
  const [vehicleData, setVehicleData] = useState([]);
  const [load, setLoad] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [searchCustomer, setSearchCustomer] = useState(false);
  const [customerObj, setCustomerObj] = useState(null);
  const [items, setItems] = useState([{
    item_name: "",
    price: "",
    tax: ""
  }]);
  const [customerId, setCustomer] = useState("")
  const [total, setTotal] = useState(0)
  const [vin, setVin] = useState("")
  const [custName, setCustName] = useState("")
  const [reg, setReg] = useState("")
  const ventor_id = localStorage.getItem("vendor_id");
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("addEstimateData"))
    if (data) {
      setSelectValue({
        label: data.name, value: {
          customer_id: data.customer_id,
          customer_name: data.name,
          vin_number: data.vin,
          reg_number: data.reg,
        }
      })
      setVin(data.vin)
      setCustName(data.name)
      setReg(data.reg)
      setCustomer(data.customer_id)
    }
    setLoad(true)
    getAllVehicle(ventor_id)
      .then((res) => {
        setLoad(false)
        if (res["data"]["shop_wizard"]["status"] === "1") {
          setVehicleData(res["data"]["shop_wizard"]["vehicles"]);
        }
      });
  }, [])

  let handleSubmit = () => {
    if (customerId && items.filter(v => !v.item_name || !v.price).length === 0) {
      setLoad(true)
      generateInvoice({
        customer_id: customerId, total_amount: total, vin_number: vin, reg_number: reg, particulars: items, vendor_id: ventor_id, appointment_id: "0", invoice_id: "0"
      }).then(res => {
        if (res.data.shop_wizard.status == "1") {
          setLoad(false)
          localStorage.removeItem("addEstimateData")
          history.push("/estimate-list")
        } else {
          setLoad(false)
          notifyError(res.data.shop_wizard.msg)
        }
      })
    } else {
      let validate = [
        { name: "Customer", value: customerId }
      ]
      items.map(v => {
        validate.push({
          name: "Issue name", value: v.item_name
        })
        validate.push({
          name: "Enter amount", value: v.price
        })
      })
      validate.some(v => {
        if (v.value) {
          return false
        } else {
          notifyError("Please enter " + v.name)
          return true
        }
      })
      // notifyError("Empty fields not allowed")
    }
  }

  let save = () => {
    if (customerId && items.filter(v => !v.item_name || !v.price).length == 0) {
      setLoad(true)
      saveEstimate({
        customer_id: customerId, total_amount: total, vin_number: vin, reg_number: reg, particulars: items, vendor_id: ventor_id, appointment_id: "0", invoice_id: "0"
      }).then(res => {
        if (res.data.shop_wizard.status == "1") {
          setLoad(false)
          localStorage.removeItem("addEstimateData")
          history.push("/estimate-list")
        } else {
          setLoad(false)
          notifyError(res.data.shop_wizard.msg)
        }
      })
    } else {
      let validate = [
        { name: "Customer", value: customerId }
      ]
      items.map(v => {
        validate.push({
          name: "Issue name", value: v.item_name
        })
        validate.push({
          name: "Enter amount", value: v.price
        })
      })
      validate.some(v => {
        if (v.value) {
          return false
        } else {
          notifyError("Please enter " + v.name)
          return true
        }
      })
    }
  }

  return (
    <>
      <Loader load={load} />
      {
        searchCustomer ?
          <SearchCustomer addEstimateSearch={true} setSearchCustomer={setSearchCustomer} setCustomerObj={setCustomerObj} customerObj={customerObj} />
          :
          (<section className="estimate__section">
            <Navbar heading='Estimates' />
            <div style={{ display: "flex" }}>
              {
                !matches_820 &&
                <div style={{ width: "19%" }}>
                  <SideBar />
                </div>
              }
              <div style={{ width: matches_820 ? "100%" : "81%",position:"relative",height:"91vh"}}>
                <div className="estimate__content container">
                  <div className="row">
                    <div className="col-7 pl-0 estimate__col">
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-2 offset-1 d-flex justify-content-center  estimate__col">
                      <img
                        src={name}
                        alt=""
                        className="img-fluid"
                        style={{ height: "20px" }}
                      />
                    </div>
                    <div className="col-7 pl-0 estimate__col">
                      <input readOnly type="text" className="bg-none border border-0 w-100" placeholder="Customer Name"
                        onClick={
                          e => {
                            setSearchCustomer(true)
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2 offset-1 d-flex justify-content-center estimate__col">
                      <img
                        src={password}
                        alt=""
                        className="img-fluid"
                        style={{ height: "20px" }}
                      />
                    </div>
                    <div className="col-7 pl-0 estimate__col">
                      <input disabled type="text" className="bg-none border border-0 w-100" placeholder="VIN No./Car Reg No." />
                    </div>
                  </div>
                </div>
                <div className="container estimate__bottom__card mt-5">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="card estimate__card">
                        <div className="card-body pb-5">
                          <p
                            className="font-weight-bold text-dark d-flex justify-content-between"
                            style={{ fontSize: "12px" }}
                          >
                            SERVICE WITH CARS
                            <i class="fa fa-plus-circle fa-2x mr-3" style={{ color: "#1050e6", cursor: "pointer" }} aria-hidden="true" onClick={e => setItems(data => ([...data, {
                              item_name: "",
                              price: "",
                              tax: ""
                            }]))}></i>
                          </p>
                          {items.map((val, i) => (<div className="row mb-2">
                            <div className="col-2 p-0 text-center d-flex justify-content-center align-items-center">
                              <span className="font-weight-bolder text-dark">{i + 1}.</span>
                            </div>
                            <div className="col-4 p-0">
                              <input
                                type="text"
                                placeholder="Type service"
                                className="estimate__input"
                                onChange={e => setItems(data => {
                                  data[i] = {
                                    ...data[i],
                                    item_name: e.target.value
                                  }
                                  return data
                                })}
                              />
                            </div>
                            <div className="col-4">
                              <input
                                type="number"
                                placeholder="Amount"
                                className="estimate__input"
                                onChange={e => setItems(data => {
                                  let value = e.target.value
                                  let tax = localStorage.getItem("tax") ? localStorage.getItem("tax") : 10
                                  data[i] = {
                                    ...data[i],
                                    price: value,
                                    tax: value ? value * (parseInt(tax) / 100) : 0
                                  }
                                  setTotal(items.map(v => parseInt(v.price ? v.price : 0)).reduce((t, v) => (t + v)))
                                  return data
                                })}
                              />
                            </div>
                            <div className="col-2 p-0  d-flex justify-content-center align-items-center">
                              <img
                                src={wrong}
                                alt=""
                                className="img-fluid"
                                style={{ height: "20px" }}
                                onClick={e => {
                                  if (i !== 0) {
                                    setItems(data => {
                                      data.splice(i, 1)
                                      return data
                                    })
                                  }
                                }}
                              />
                            </div>
                          </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="generate-invoice_container">
                  <div className="generate-invoice-data d-flex justify-content-between px-3 py-1">
                    <p className="data">Service:{items.length}</p>
                    <p className="data">Amount:{total}</p>
                    <p className="data">Tax:{total * parseInt(localStorage.getItem("tax")) / 100}</p>
                  </div>
                  <button className="generate-invoice-btn text-center" onClick={e => showConfirmAlert(handleSubmit)}>Generate Invoice</button>
                </div>
              </div>
            </div>
          </section>)}
    </>
  );
}

export default Estimate;
