import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import back from "../assets/back.png";
import hamburger from "../assets/filter.png";
import { getAllInvoice, printInvoice, sendInvoiceEmail } from "../services/httpService";
import { notifyError, notifySuccess } from "../util/Util";
import BillPage from "./billPage";
import "./Estimate2.css"
import Loader from "./Loader";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import print from "../assets/print.png";
import pay from "../assets/pay.png";
import mail from "../assets/mail.png";

import "react-datepicker/dist/react-datepicker.css";
import Ncontext from "../ContextC";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";

function Invoice(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const matches_545 = useMediaQuery('(max-width:545px)');
  const context = useContext(Ncontext);
  const { name, setname, customerDetailbill, setCustomerDetailbill, InvoiceNumbill, setInvoiceNumbill, itemDetailbill, setItemDetailbill, vendorDetailbill, setVendorDetailbill } = context;

  const [InvoiceData, setInvoiceData] = useState([])
  const [itemDetail, setItemDetail] = useState([])
  const [customerDetail, setCustomerDetail] = useState("")
  const [vendorDetail, setVendorDetail] = useState("")
  const [load, setLoad] = useState(false)
  const [selected, setSelected] = useState("")
  const [showData, setShowData] = useState([]);
  const [query, setQuery] = useState(name);
  const [contQuery, setContQuery] = useState("");
  const ventor_id = localStorage.getItem("vendor_id");
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [status, setstatus] = useState('');
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [bool, setbool] = useState(false);
  const [notes_arr, setnotes_arr] = useState([]);
  const [policy_arr, setpolicy_arr] = useState([]);
  const [inv_date, setinv_date] = useState('');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [vehicle_detail, setvehicle_detail] = useState({});
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    // setAge(event.target.value);
    setstatus(event.target.value)
  };

  useEffect(() => {
    // alert(name);
    setLoad(true)
    getAllInvoice(ventor_id)
      .then((res) => {
        setLoad(false)
        if (res["data"]["shop_wizard"]["status"] == "1") {
          console.log(res["data"]["shop_wizard"]["items"]);
          // setShowData(res["data"]["shop_wizard"]["items"]);
          if (name != "") {
            // alert(name);
            setInvoiceData(res["data"]["shop_wizard"]["items"].filter(v => v.name.toLowerCase().includes(name.toLowerCase())).map((item) => {
              return item
            }))
            setShowData(res["data"]["shop_wizard"]["items"].filter(v => v.name.toLowerCase().includes(name.toLowerCase())).map((item) => {
              return item
            }));
            setname("");
          } else {

            setShowData(res["data"]["shop_wizard"]["items"]);
            setInvoiceData(res["data"]["shop_wizard"]["items"]);
          }
          // console.log("invs data", res["data"]["shop_wizard"]["items"].filter(v => v.name.toLowerCase().includes(name.toLowerCase())).map((item) => {
          //   return item
          // }));
        }
      });
    return () => { };
  }, []);

  useEffect(() => {
    if (selected) {
      printInvoice(selected).then(res => {
        if (res["data"]["shop_wizard"]["status"] === "1") {
          console.log(res["data"]["shop_wizard"]);
          setItemDetail(res.data.shop_wizard.item_detail)
          setCustomerDetail(res.data.shop_wizard.customer_detail)
          setVendorDetail(res.data.shop_wizard.vendor_detail);
          setvehicle_detail(res.data.shop_wizard.vehicle_detail);
          setnotes_arr(res.data.shop_wizard.notes);
          setpolicy_arr(res.data.shop_wizard.policy);
        }
      })
    }
  }, [selected])


  useEffect(() => {
    if (itemDetail.length && vendorDetail && vehicle_detail?.car_name) {
      handlePrint()
    }
  }, [itemDetail.length, JSON.stringify(vendorDetail), vehicle_detail, notes_arr, policy_arr, inv_date])

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(InvoiceData)

  useEffect(() => {
    if (name != "") {
      setShowData(InvoiceData.filter(v => v.name.toLowerCase().includes(JSON.stringify(name).toLowerCase())));
    } else {
      setShowData(InvoiceData)
    }
  }, [])

  useEffect(() => {

    if (query) {
      setShowData(InvoiceData.filter(v => v.name.toLowerCase().includes(query.toLowerCase()) || v.contact.toLowerCase().includes(query.toLowerCase()) || JSON.stringify(v.vin_number).toLowerCase().includes(JSON.stringify(query).toLowerCase()) || JSON.stringify(v.vin_number).toLowerCase().includes(query.toLowerCase())))
    } else {
      setShowData(InvoiceData)
    }
  }, [query])

  useEffect(() => {
    if (contQuery) {
      setShowData(showData.filter(v => JSON.stringify(v.contact).toLowerCase().includes(contQuery.toLowerCase()) || JSON.stringify(v.date_time).toLowerCase().includes(contQuery.toLowerCase())))
    } else {
      if (query) {
        setShowData(InvoiceData.filter(v => v.name.toLowerCase().includes(query.toLowerCase()) || v.contact.toLowerCase().includes(query.toLowerCase()) || JSON.stringify(v.vin_number).toLowerCase().includes(JSON.stringify(query).toLowerCase())))
      } else {
        setShowData(InvoiceData)
      }
      // getAllCustomer(ventor_id)
      //   .then((res) => {
      //     if (res["data"]["shop_wizard"]["status"] === "1") {
      //       setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
      //     }
      //   });
    }
  }, [contQuery]);

  const emailHTML = (id, vin, cust_id, email, date) => {
    setLoad(true);
    printInvoice(id, vin, cust_id).then(res => {
      let img = Object.keys(res["data"]["shop_wizard"]).length ? res["data"]["shop_wizard"].vendor_detail.logo_url : "";
      let dateinv = date == 'null' ? "No date" : date;
      if (res["data"]["shop_wizard"]["status"] === "1") {
        let html = `
          <div className="row">
            <div style=" display: flex; width: 100%; padding: 0px 0px 0px 10px" >
              <div style= "width: 48%; display: flex">
                <img src="`+ img + `" style="height:80px" />&nbsp;&nbsp;
                <div>
                  <p style= "fontSize: 11px; color: black; lineHeight: 16px; width: 120px; paddingTop: 1px ">
                    <span style=" fontSize: 15px; fontWeight: 600 ">`+ res["data"]["shop_wizard"].vendor_detail.name + `</span><br />
                    `+ res["data"]["shop_wizard"].vendor_detail.address + `<br />
                    `+ res["data"]["shop_wizard"].vendor_detail.contact + `<br />
                    `+ res["data"]["shop_wizard"].vendor_detail.email + `
                  </p>
                </div>
              </div>
              <div style=" width: 48%; color: #000 ">
                <div style=" width: 100%; display: flex; justifyContent: end; alignItems: center" >
                  <b>INVOICE `+ id + `</b>
                </div>
                <div style=" width: 100%; display: flex; justifyContent: end; alignItems: center; fontSize: 12px" >
                  <i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;`+ dateinv + `
                </div>
              </div>
            </div>
            <div style="display: flex; width: 100%; padding: 15px 0px 0px 10px; fontSize: 13px; fontWeight: 500">
              <div style=" width: 48%; color: #000">
                <div style=" width: 100%; display: flex; justifyContent: start; alignItems: center">
                  <i class="fa fa-user" aria-hidden="true"></i>&nbsp; `+ res["data"]["shop_wizard"].customer_detail.name + `
                </div>
                <div style=" width: 100%; display: flex; justifyContent: start; alignItems: center">
                  <i class="fa fa-phone" aria-hidden="true"></i>&nbsp; `+ res["data"]["shop_wizard"].customer_detail.contact + `
                </div>
              </div>
              <div style=" width: 48%; color: #000">
                <div style=" width: 100%; display: flex; justifyContent: end; alignItems: center">
                  `+ res["data"]["shop_wizard"].vehicle_detail.year + ` ` + res["data"]["shop_wizard"].vehicle_detail.color + ` ` + res["data"]["shop_wizard"].vehicle_detail.car_name + ` ` + res["data"]["shop_wizard"].vehicle_detail.model + `
                </div>
                <div style=" width: 100%; display: flex; justifyContent: end; alignItems: center">
                  <span style=" fontWeight: 600">VIN:</span>&nbsp; `+ res["data"]["shop_wizard"].customer_detail.vin_number + `
                </div>
              </div>
            </div>
            <div style=" width: 100%; padding: 15px 10px 0px 10px; color: #000" >
              <div style=" border: 1px solid grey; backgroundColor: #ddd; height: 30px; width: 100%"></div>
              <table style="width: 100%">
                <thead style="border: 1px solid grey; fontSize: 12px" >
                  <th style=" padding: "7px 0px"  ><center>#</center></th>
                  <th ><center>Description</center></th>
                  <th ><center>Price</center></th>
                  <th ><center>Tax</center></th>
                  <th ><center>Sub total</center></th>
                </thead>
                <tbody>
                  `+
          res["data"]["shop_wizard"]?.item_detail?.map((val, index) => {
            return (
              `<tr style=" border: 1px solid grey; fontSize: 13px; padding: 5px 0px">
                          <td style=" padding: 5px 0px"><center>`+ index + 1 + `</center></td>
                          <td><center>`+ val.item_name + `</center></td>
                          <td><center>`+ val.amount + `</center></td>
                          <td><center>`+ val.tax + `</center></td>
                          <td><center>`+ parseInt(val.amount) + parseInt(val.tax) + `</center></td>
                        </tr>`
            )
          }
          ) +
          `
                </tbody>
              </table>
            </div>
            <div style=" width: 100%; padding: 0px 10px 0px 10px; display: flex; justifyContent: end; color: #000" >
              <div style="width: 50%; padding: 0px 0px; fontSize: 12px; fontWeight: 600">
                <div style="width: 100%; height: 30px; justifyContent: space-between; padding: 0px 51px 0px 8px; border: 1px solid grey; display: flex; alignItems: center; backgroundColor: #fff">
                  <span style="fontWeight: 550; fontSize: 11px">TOTAL PRICE</span>&nbsp;<span>`+ res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.amount ? v.amount : 0)).reduce((t, v) => (t + v)) + `</span>
                </div>
                <div style="width: 100%; height: 30px; justifyContent: space-between; padding: 0px 51px 0px 8px; border: 1px solid grey; display: flex; alignItems: center; backgroundColor: #fff">
                  <span style="fontWeight: 550; fontSize: 11px">TOTAL TAX</span>&nbsp;<span>`+ res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.tax ? v.tax : 0)).reduce((t, v) => (t + v)) + `</span>
                </div>
                <div style="width: 100%; height: 30px; justifyContent: space-between; padding: 0px 51px 0px 8px; border: 1px solid grey; display: flex; alignItems: center; backgroundColor: #fff">
                  <span style="fontWeight: 550; fontSize: 11px">TOTAL AMOUNT</span>&nbsp;<span>`+ parseInt(res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.amount ? v.amount : 0)).reduce((t, v) => (t + v))) + parseInt(res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.tax ? v.tax : 0)).reduce((t, v) => (t + v))) + `</span>
                </div>
              </div>
            </div>
            <div style=" width: 100%; padding: 15px 10px 0px 10px; display: flex; fontSize: 11px; justifyContent: start; color: #000" >
              Thank you for your business, Your payment is due upon vehicle pickup.
            </div>
            <div style="width: 100%; padding: 10px 10px 0px 10px; display: flex; fontSize: 13px; justifyContent: start; color: #000">
              <strong>Signature____________________</strong>
            </div>
          </div>`

        console.log(html);
        sendInvoiceEmail(email, id, html)
          .then(res => {
            console.log("sent email", res)
            if (res["data"]["shop_wizard"]["status"] == "1") {
              setLoad(false);
              notifySuccess("Email sent successfully")
            } else {
              setLoad(false);
              notifyError(res["data"]["shop_wizard"]["msg"])
            }
          })
      }
    })

  }

  const thead_padding = matches_545 ? { paddingRight: "5px", paddingLeft: "5px" } : {};


  return (
    <>
      <Loader load={load} />
      <section className="estimate__section">
        <Navbar heading='Invoices' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80%" }}>
            <div className="custom-table_container" style={{ position: "relative" }}>
              <div className="" style={{ display: "flex", width: "100%", alignItems: "center", padding: "0px 15px", position: "absolute", top: "0px" }}>
                <div class="input-group search-bar_container py-3 pr-2" >
                  {contQuery && <span class="input-group-append" onClick={() => { setContQuery('') }}>
                    <button class="btn btn-outline-secondary border-right-0 border" type="button">
                      <i class="fa fa-arrow-left"></i>
                    </button>
                  </span>}
                  <input class="form-control py-2 border-right-0 border" type="search" onChange={e => {
                    setQuery(e.target.value)
                    setContQuery("")
                  }} placeholder="Search..." id="example-search-input" />
                  <span class="input-group-append">
                    <button class="btn btn-outline-secondary border-left-0 border" type="button">
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
                <div className=" d-flex align-items-center justify-content-end pl-2" style={{ position: "relative" }}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    style={{ border: "1px solid #dee2e6", height: "38px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <img
                      src={hamburger}
                      style={{ height: "25px", cursor: "pointer" }}
                      alt="Filter"
                    // className="img-fluid notification_img navbar__img mx-1"
                    />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    style={{ position: "absolute", zIndex: "1000", right: '500px' }}
                  >
                    <MenuItem >Invoice Status &nbsp;&nbsp;
                      <FormControl >
                        <InputLabel id="demo-simple-select-label">Due</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          style={{ width: "150px", height: "40px" }}
                          label="Age"
                          onChange={handleChange}
                        >
                          <MenuItem value={0}>Pending</MenuItem>
                          <MenuItem value={2}>Partial</MenuItem>
                          <MenuItem value={1}>Paid</MenuItem>
                        </Select>
                      </FormControl>
                    </MenuItem>
                    <MenuItem >Invoice Date</MenuItem>
                    <MenuItem >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From"
                          value={fromdate}
                          onChange={(newValue) => {
                            setfromdate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} style={{ width: "150px", padding: "0px" }} />}
                        />
                      </LocalizationProvider></MenuItem>
                    <MenuItem >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To"
                          value={todate}
                          onChange={(newValue) => {
                            settodate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} style={{ width: "150px", padding: "0px" }} />}
                        />
                      </LocalizationProvider>
                    </MenuItem>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 10px" }}>
                      <Button variant="contained" onClick={() => {
                        let fm = fromdate.$M + 1;
                        let ft = todate.$M + 1;
                        let from = fromdate.$y + "-" + fm + "-" + fromdate.$D;
                        let to = todate.$y + "-" + ft + "-" + todate.$D;
                        // console.log(ventor_id, status, from, to);
                        const date_from = fromdate.$y ? from : null;
                        const date_to = todate.$y ? to : null;
                        let s = JSON.stringify(status)
                        setLoad(true)
                        getAllInvoice(ventor_id, s, date_from, date_to).then((res) => {
                          setLoad(false)
                          if (res["data"]["shop_wizard"]["status"] === "1") {
                            setInvoiceData(res["data"]["shop_wizard"]["items"]);
                            setShowData(res["data"]["shop_wizard"]["items"]);
                            console.log("invs data", res["data"]["shop_wizard"]["items"])
                          }
                          else {
                            notifyError("No records found")
                          }
                        }).catch((err) => {
                          console.log(err);
                        });
                      }}>Filter</Button>
                      <Button variant="contained" onClick={() => {
                        setfromdate("");
                        settodate("");
                        setstatus("");
                        getAllInvoice(ventor_id).then((res) => {
                          setLoad(false)
                          if (res["data"]["shop_wizard"]["status"] === "1") {
                            setInvoiceData(res["data"]["shop_wizard"]["items"]);
                            setShowData(res["data"]["shop_wizard"]["items"]);
                            console.log("invs data", res["data"]["shop_wizard"]["items"])
                          }
                          else {
                            notifyError("No records found")
                          }
                        }).catch((err) => {
                          console.log(err);
                        });
                      }}>Reset</Button>
                    </div>
                  </Menu>
                </div>
              </div>
              <div style={{ height: "70px" }}></div>
              <div style={{ width: "100%", padding: "0px 12px", height: "80vh", overflowY: "auto" }}>

                <table style={{ width: "100%" }}>
                  <thead style={{ borderBottom: "1px solid #c9c9c9", borderTop: "1px solid #c9c9c9", fontSize: matches_545 ? "14px" : "" }}>
                    <th style={{ padding: "3px", ...thead_padding }} className="text-uppercase">Inv#</th>
                    <th className="text-uppercase" style={thead_padding} >Inv Date</th>
                    <th className="text-uppercase" style={thead_padding} >Name</th>
                    <th className="text-uppercase" style={thead_padding} >Contact</th>
                    <th className="text-uppercase" style={thead_padding} >VIN No.</th>
                    <th className="text-uppercase" style={thead_padding} >Paid</th>
                    <th className="text-uppercase" style={thead_padding} >Due</th>
                    <th className="text-uppercase" style={thead_padding} >Status</th>
                    <th style={{ textAlign: "center", ...thead_padding }} className="text-uppercase">Action</th>
                  </thead>
                  <tbody>
                    {showData.map((val, index) => {
                      // console.log("123456", val)
                      return (
                        <tr style={{ cursor: "pointer", background: ((index % 2) == 0) ? "#fff" : "#f5f5f5", fontSize: matches_545 ? "14px" : "16px" }} >
                          <td
                            id={"sum_" + index}
                            onMouseOver={() => {
                              document.querySelector("#sum_" + index).style.textDecoration = "underline"
                            }}
                            onMouseOut={() => {
                              document.querySelector("#sum_" + index).style.textDecoration = "none"
                            }}
                            onClick={
                              () => {
                                history.push(`/invoice-detail/${val.invoice_id}/${val.vin_number}/${val.customer_id}/${val.date_time}`);
                                // setVendorDetail("")
                                //   setCustomerDetail("")
                                //   setItemDetail([])
                                //   setSelected(val.invoice_id)
                                // setVendorDetailbill("");
                                // setCustomerDetailbill("");
                                // setItemDetailbill([]);
                                // setInvoiceNumbill(val.invoice_id);
                                // history.push(`/bill`);
                              }
                            } style={thead_padding} >{val.invoice_id}</td>
                          <td style={{ padding: "3px", whiteSpace: "nowrap", cursor: "pointer", ...thead_padding }} onClick={
                            () => {
                              // history.push(`/invoice-detail/${val.invoice_id}`)
                              // alert(val.date_time.toLowerCase())
                              setContQuery(JSON.stringify(val.date_time == null ? "" : val.date_time))
                              // alert(val.date_time.toLowerCase().includes(query.toLowerCase()))
                              setQuery("")
                              setbool(true);
                            }
                          }>{val.date_time == null ? "No date" : val.date_time}</td>
                          <td
                            style={{ cursor: "pointer", ...thead_padding }}
                            onClick={
                              () => {
                                // history.push(`/invoice-detail/${val.invoice_id}`)
                                setQuery(val.name)
                                setContQuery("")
                                setbool(true);
                              }
                            }
                          >{val.name}</td>
                          <td style={thead_padding} className="cursor-pointer"
                            onClick={
                              () => {
                                // alert(JSON.stringify(val.contact).toLowerCase());
                                setContQuery(JSON.stringify(val.contact));
                                setQuery("");
                                setbool(true);
                              }
                            }
                          >{val.contact}</td>

                          <td style={{ padding: "0px", cursor: "pointer", ...thead_padding }} onClick={
                            () => {
                              // history.push(`/invoice-detail/${val.invoice_id}`)
                              setQuery(val.vin_number);
                              setContQuery("")
                              setbool(true);
                            }
                          } >{val.vin_number}</td>
                          <td style={thead_padding} >{val.paid_amount}</td>
                          <td style={thead_padding} >{(val.total_amount - val.paid_amount).toFixed(2)}</td>
                          <td style={thead_padding} className="text-uppercase font-weight-bolder">{
                            (val.status === "1") ?
                              <span style={{ color: "#9ccf97" }}>Paid</span> :
                              ((val.status === "0") ?
                                <span style={{ color: "#dac26d" }}>DUE</span> :
                                <span style={{ color: "#e8675b" }}>Partial</span>)}</td>
                          <td>
                            <div style={{ width: "100%", ...thead_padding, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img src={mail} onClick={
                                () => {
                                  // console.log("data", val)
                                  // getInvoiceData(val.invoice_id,val.vin_number,val.customer_id,val.email);
                                  console.log(val.invoice_id, val.vin_number, val.customer_id, val.email, val.date_time);

                                  emailHTML(val.invoice_id, val.vin_number, val.customer_id, val.email, val.date_time);
                                }
                              } className="mx-1" style={{ height: "16px", cursor: "pointer" }} />
                              <img src={pay} className="mx-1" onClick={() => {
                                localStorage.removeItem("estimateEdit2")
                                localStorage.setItem("estimateEdit2", JSON.stringify({ invoice: val.invoice_id, customer_name: val.name, total: val.total_amount, vin: val.vin_number, reg: val.reg_number, customer: val.customer_id }))
                                props.history.push("/pay-estimate/" + val.invoice_id)
                              }} style={{ height: "16px", display: val.status != "1" ? "block" : "none", cursor: "pointer" }} />
                              <img src={print} className="mx-1" onClick={e => {
                                setVendorDetail("")
                                setCustomerDetail("")
                                setItemDetail([])
                                setvehicle_detail({});
                                setSelected('');
                                setinv_date(val.date_time)

                                // setnotes_arr([]);
                                // setpolicy_arr([]);
                                setTimeout(() => {
                                  setSelected(val.invoice_id);
                                }, 50);
                              }} style={{ height: "16px", cursor: "pointer" }} />
                            </div>
                          </td>
                          {/*{val.status === "1" ? (
                        <td className="cursor-pointer" onClick={e => {
                        setVendorDetail("")
                        setCustomerDetail("")
                        setItemDetail([])
                        setSelected(val.invoice_id)
                      }}>Print</td>) : (
                        <td className="cursor-pointer" onClick={() => {
                          localStorage.removeItem("estimateEdit2")
                          localStorage.setItem("estimateEdit2", JSON.stringify({ invoice: val.invoice_id, customer_name: val.name, total: val.total_amount, vin: val.vin_number, reg: val.reg_number, customer: val.customer_id }))
                          props.history.push("/pay-estimate/" + val.invoice_id)
                        }} >Pay</td>
                      )}*/}
                        </tr>)
                    }
                    )

                    }
                  </tbody>
                </table>
              </div>
              <div className="d-none"><BillPage
                vehicle_detail={vehicle_detail}
                inv_date={inv_date}
                ref={componentRef} policy_arr={policy_arr} notes_arr={notes_arr} invoiceNumber={selected} itemDetail={itemDetail} customerDetail={customerDetail} vendorDetail={vendorDetail} /></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Invoice;
