import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import back from "../assets/back.png";
import "./Estimate2.css";
import "./AddTemplate.css";
import { addMessage, create_email_templateProfile } from "../services/httpService";
import { notifyError, notifySuccess } from "../util/Util";
import Loader from "./Loader";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";

function AddTemplate(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const history = useHistory()
  const [desc, setMessage] = useState("");
  const [load, setLoad] = useState(false);
  const [type, setType] = useState("");
  const [title, settitle] = useState('');
  const vendor_id = localStorage.getItem("vendor_id");
  const [image, setimage] = useState(null);
  const [img_name, setimg_name] = useState('Choose flyer (if any)')
  return (
    <>
      <Loader load={load} />
      <section className="estimate__section">
        <Navbar heading='Add New Template' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80.9%" }}>
            <div className="add-template_container d-flex flex-wrap p-3">
              <form onSubmit={e => {
                e.preventDefault()
                if (desc && type && title) {
                  console.log({image, vendor_id, type, desc, title});
                  setLoad(true)
                  create_email_templateProfile(image, vendor_id, type, desc, title).then((res) => {
                    setLoad(false);
                    history.push("/message");
                  }).catch(err => {
                    notifyError("Something went wrong!")
                  })
                } else {
                  let validate = [
                    { name: "Title", value: title },
                    { name: "Template type", value: type },
                    { name: "Description", value: desc }
                  ]
                  validate.some(v => {
                    if (v.value) {
                      return false
                    } else {
                      notifyError("Please enter " + v.name)
                      return true
                    }
                  })
                }
              }} style={{ width: "100%" }}>
                <div className="col-6"></div>
                <div className="col-12" style={{ alignItems: "center", width: "100%" }}>
                  <span className="text-secondary" style={{ fontSize: "14px", fontWeight: "700" }} >Choose template type</span>
                  <br />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <select className="form-control mt-1" name="" id="" style={{ cursor: "pointer", width: "80px", border: "none" }} onChange={e => {
                      setType(e.target.value);
                      setimage(null);
                      setimg_name('Choose flyer (if any)');
                    }
                    }>
                      <option disabled selected>Type</option>
                      <option value="1">SMS</option>
                      <option value="2">Email</option>
                    </select>
                    &nbsp;
                    &nbsp;
                    {
                      type == 2 &&
                      <>
                        <div style={{ color: "#000", opacity: 1, backgroundColor: "#fff", position: "relative", padding: "4px 10px", fontSize: "14px" }} >
                          {img_name}
                          <label htmlFor="fileInput" className="file-input-label" style={{
                            position: "absolute", top: -10, right: 0,
                            height: "100%", backgroundColor: "transparent", width: "100%"
                          }}></label>
                        </div>
                        <input
                          id="fileInput" className="file-input"
                          style={{ marginTop: "14px", color: "#000" }}
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            setimage(e.target.files[0]);
                            setimg_name(e.target.files[0].name)
                            // console.log(e.target.files[0]);
                          }}
                          placeholder="choose flyer (if any)"
                        />
                      </>
                    }
                  </div>
                </div>
                <div className="col-sm-12 pt-3" style={{ width: "100%" }}>
                  <label htmlFor="" className="d-block mb-2 text-secondary">
                    <span style={{ fontSize: "14px" }} >Title</span>
                  </label>
                  <input
                    name=""
                    id="formDesc"
                    className="form-control"
                    placeholder="title"
                    style={{ boxShadow: "0 0 5px 1px #ddd", fontSize: "14px", }}
                    onChange={e => settitle(e.target.value)}
                  />
                </div>
                <div className="col-12 pt-3" style={{ width: "100%" }} >
                  <label htmlFor="" className="d-block mb-2 text-secondary">
                    <span style={{ fontSize: "14px" }} >Description</span>
                  </label>
                  <textarea
                    name=""
                    id="formDesc"
                    className="form-control"
                    placeholder="Please type your message"
                    style={{ fontSize: "14px", width: "100%" }}
                    onChange={e => {
                      setMessage(e.target.value)
                    }}
                  >
                  </textarea>
                  <button className="filled-btn mt-4">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddTemplate;
