import React, { useContext } from "react";
import "./Message.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import redwrong from "../assets/redWrong.png";
import edit from "../assets/edit.png";
import send from "../assets/send.png";
import add from "../assets/add.png";
import { useState } from "react";
import { useEffect } from "react";
import { getAllMessages, deleteMessage } from "../services/httpService";
import { notifyError, notifySuccess, showConfirmDelete } from "../util/Util";
import Loader from "./Loader";
import ShowMOre from "./ShowMOre";
import Ncontext from "../ContextC";
import Navbar from "./Navbar";
import SideBar from "./Sidebar";
import { useMediaQuery } from "@mui/material";

function Message() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const context = useContext(Ncontext);
  const { templateTypeNum, settemplateTypeNum, total_cust, settotal_cust } = context;
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState([]);
  const [load, setLoad] = useState(false);
  const [pageType, setPageType] = useState("");
  const ventor_id = localStorage.getItem("vendor_id");
  const [showData, setShowData] = useState([]);
  const [query, setQuery] = useState("");
  const history = useHistory();

  const [Templatetype, setTemplatetype] = useState('Type')

  const getAllTemplates = () => {
    getAllMessages(ventor_id)
      .then((res) => {
        setLoad(false)
        console.log(res["data"]["shop_wizard"]);
        if (res["data"]["shop_wizard"]["status"] == "1") {
          if (!res["data"]["shop_wizard"].hasOwnProperty('sms_template')) {
            setMessage(res["data"]["shop_wizard"]["email_template"].filter((item) => {
              if (item == undefined) {
                return;
              } else {
                return item;
              }
            }));
            setShowData(res["data"]["shop_wizard"]["email_template"].filter((item) => {
              if (item == undefined) {
                return;
              } else {
                return item;
              }
            }));
          } else if (!res["data"]["shop_wizard"].hasOwnProperty('email_template')) {
            setMessage((res.data.shop_wizard.sms_template).filter((item) => {
              if (item == undefined) {
                return;
              } else {
                return item;
              }
            }));
            setShowData((res.data.shop_wizard.sms_template).filter((item) => {
              if (item == undefined) {
                return;
              } else {
                return item;
              }
            }));
          }
          else {
            setMessage(res["data"]["shop_wizard"]["email_template"].concat(res.data.shop_wizard.sms_template).filter((item) => {
              if (item == undefined) {
                return;
              } else {
                return item;
              }
            }));
            setShowData(res["data"]["shop_wizard"]["email_template"].concat(res.data.shop_wizard.sms_template).filter((item) => {
              if (item == undefined) {
                return;
              } else {
                return item;
              }
            }));
          }
        }
        else {
          setMessage([]);
          setShowData([]);
        }
      }).catch(err => setLoad(false));
  }

  useEffect(() => {
    // alert(ventor_id)
    setLoad(true);
    getAllTemplates();
    return () => { };
  }, []);

  useEffect(() => {
    if (query) {
      setShowData(message.filter(v => v.template_desc.toLowerCase().includes(query.toLowerCase())))
    } else {
      setShowData(message)
    }
  }, [query])
  return (
    <>
      <Loader load={load} />
      <section className="message__section">
        <Navbar heading='Marketing Templates' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80.9%", position: "relative" }}>
            <div className="container message__content" >
              <div class="input-group col-md-12 search-bar_container py-3" style={{ position: "absolute", top: "0px" }}>
                <input class="form-control py-2 border-right-0 border" type="search" onChange={e => {
                  setQuery(e.target.value)
                }} placeholder="Search..." id="example-search-input" />
                <span class="input-group-append">
                  <button class="btn btn-outline-secondary border-left-0 border" type="button">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{Templatetype}</button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {
                      setTemplatetype('ALL');
                      setMessageType("")
                    }}>ALL</a>
                    <a class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {
                      setTemplatetype('SMS');
                      setMessageType('1')
                    }}>SMS</a>
                    <a class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {
                      setTemplatetype('Email');
                      setMessageType('2')
                    }}>Email</a>
                  </div>
                </div>
                <span class="input-group-append">
                  <Link to="/add-template">
                    <button class="btn btn-outline-secondary" style={{ border: "1px solid #6c757d", borderLeft: "0px" }} type="button">
                      <i class="fa fa-plus"></i>
                    </button>
                  </Link>
                </span>
              </div>
              <div className="row mt-1" style={{ maxHeight:"80vh", overflowY: "auto" }}>
                {showData.length > 0 &&
                  showData.filter(v => {
                    if (messageType) {
                      return (v.template_type == messageType) ? true : false
                    }
                    return true
                  }).map(function (msg) {
                    return (
                      <div className="col-12" >
                        <div className="card message__content__card mb-2">
                          <div className="card-body" style={{ padding: "13px 15px 0px 15px" }}>
                            <div className="row" >
                              <div className="col-9" style={{ height: "25px" }}>
                                <p className="font-weight-bolder text-dark" style={{ fontSize: "14px" }}>
                                  {
                                    msg.subject.length > 36 ?
                                      msg.subject.slice(0, 36) + "..." : msg.subject}
                                </p>
                              </div>
                              <div className="col-3 d-flex justify-content-end cursor-pointer" >
                                <img
                                  onClick={
                                    () => {
                                      if (total_cust == 0) {
                                        notifyError("You have no customers")
                                      }
                                      else {
                                        settemplateTypeNum(msg.template_type)
                                        history.push("/send/" + msg.template_id)
                                      }
                                    }
                                  }
                                  src={send}
                                  alt=""
                                  className="img-fluid"
                                  style={{ height: "21px" }}
                                />
                                <img
                                  onClick={
                                    () => {
                                      history.push("/edit-template/" + msg.template_id)
                                    }
                                  }
                                  src={edit}
                                  alt=""
                                  className="img-fluid mx-2"
                                  style={{ height: "19px" }}
                                />
                                <img
                                  onClick={e => {
                                    showConfirmDelete(() => {
                                      deleteMessage(msg.template_id).then(val => {
                                        console.log('delete');
                                        getAllTemplates();
                                      })
                                    })
                                  }}
                                  src={redwrong}
                                  alt=""
                                  className="img-fluid"
                                  style={{ height: "20px" }}
                                />
                              </div>
                            </div>
                            <div className="row" >
                              <div className="col-12" style={{ padding: "0px 0px 0px 16px", }}>
                                <ShowMOre desc={msg.template_desc} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {/* <div className="col-12 ">
          <img
            src={add}
            alt=""
            className="img-fluid customer_plus__button"
          // style={{ height: "660px" }}
          />
    </div> */}

          </div>
        </div>
      </section>
    </>
  );
}

export default Message;
