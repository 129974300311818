import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import add from "../assets/add.png";
import back from "../assets/back.png";
import { getAllEstimate, printInvoice, sendInvoiceEmail } from "../services/httpService";
import "./Estimate2.css"
import Loader from "./Loader";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";
import BillPage from "./billPage";
import print_img from "../assets/print.png";
import edit_img from "../assets/edit_2.png";
import mail from "../assets/mail.png";
import { useReactToPrint } from "react-to-print";
import { notifyError, notifySuccess } from "../util/Util";


function Estimate(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const matches_545 = useMediaQuery('(max-width:545px)');
  const history = useHistory()
  const [estimate, setEstimate] = useState([]);
  const [showData, setShowData] = useState([]);
  const [itemDetail, setItemDetail] = useState([])
  const [customerDetail, setCustomerDetail] = useState([])
  const [vendorDetail, setVendorDetail] = useState([])
  const [selected, setSelected] = useState("")
  const [load, setLoad] = useState(false);
  const ventor_id = localStorage.getItem("vendor_id");
  const [query, setQuery] = useState("");
  const [vehicle_detail, setvehicle_detail] = useState({});
  const [notes_arr, setnotes_arr] = useState([]);
  const [policy_arr, setpolicy_arr] = useState([]);
  const [inv_date, setinv_date] = useState('')

  useEffect(() => {
    setLoad(true)
    getAllEstimate(ventor_id).then(res => {
      setLoad(false)
      if (res["data"]["shop_wizard"]["status"] === "1") {
        console.log(res["data"]["shop_wizard"]["items"]);
        setEstimate(res["data"]["shop_wizard"]["items"]);
        setShowData(res["data"]["shop_wizard"]["items"]);
        console.log(res.data.shop_wizard)
      }
    })
  }, [])

  useEffect(() => {
    if (selected) {
      console.log({ selected });
      printInvoice(selected).then(res => {
        if (res["data"]["shop_wizard"]["status"] === "1") {
          console.log(res["data"]["shop_wizard"]);
          setItemDetail(res.data.shop_wizard.item_detail)
          setCustomerDetail(res.data.shop_wizard.customer_detail)
          setVendorDetail(res.data.shop_wizard.vendor_detail)
          setvehicle_detail(res.data.shop_wizard.vehicle_detail);
          setnotes_arr(res.data.shop_wizard.notes)
          setpolicy_arr(res.data.shop_wizard.policy)
        }
      })
    }
  }, [selected])



  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log(InvoiceData)
  useEffect(() => {
    if (itemDetail.length && vendorDetail && vehicle_detail?.car_name) {
      handlePrint()
    }
  }, [itemDetail.length, vehicle_detail, JSON.stringify(vendorDetail), notes_arr, policy_arr, inv_date])



  useEffect(() => {
    if (query) {
      // searchCustomer(ventor_id, query)
      //   .then((res) => {
      //     if (res["data"]["shop_wizard"]["status"] === "1") {
      //       setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
      //     }
      //   });
      setShowData(estimate.filter(v => v.name.toLowerCase().includes(query.toLowerCase())))
    } else {
      // getAllCustomer(ventor_id)
      //   .then((res) => {
      //     if (res["data"]["shop_wizard"]["status"] === "1") {
      //       setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
      //     }
      //   });
      setShowData(estimate)
    }
  }, [query]);

  const emailHTML = (id, vin, cust_id, email, date) => {
    setLoad(true);
    printInvoice(id, vin, cust_id).then(res => {
      let img = Object.keys(res["data"]["shop_wizard"]).length ? res["data"]["shop_wizard"].vendor_detail.logo_url : "";
      let dateinv = date == 'null' ? "No date" : date;
      if (res["data"]["shop_wizard"]["status"] === "1") {
        let html = `
          <div className="row">
            <div style=" display: flex; width: 100%; padding: 0px 0px 0px 10px" >
              <div style= "width: 48%; display: flex">
                <img src="`+ img + `" style="height:80px" />&nbsp;&nbsp;
                <div>
                  <p style= "fontSize: 11px; color: black; lineHeight: 16px; width: 120px; paddingTop: 1px ">
                    <span style=" fontSize: 15px; fontWeight: 600 ">`+ res["data"]["shop_wizard"].vendor_detail.name + `</span><br />
                    `+ res["data"]["shop_wizard"].vendor_detail.address + `<br />
                    `+ res["data"]["shop_wizard"].vendor_detail.contact + `<br />
                    `+ res["data"]["shop_wizard"].vendor_detail.email + `
                  </p>
                </div>
              </div>
              <div style=" width: 48%; color: #000 ">
                <div style=" width: 100%; display: flex; justifyContent: end; alignItems: center" >
                  <b>INVOICE `+ id + `</b>
                </div>
                <div style=" width: 100%; display: flex; justifyContent: end; alignItems: center; fontSize: 12px" >
                  <i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;`+ dateinv + `
                </div>
              </div>
            </div>
            <div style="display: flex; width: 100%; padding: 15px 0px 0px 10px; fontSize: 13px; fontWeight: 500">
              <div style=" width: 48%; color: #000">
                <div style=" width: 100%; display: flex; justifyContent: start; alignItems: center">
                  <i class="fa fa-user" aria-hidden="true"></i>&nbsp; `+ res["data"]["shop_wizard"].customer_detail.name + `
                </div>
                <div style=" width: 100%; display: flex; justifyContent: start; alignItems: center">
                  <i class="fa fa-phone" aria-hidden="true"></i>&nbsp; `+ res["data"]["shop_wizard"].customer_detail.contact + `
                </div>
              </div>
              <div style=" width: 48%; color: #000">
                <div style=" width: 100%; display: flex; justifyContent: end; alignItems: center">
                  `+ res["data"]["shop_wizard"].vehicle_detail.year + ` ` + res["data"]["shop_wizard"].vehicle_detail.color + ` ` + res["data"]["shop_wizard"].vehicle_detail.car_name + ` ` + res["data"]["shop_wizard"].vehicle_detail.model + `
                </div>
                <div style=" width: 100%; display: flex; justifyContent: end; alignItems: center">
                  <span style=" fontWeight: 600">VIN:</span>&nbsp; `+ res["data"]["shop_wizard"].customer_detail.vin_number + `
                </div>
              </div>
            </div>
            <div style=" width: 100%; padding: 15px 10px 0px 10px; color: #000" >
              <div style=" border: 1px solid grey; backgroundColor: #ddd; height: 30px; width: 100%"></div>
              <table style="width: 100%">
                <thead style="border: 1px solid grey; fontSize: 12px" >
                  <th style=" padding: "7px 0px"  ><center>#</center></th>
                  <th ><center>Description</center></th>
                  <th ><center>Price</center></th>
                  <th ><center>Tax</center></th>
                  <th ><center>Sub total</center></th>
                </thead>
                <tbody>
                  `+
          res["data"]["shop_wizard"]?.item_detail?.map((val, index) => {
            return (
              `<tr style=" border: 1px solid grey; fontSize: 13px; padding: 5px 0px">
                          <td style=" padding: 5px 0px"><center>`+ index + 1 + `</center></td>
                          <td><center>`+ val.item_name + `</center></td>
                          <td><center>`+ val.amount + `</center></td>
                          <td><center>`+ val.tax + `</center></td>
                          <td><center>`+ parseInt(val.amount) + parseInt(val.tax) + `</center></td>
                        </tr>`
            )
          }
          ) +
          `
                </tbody>
              </table>
            </div>
            <div style=" width: 100%; padding: 0px 10px 0px 10px; display: flex; justifyContent: end; color: #000" >
              <div style="width: 50%; padding: 0px 0px; fontSize: 12px; fontWeight: 600">
                <div style="width: 100%; height: 30px; justifyContent: space-between; padding: 0px 51px 0px 8px; border: 1px solid grey; display: flex; alignItems: center; backgroundColor: #fff">
                  <span style="fontWeight: 550; fontSize: 11px">TOTAL PRICE</span><span>`+ res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.amount ? v.amount : 0)).reduce((t, v) => (t + v)) + `</span>
                </div>
                <div style="width: 100%; height: 30px; justifyContent: space-between; padding: 0px 51px 0px 8px; border: 1px solid grey; display: flex; alignItems: center; backgroundColor: #fff">
                  <span style="fontWeight: 550; fontSize: 11px">TOTAL TAX</span><span>`+ res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.tax ? v.tax : 0)).reduce((t, v) => (t + v)) + `</span>
                </div>
                <div style="width: 100%; height: 30px; justifyContent: space-between; padding: 0px 51px 0px 8px; border: 1px solid grey; display: flex; alignItems: center; backgroundColor: #fff">
                  <span style="fontWeight: 550; fontSize: 11px">TOTAL AMOUNT</span><span>`+ parseInt(res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.amount ? v.amount : 0)).reduce((t, v) => (t + v))) + parseInt(res["data"]["shop_wizard"].item_detail.map(v => parseInt(v.tax ? v.tax : 0)).reduce((t, v) => (t + v))) + `</span>
                </div>
              </div>
            </div>
            <div style=" width: 100%; padding: 15px 10px 0px 10px; display: flex; fontSize: 11px; justifyContent: start; color: #000" >
              Thank you for your business, Your payment is due upon vehicle pickup.
            </div>
            <div style="width: 100%; padding: 10px 10px 0px 10px; display: flex; fontSize: 13px; justifyContent: start; color: #000">
              <strong>Signature____________________</strong>
            </div>
          </div>`

        console.log(html);
        sendInvoiceEmail(email, id, html)
          .then(res => {
            // console.log("sent email", res["data"])
            if (res["data"]["shop_wizard"]["status"] == "1") {
              setLoad(false);
              notifySuccess("Email sent successfully")
            } else {
              setLoad(false);
              notifyError(res["data"]["shop_wizard"]["msg"])
            }
          })
      }
    })

  }

  const thead_padding = matches_545 ? { paddingRight: "5px", paddingLeft: "5px" } : {};


  return (
    <>
      <Loader load={load} />
      <section className="estimate__section">
        <Navbar heading='Estimate List' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80%" }}>
            <div className="custom-table_container">
              <div className="">
                <div class="input-group col-md-12 search-bar_container py-3" >
                  <input class="form-control py-2 border-right-0 border" type="search" onChange={e => {
                    setQuery(e.target.value)
                  }} placeholder="Search..." id="example-search-input" />
                  <span class="input-group-append">
                    <button class="btn btn-outline-secondary border-left-0 border" type="button">
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                  <span class="input-group-append">
                    <button onClick={
                      e => {
                        e.preventDefault()
                        localStorage.removeItem(
                          "addEstimateData"
                        );
                        history.push("/new-estimate")
                      }
                    } class="btn btn-outline-secondary border-left-0 border" type="button">
                      <i class="fa fa-plus"></i>
                    </button>
                  </span>
                </div>
              </div>
              <div style={{ width: "100%", padding: "0px 12px", height: "80vh", overflowY: "auto" }}>

                <table style={{ width: "100%" }}>
                  <thead style={{ borderBottom: "1px solid #c9c9c9", borderTop: "1px solid #c9c9c9", fontSize: matches_545 ? "14px" : "" }}>
                    <th style={{ padding: "3px", ...thead_padding }} className="text-uppercase">#</th>
                    <th className="text-uppercase" style={thead_padding} >Name</th>
                    <th className="text-uppercase" style={thead_padding} >VIN No.</th>
                    <th className="text-uppercase" style={thead_padding} >Amount</th>
                    {/* <th className="text-uppercase">Status</th> */}
                    <th style={{ textAlign: "center", ...thead_padding }} className="text-uppercase">Action</th>
                  </thead>
                  <tbody>
                    {showData.map((val, index) => <tr style={{ background: ((index % 2) == 0) ? "#fff" : "#f5f5f5", fontSize: matches_545 ? "14px" : "16px" }} >
                      <td style={{ padding: "3px", ...thead_padding }}>{val.invoice_id}</td>
                      <td style={thead_padding} >{val.name} </td>
                      <td style={thead_padding} >{val.vin_number} </td>
                      <td style={thead_padding} >{val.total_amount} </td>
                      {/* <td className="text-uppercase pending-data">{val.status === "0" ? "Pending" : "Paid"}</td> */}
                      <td className="cursor-pointer" style={thead_padding} onClick={e => {

                      }}>
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <img src={edit_img} className="mx-1" onClick={() => {
                            console.log(val);
                            localStorage.removeItem("estimateEdit")
                            localStorage.setItem("estimateEdit", JSON.stringify({ name: val.name, invoice: val.invoice_id, total: val.total_amount, vin: val.vin_number, reg: val.reg_number, customer: val.customer_id }))
                            history.push("/edit-estimate/" + val.invoice_id)
                          }} style={{ height: "16px", display: val.status != "1" ? "block" : "none", cursor: "pointer" }} />
                          <img src={mail} onClick={
                            () => {
                              // console.log("data", val)
                              // getInvoiceData(val.invoice_id,val.vin_number,val.customer_id,val.email);
                              console.log(val.invoice_id, val.vin_number, val.customer_id, val.email, val.date_time);
                              emailHTML(val.invoice_id, val.vin_number, val.customer_id, val.email, val.date_time);
                            }
                          } className="mx-1" style={{ height: "16px", cursor: "pointer" }} />
                          <img src={print_img} className="mx-1" onClick={e => {
                            // setVendorDetail("")
                            // setCustomerDetail("")
                            // setItemDetail([])setVendorDetail("")
                            console.log({ selected, props });
                            setItemDetail([])
                            setCustomerDetail([])
                            setVendorDetail([])
                            setSelected('');
                            setvehicle_detail({});
                            setinv_date(val.date_time.slice(0,10))

                            // setnotes_arr([]);
                            // setpolicy_arr([]);
                            setTimeout(() => {
                              setSelected(val.invoice_id)
                            }, 50);
                          }} style={{ height: "16px", cursor: "pointer" }} />
                        </div>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
            {/*<img
                src={add}
                alt=""
                className="img-fluid customer_plus__button"
              />*/}
          </div>
        </div>
      </section>
      <div className="d-none">
        <BillPage
          inv_date={inv_date}
          notes_arr={notes_arr}
          policy_arr={policy_arr}
          ref={componentRef}
          invoiceNumber={selected}
          itemDetail={itemDetail}
          customerDetail={customerDetail}
          vendorDetail={vendorDetail}
          vehicle_detail={vehicle_detail}
        />
      </div>
    </>
  );
}

export default Estimate;
