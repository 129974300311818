import React, { useRef } from 'react'
import { Component } from 'react';
// import ReactToPrint from "react-to-print";
import { useReactToPrint } from 'react-to-print';

import Logo from "../assets/sw_logo.png";
import './billPage.css';


class BillPage extends Component {
    render() {
        let { inv_date, policy_arr, customerDetail, itemDetail, vendorDetail, invoiceNumber, vehicle_detail, notes_arr } = this.props;
        console.log({ props: this.props, vehicle_detail });
        return (
            <div className="bill-page" style={{ paddingTop: "30px" }} >
                <div className="bill-container d-flex flex-wrap">
                    <div className="col-6" style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <img src={vendorDetail.logo_url}
                            style={{ width: "150px", height: "150px", objectFit: "cover" }} />
                        <div className=" text-left company-details" style={{ marginLeft: "10px" }} >
                            <h4>{vendorDetail.name}</h4>
                            <h5>{vendorDetail.address}</h5>
                            {/* <h5>katy, TX 77449</h5> */}
                            <h5>{vendorDetail.contact}</h5>
                            <h5>{vendorDetail.email}</h5>
                            {/* <h5>www.excelautostyling.com</h5> */}
                        </div>
                    </div>

                    <div className="invoice col-6 text-right">
                        <h1><span style={{ fontSize: "30px" }}>INVOICE#</span> {invoiceNumber}</h1>
                        <h5>{inv_date}</h5>
                        <span style={{ fontSize: "40px", fontWeight: "600", color: JSON.stringify(customerDetail.invoice_status)?.toLowerCase()?.includes('paid') ? "green" : "red" }}>
                            {customerDetail.invoice_status}</span>
                        {/* <h5>Invoiced:8/4/2020</h5> */}
                        {/*<h5>service Writer: {customerDetail.name}</h5>*/}
                    </div>
                </div>
                <div className="d-flex mt-3 mb-2">
                    <div className="col-6 text-left company-details mb-2" style={{ lineHeight: "25px" }} >
                        {/*<h4>Customer:</h4>
        <h4>{customerDetail.name+(customerDetail.contact)}</h4>*/}
                        <b style={{ fontSize: "20px" }}>Customer:</b><br />
                        <span style={{ fontSize: "20px" }}>{customerDetail.name + " (" + (customerDetail.contact) + ")"}</span>
                    </div>
                    <div className="col-6  company-details mb-2" style={{
                        lineHeight: "25px", textAlign: "end"
                    }} >
                        <span style={{ fontSize: "20px" }}>{vehicle_detail?.year + " " + vehicle_detail?.color + " " + vehicle_detail?.car_name + " " + vehicle_detail?.model}</span><br />
                        <span style={{ fontSize: "20px" }}><span style={{ fontWeight: "600" }}>VIN:</span>&nbsp;{customerDetail.vin_number}</span>
                    </div>
                </div>
                {/*<div className="pl-4 py-1" style={{ background: "#ddd", height: "25px", border: "1px solid grey" }}></div>*/}
                <div style={{ position: "relative", width: "100%" }}>
                    <table style={{ width: "100%", border: "1px solid grey" }}>
                        <thead style={{ border: "1px solid grey", backgroundColor: "#ddd", fontSize: "16px" }} >
                            <th style={{ padding: "7px 0px" }} ><center>#</center></th>
                            <th ><center>DESCRIPTION</center></th>
                            <th style={{ textAlign: "end", paddingRight: "30px" }}>PRICE</th>
                            <th style={{ textAlign: "end", paddingRight: "30px" }}>TAX</th>
                            <th style={{ textAlign: "end", paddingRight: "48px" }}>SUB TOTAL</th>
                        </thead>
                        <tbody>
                            {
                                itemDetail.map((val, index) => (
                                    <tr className="custom-table" style={{ border: "1px solid grey" }}>
                                        <td style={{ fontSize: "16px" }}>{index + 1}</td>
                                        <td style={{ fontSize: "16px" }}>{val.item_name}</td>
                                        <td style={{ fontSize: "16px", textAlign: "end", paddingRight: "30px" }}>{val.amount}</td>
                                        <td style={{ fontSize: "16px", textAlign: "end", paddingRight: "30px" }}>{val.tax}</td>
                                        <td style={{ fontSize: "16px", textAlign: "end", paddingRight: "50px" }}>{val.sub_total}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div style={{
                        width: "450px", fontSize: "12px", fontWeight: "600", position: "absolute", right: "10px"
                        , bottom: "-90px"
                    }}>
                        <div style={{ width: "100%", marginLeft: "10px", height: "30px", justifyContent: "space-between", padding: "0px 51px 0px 8px", border: "1px solid grey", display: "flex", alignItems: "center", backgroundColor: "#fff" }}>
                            <span style={{ fontWeight: "550", fontSize: "12px" }}>TOTAL PRICE</span><span style={{ fontSize: "12px" }}>{parseFloat(customerDetail.total_amount).toFixed(2)}</span>
                        </div>
                        <div style={{ width: "100%", marginLeft: "10px", height: "30px", justifyContent: "space-between", padding: "0px 51px 0px 8px", border: "1px solid grey", display: "flex", alignItems: "center", backgroundColor: "#fff" }}>
                            <span style={{ fontWeight: "550", fontSize: "12px" }}>TOTAL TAX</span><span style={{ fontSize: "12px" }}>{parseFloat(customerDetail.total_tax).toFixed(2)}</span>
                        </div>
                        <div style={{ width: "100%", marginLeft: "10px", height: "30px", justifyContent: "space-between", padding: "0px 51px 0px 8px", border: "1px solid grey", display: "flex", alignItems: "center", backgroundColor: "#ddd" }}>
                            <span style={{ fontWeight: "550", fontSize: "12px" }}>TOTAL AMOUNT</span><span style={{ fontSize: "12px" }}>{(parseFloat(customerDetail.total_price) + parseFloat(customerDetail.total_tax)).toFixed(2)}</span>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", marginTop: "-15px", display: "flex", justifyContent: "justify-between", alignItems: "center" }}>
                    <div style={{ width: "49%", marginTop: "25px", paddingLeft: "10px" }}>
                        {
                            (notes_arr && notes_arr.length > 0) &&
                            <div style={{ fontSize: "14px" }}>
                                <span style={{ fontSize: "18px" }}>Notes :-</span>
                                <ul class="dotted">
                                    {
                                        notes_arr.map((el) => {
                                            return (
                                                <li>{el.notes}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            (policy_arr && policy_arr.length > 0) &&
                            <div style={{ fontSize: "14px", marginTop: "15px" }}>
                                <span style={{ fontSize: "18px" }}>Terms & conditions :-</span>
                                <ul class="dotted">
                                    {
                                        policy_arr.map((el) => {
                                            return (
                                                <li>{el.policy}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        <div className="d-flex align-items-end">
                            <h5 className="col-3">Signature</h5>
                            <div className="col-9" style={{ height: "1px", background: "black" }} />
                        </div>
                    </div>

                </div >
                <div className="d-flex mt-5">
                    {/*<div className="col-7">
                        <div style={{ fontSize: "14px", marginTop: "-25px" }}>
                            <span style={{ fontSize: "18px" }}>Notes :-</span>
                            <ul class="dotted">
                                <li>fdh</li>
                                <li>fdh</li>
                            </ul>
                        </div>
                        <p>Thank you for your business. Your Payment is due upon vehicle pickup</p>
                        <div className="d-flex align-items-end">
                            <h5 className="col-3">Signature</h5>
                            <div className="col-9" style={{ height: "1px", background: "black" }} />
                        </div>
                    </div>*/}
                    {/*<div className="col-5">
                        <div style={{ border: "1px solid rgba(0,0,0,.2)" }}>
                            <h5 className="p-3">
                                <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Sub total</span>
                                    <span>{customerDetail.total_amount}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Tax</span>
                                    <span>{customerDetail.total_tax}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-end" style={{ borderBottom: "1px solid #ddd" }}>
                                    <span>Grand Total</span>
                                    <span>{customerDetail.grand_total}</span>
                                </div>
                            </h5>
                            <div className="d-flex justify-content-between px-3 py-1" style={{ background: "#ddd" }}>
                                <h6 className="font-weight-bold mb-0">Total Amount</h6>
                                <h6 className="font-weight-bold mb-0">{customerDetail.grand_total}</h6>
                            </div>
                        </div>
                    </div>*/}

                </div>
            </div >
            // </section>
        )
    }
}

// const Example = () => {
//     const componentRef = useRef();
//     const handlePrint = useReactToPrint({
//         content: () => componentRef.current,
//       });
//     return (
//         <div>
//             <div className=""><BillPage ref={componentRef} /></div>
//             <button onClick={handlePrint} style={{zIndex:100,position:"absolute",bottom:"0",left:"0"}}>Print this out!</button>
//         </div>
//     );
// };

export default BillPage
