import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import back from "../assets/back.png";
import hamburger from "../assets/filter.png";
import { getAllInvoice, get_invoice_summary_api, printInvoice, sendInvoiceEmail } from "../services/httpService";
import { filter_invoice_summary, notifyError, notifySuccess } from "../util/Util";
import BillPage from "./billPage";
import "./Estimate2.css"
import Loader from "./Loader";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import print from "../assets/print.png";
import pay from "../assets/pay.png";
import mail from "../assets/mail.png";

import "react-datepicker/dist/react-datepicker.css";
import Ncontext from "../ContextC";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";
import "./invoice_summary.css"

function Invoice_Summary(props) {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const matches_820 = useMediaQuery('(max-width:820px)');
    const matches_545 = useMediaQuery('(max-width:545px)');
    const context = useContext(Ncontext);
    const { name, setname, customerDetailbill, setCustomerDetailbill, InvoiceNumbill, setInvoiceNumbill, itemDetailbill, setItemDetailbill, vendorDetailbill, setVendorDetailbill } = context;


    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to the month because it is 0-based
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const [InvoiceData, setInvoiceData] = useState([])
    const [itemDetail, setItemDetail] = useState([])
    const [customerDetail, setCustomerDetail] = useState("")
    const [vendorDetail, setVendorDetail] = useState("")
    const [load, setLoad] = useState(false)
    const [selected, setSelected] = useState("")
    const [showData, setShowData] = useState([]);
    const [query, setQuery] = useState(name);
    const [contQuery, setContQuery] = useState("");
    const ventor_id = localStorage.getItem("vendor_id");
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [status, setstatus] = useState('');
    const [fromdate, setfromdate] = useState(formatDate(new Date()));
    const [todate, settodate] = useState(formatDate(new Date()));
    const [bool, setbool] = useState(false);
    const [summary_arr, setsummary_arr] = useState([]);
    const [from_date_type, setfrom_date_type] = useState('text');
    const [to_date_type, setto_date_type] = useState('text');
    const [from_date, setfrom_date] = useState(formatDate(new Date()));
    const [to_date, setto_date] = useState(formatDate(new Date()));
    const [isOpen, setIsOpen] = useState(false);

    const openDialog = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleChange = (event) => {
        // setAge(event.target.value);
        setstatus(event.target.value)
    };

    const get_invoice_summary = () => {
        setLoad(true)
        get_invoice_summary_api({
            "from_date": from_date,
            "to_date": to_date,
            "vendor_id": ventor_id
        })
            .then((res) => {
                setLoad(false)
                if (res["data"]["shop_wizard"]["status"] = 1) {
                    setsummary_arr(res["data"]["shop_wizard"]["items"])
                }
                else {
                    setsummary_arr([])
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        // alert(name);

        get_invoice_summary()
        return () => { };
    }, []);

    const vehicle_arr = summary_arr && summary_arr.length > 0 ?
        summary_arr.map((el) => {
            return el.vehicle
        }) : [];

    const thead_padding = matches_545 ? { paddingRight: "5px", paddingLeft: "5px" } : {};

    return (
        <>
            <Loader load={load} />
            <section className="estimate__section">
                <Navbar heading='Invoice Statement' />
                <div style={{ display: "flex" }}>
                    {
                        !matches_820 &&
                        <div style={{ width: "19%" }}>
                            <SideBar />
                        </div>
                    }

                    <div style={{ width: matches_820 ? "100%" : "80%" }}>
                        <div style={{
                            width: "100%",
                            padding: "8px 14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            borderTop: "2px solid #c9c9c9",
                            borderBottom: "2px solid #c9c9c9"
                        }}>
                            <button className="btn btn-outline-secondary"
                                onClick={() => {
                                    openDialog()
                                    // filter_invoice_summary(from_date,setfrom_date,to_date,setto_date,get_invoice_summary)
                                }}
                            >
                                <i class="fa fa-filter"></i>
                            </button>
                            &nbsp;
                            &nbsp;
                            <button className="btn btn-outline-secondary"
                                onClick={() => {
                                    handlePrint();
                                }}
                            >
                                <i class="fa fa-print"></i>
                            </button>

                        </div>
                        <div ref={componentRef} className="w-full">
                            <div style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#000",
                                // padding: "10px 0px",
                                marginTop: "20px",
                                paddingRight: "20px"
                            }}>
                                <div style={{ width: matches_545?"60%":"79%", lineHeight: "20px", paddingLeft: "20px", borderRight: "1px solid #696969" }}>
                                    <span style={{ fontWeight: "500", fontSize: matches_545?"18px":"28px" }}>Invoice Summary Statement</span>
                                    <h5 style={{ fontWeight: "400",fontSize:matches_545?"14px":"" }}>{from_date + " ~ " + to_date}</h5>

                                </div>
                                <div style={{ width: matches_545 ? "39%" : "20%", lineHeight: "20px", textAlign: "end" }}>
                                    <span style={{ fontWeight: "500", fontSize: matches_545 ? "16px" : "22px" }}>
                                        Grand Total:&nbsp;
                                        {summary_arr && summary_arr.length > 0 && summary_arr.reduce((accumulator, currentValue) => {
                                            return accumulator + parseFloat(currentValue.total);
                                        }, 0).toFixed(2)}
                                    </span>
                                    <h5 style={{ fontWeight: "400", fontSize: matches_545 ? "13px" : "" }}>
                                        {(vehicle_arr.length + " Orders, " + (vehicle_arr.length > 0 ? [...new Set(vehicle_arr)].length : 0) + " Vehicles")}
                                    </h5>

                                </div>
                            </div>


                            <div className="custom-table_container" style={{ position: "relative", marginTop: "20px" }}>


                                <div style={{ width: "100%", padding: "0px 12px", height: "80vh", overflowY: "auto" }}>

                                    <table style={{ width: "100%" }}>
                                        <thead style={{ borderBottom: "1px solid #c9c9c9", borderTop: "1px solid #c9c9c9", fontSize: matches_545 ? "14px" : "" }}>
                                            <th className="text-uppercase" style={{ padding: "3px 5px", ...thead_padding }}>#</th>
                                            <th className="text-uppercase" style={thead_padding} >{matches_545 ? 'Name' : 'Order Name'}</th>
                                            <th className="text-uppercase" style={thead_padding}>Vehicle</th>
                                            <th className="text-uppercase" style={thead_padding}>Invoiced</th>
                                            <th className="text-uppercase" style={thead_padding}>Total</th>
                                            <th className="text-uppercase" style={thead_padding}>Paid</th>
                                            <th className="text-uppercase" style={{ width: "92px", ...thead_padding }}>{matches_545 ? 'Due' : 'Total Due'}</th>
                                        </thead>
                                        {
                                            summary_arr && summary_arr.length > 0 &&
                                            <tbody>
                                                {
                                                    summary_arr.map((el, index) => {
                                                        return (
                                                            <tr style={{ background: ((index % 2) == 0) ? "#fff" : "#f5f5f5", fontSize: matches_545 ? "14px" : "" }} >
                                                                <td style={{ padding: "3px", cursor: "pointer", ...thead_padding }}
                                                                    id={"sum_" + index}
                                                                    onMouseOver={() => {
                                                                        document.querySelector("#sum_" + index).style.textDecoration = "underline"
                                                                    }}
                                                                    onMouseOut={() => {
                                                                        document.querySelector("#sum_" + index).style.textDecoration = "none"
                                                                    }}
                                                                    onClick={() => {
                                                                        const vehicle = el.vehicle.split(" ")[0];
                                                                        history.push(`/invoice-detail/${el.order_id}/${vehicle}/${el.customer_id}/${el.invoiced}`);
                                                                    }}
                                                                > {el.order_id}</td>
                                                                <td style={thead_padding}>{el.order_name}</td>
                                                                <td style={thead_padding}>{el.vehicle}</td>
                                                                <td style={thead_padding}>{el.invoiced}</td>
                                                                <td style={thead_padding}>{parseFloat(el.total).toFixed(2)}</td>
                                                                <td style={thead_padding}>{parseFloat(el.paid).toFixed(2)}</td>
                                                                <td style={{ textAlign: "end", ...thead_padding }}>{(parseFloat(el.total) - parseFloat(el.paid)).toFixed(2)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        }
                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {isOpen && (
                <div className="dialog">
                    <div className="dialog-content">
                        <span onClick={closeDialog} className="close-button">
                            &times;
                        </span>
                        <div style={{
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#fff"
                        }}>
                            <div style={{ borderBottom: "1px solid #c9c9c9", textAlign: "center" }}>
                                <h5>Choose statement date</h5>
                            </div>
                            <div style={{ marginTop: "8px", width: "400px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <input
                                    type={'date'}
                                    className="form-control"
                                    style={{ width: "150px" }}
                                    aria-label="Username"
                                    placeholder="From date"
                                    aria-describedby="basic-addon1"
                                    // autoFocus={true}
                                    // style={{ color: "#C4C4C4" }}
                                    id="date_input"
                                    name="date"
                                    value={from_date}
                                    // onFocus={() => {
                                    //     setfrom_date_type('date')
                                    // }}
                                    //   value={date}
                                    onChange={(event) => {
                                        setfrom_date(event.target.value)
                                        console.log(event.target.value);
                                    }}
                                />
                                <input
                                    type={'date'}
                                    className="form-control"
                                    style={{ width: "150px", marginLeft: "20px" }}
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    // autoFocus={true}
                                    // style={{ color: "#C4C4C4" }}
                                    placeholder="To date"
                                    id="date_input"
                                    name="date"
                                    value={to_date}
                                    // onFocus={() => {
                                    //     setto_date_type('date')
                                    // }}
                                    onChange={(event) => {
                                        setto_date(event.target.value)
                                        // console.log(event.target.value);
                                    }}
                                />
                            </div>
                            <div style={{ width: "400px", marginTop: "10px", display: "flex", paddingRight: "40px", alignItems: "center", justifyContent: "end" }}>
                                <button className="btn btn-primary"
                                    onClick={() => {
                                        get_invoice_summary();
                                        closeDialog()
                                    }}
                                >Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    );
}

export default Invoice_Summary;
