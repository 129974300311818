import React, { useContext, useState } from "react";
import Password from "../assets/password.png";
import "./Customer.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import down from "../assets/down.png";
import add from "../assets/add.png";
import { useEffect } from "react";
import { getCustomerDetail, deleteCustomer, updateCustomerDetail, updateCustomerVehicle } from "../services/httpService";
import Loader from "./Loader";
import { showConfirmDelete } from "../util/Util";
import { confirmAlert } from "react-confirm-alert";
import Ncontext from "../ContextC";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";


function Customer(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const context = useContext(Ncontext);
  const { setname } = context;
  const history = useHistory();
  const [customerData, setCustomerData] = useState({});
  const [load, setLoad] = useState(false);
  const [updatedData, setUpdatedData] = useState(null);
  const ventor_id = localStorage.getItem("vendor_id");
  useEffect(() => {
    setLoad(true);
    getCustomerDetail(props.match.params.id, ventor_id).then((res) => {
      if (res["data"]["shop_wizard"]["status"] === "1") {
        setLoad(false);
        setCustomerData(res["data"]["shop_wizard"]["customer"]);
        console.log(res["data"]["shop_wizard"]["customer"]);
      }
    });
    return () => { };
  }, []);
  // console.log("customerData.vehicle", customerData.vehicle);
  return (
    <>
      <Loader load={load} />
      <section className="customer__section">
      <Navbar heading='Customer Details' />
        <div style={{display:"flex"}}>
        {
          !matches_820 &&
          <div style={{ width: "19%" }}>
            <SideBar />
          </div>
        }
        <div style={{width:matches_820 ? "100%" : "80.9%"}}>
        <div className="profile__content container-fluid">
          <div
            className="row"
            style={{
              // borderTopLeftRadius: "30px",
              // borderTopLeftRadius: "30px",
              // borderTopRightRadius: "30px",
              overflow: "hidden",
            }}
          >
            <div className="col-12 p-0">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 profile__content_col border-0 d-flex align-items-center justify-content-center">
                      <h3 className="text-dark">
                        {customerData.customer_name}
                      </h3>
                    </div>
                  </div>
                  <div className="row mt-4 px-3">
                    <div className="col-6 p-0 profile__content_col">
                      <small className="font-weight-bold text-dark">
                        Email:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col">
                      <small className="profile__content__right">
                        {customerData.email}
                      </small>
                    </div>
                  </div>
                  <div className="row mt-4 px-3">
                    <div className="col-6 p-0 profile__content_col">
                      <small className="font-weight-bold text-dark">
                        Phone Number:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col">
                      <small className="profile__content__right">
                        {customerData.contact}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 mt-4 mb-1">
                    <div className="card dashboard__card">
                      <div className="card-body pt-2 pb-3 p-1 px-4">
                        <Link onClick={() => {
                          setname(customerData.customer_name);
                        }} to="/invoice">
                          <p className="mt-2 font-weight-bold mb-0" style={{ fontSize: "20px" }}>
                            Invoice
                          </p>
                        </Link>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <p className="mt-2 mb-0">
                            <span style={{ color: '#777', fontWeight: "600" }}> Total Invoice:  </span> <strong>{customerData.todays_invoice}</strong>
                          </p>
                          <p className="mt-2 mb-0">
                            <span className="sales_summry" style={{ color: '#777', fontWeight: "600" }}> Total Paid:</span> <strong> {customerData.total_paid_amount}</strong>
                          </p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <p className="mt-2 mb-0">
                            <span className="sales_summry" style={{ color: '#777', fontWeight: "600" }}>Total Amount:  </span><strong>{customerData.total_invoice_amount}</strong>
                          </p>
                          <p className="mt-2 mb-0">
                            <span className="sales_summry" style={{ color: '#777', fontWeight: "600" }}>  Total Balance:</span><strong> {customerData.balance_amount}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 px-3">
                    <div className="col-6 p-0 profile__content_col border-0">
                      <small className="font-weight-bold text-dark">
                        Vehicles:
                      </small>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-end profile__content_col border-0">
                      {/* <small className="profile__content__right">
                      {customerData.contact}
                    </small> */}
                    </div>
                  </div>
                  {customerData?.vehicle?.map(function (data) {
                    return (
                      <div
                        className="col-12 p-0 mt-2"
                        onClick={(e) => {
                          console.log(customerData);
                          localStorage.removeItem("addEstimateData");
                          localStorage.setItem(
                            "addEstimateData",
                            JSON.stringify({
                              vin: data.vin_number,
                              reg: data.reg_number,
                              customer_id: customerData.customer_id,
                              name: customerData.customer_name,
                            })
                          );
                          // history.push("/estimate");
                        }}
                      >
                        <div className="card customer__card">
                          <div className="card-body customer__cardBody px-0">
                            <div className="row mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  VIN No.:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.vin_number}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  Registration No:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.reg_number}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  Car Name:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.car_name}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  Model:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.model}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0">
                            <div className="col-6 ">
                              <small className="font-weight-bold text-dark">
                                Color:
                              </small>
                            </div>
                            <div className="col-6 d-flex justify-content-end ">
                              <small className="profile__content__right">
                                {data.color}
                              </small>
                            </div>
                          </div>
                            <div className="row mt-1 mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  Year:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.year}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  Fuel Type:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.fule_type}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0">
                              <div className="col-6 ">
                                <small className="font-weight-bold text-dark">
                                  State:
                                </small>
                              </div>
                              <div className="col-6 d-flex justify-content-end ">
                                <small className="profile__content__right">
                                  {data.state}
                                </small>
                              </div>
                            </div>
                            <div className="row mt-1 mx-0 d-flex">
                              <div className="col-6">
                                <button
                                  className="btn text-light py-1 px-5 ml-5 mr-3"
                                  style={{ background: "rgb(16,80,230)" }}
                                  onClick={() => {
                                    setUpdatedData({
                                      vin_number: data.vin_number,
                                      reg_number: data.reg_number
                                    })
                                    confirmAlert({
                                      // title: 'Error',
                                      // message: text,
                                      customUI: ({ onClose }) => {

                                        return (
                                          <div
                                            className="custom-ui d-flex flex-column align-items-start"
                                            style={{
                                              width: "300px",
                                              background: "white",
                                              padding: "20px",
                                              border: "1px solid #ebebeb",
                                              color: "#4f4f4f",
                                              borderRadius: "10px",
                                            }}
                                          >
                                            {/* <h3 className="text-danger">Error</h3> */}
                                            {/* <small className="font-weight-bold mb-4" style={{ color: "black", fontSize: "0.85em" }}>{"Are you sure you want to generate invoice"}</small> */}
                                            <div className="form-group mb-0 w-100">
                                              <label
                                                htmlFor="exampleInputEmail1"
                                                className="ml-3"
                                              >
                                                VIN No.
                                              </label>
                                              <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <span
                                                    className="input-group-text login__span px-2"
                                                    id="basic-addon1"
                                                  >
                                                    <img
                                                      src={Password}
                                                      alt=""
                                                      className="img-fluid login__img email_img"
                                                    />
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control login__input"
                                                  placeholder=""
                                                  aria-label="Username"
                                                  aria-describedby="basic-addon1"
                                                  name="email"
                                                  defaultValue={data.vin_number}
                                                  onChange={(e) =>
                                                    setUpdatedData(v => {
                                                      console.log(v)
                                                      return {
                                                        ...v,
                                                        vin_number: e.target.value
                                                      }
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group w-100">
                                              <label
                                                htmlFor="exampleInputEmail1"
                                                className="ml-3"
                                              >
                                                Registration No.
                                              </label>
                                              <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <span
                                                    className="input-group-text login__span px-2"
                                                    id="basic-addon1"
                                                  >
                                                    <img
                                                      src={Password}
                                                      alt=""
                                                      className="img-fluid login__img email_img"
                                                    />
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control login__input"
                                                  placeholder=""
                                                  aria-label="Username"
                                                  aria-describedby="basic-addon1"
                                                  name="email"
                                                  defaultValue={data.reg_number}
                                                  onChange={(e) =>
                                                    setUpdatedData(v => {
                                                      return {
                                                        ...v,
                                                        reg_number: e.target.value
                                                      }
                                                    }
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between w-100">
                                              <button
                                                className="btn alertBtn"
                                                style={{
                                                  color: "#1658e6",
                                                  background: "#ddd",
                                                }}
                                                onClick={onClose}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                className="btn btn-primary alertBtn"
                                                style={{
                                                  background: "#1658e6",
                                                  color: "white",
                                                }}
                                                onClick={(e) => {
                                                  setUpdatedData(v => {
                                                    updateCustomerVehicle(
                                                      data.vehicle_id, v.vin_number == "" ? "-" : v.vin_number, v.reg_number
                                                    ).then(res => {
                                                      onClose()
                                                      getCustomerDetail(props.match.params.id).then((res) => {
                                                        if (res["data"]["shop_wizard"]["status"] === "1") {
                                                          setLoad(false);
                                                          setCustomerData(res["data"]["shop_wizard"]["customer"]);
                                                        }
                                                      });
                                                    })
                                                    console.log(v)
                                                    return v
                                                  })
                                                }}
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      },
                                    });
                                  }}
                                >
                                  Edit
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  className="btn text-light py-1 px-3 mx-3"
                                  style={{ background: "rgb(16,80,230)" }}
                                  onClick={
                                    () => {
                                      history.push("/new-cust-estimate")
                                    }
                                  }
                                >
                                  New Estimate
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </section>
    </>
  );
}

export default Customer;
