import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import Load from '../Loader'
import { Link, useHistory } from "react-router-dom";
import { notifyError, notifySuccess, showConfirm_cancelAlert } from '../../util/Util';


const CheckoutForm = ({ setLoad, load_2 }) => {
    // const [btn_flag, setbtn_flag] = useState(initialState)
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    // console.log(elements);

    const [errorMessage, setErrorMessage] = useState(null);
    function addOneMonth(date) {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
        return `${year}-${month}-${day}`;
    }

    // Convert milliseconds to a Date object
    const milliseconds = Date.now();
    const date = new Date(milliseconds);

    // Add one month to the date
    const newDate = addOneMonth(date);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoad(true);
        if (elements == null) {
            setLoad(false);
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError.message);
            setLoad(false);
            return;
        }

        // Create the PaymentIntent and obtain clientSecret from your server endpoint


        const payment_resp = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // return_url: 'http://192.168.29.136:3000/complete',
                return_url: 'http://35.159.9.64/shopwizard/#/dashboard'
            },
            redirect: 'if_required'
        });
        console.log(payment_resp);


        setLoad(false);
        if (payment_resp.paymentIntent.status == 'succeeded') {
            const subscription_till = formatDate(newDate);

            const sub_result = await fetch('https://demoapps.in/index.php/Shop_controller/subscribe_result', {
                method: 'POST',
                body: JSON.stringify({
                    shop_wizard: {
                        email: localStorage.getItem('vendor_reg_email'), subscription_till, customer_id: localStorage.getItem('customer_id'), susbscription_id: localStorage.getItem('subscription_id'), vendor_id: localStorage.getItem("vendor_id"), stripe_txn_id: payment_resp.paymentIntent.id, amount: payment_resp.paymentIntent.amount, txn_status: payment_resp.paymentIntent.status == 'succeeded' ? 1 : 0
                    }
                })
            });
            fetch('https://demoapps.in/index.php/Shop_controller/update_subscribe_date', {
                method: 'POST',
                body: JSON.stringify({
                    shop_wizard: {
                        is_trial: 0, subscription_till, vendor_id: localStorage.getItem("vendor_id")
                    }
                })
            }).then(async (res) => {
                const resp = await res.json();
                localStorage.setItem('subscription_data', JSON.stringify({
                    sub_id: resp["shop_wizard"]["susbscription_id"],
                    valid_till: subscription_till
                }))
                // history.push("/dashboard");
                let string_data = await sub_result.text();
                const parsed_data = JSON.parse(string_data);
                const { shop_wizard } = parsed_data;
                const { msg } = shop_wizard

                // console.log(sub_result);
                notifySuccess(msg);
                localStorage.setItem('subscription_data', JSON.stringify({
                    sub_id: localStorage.getItem('subscription_id'),
                    valid_till: subscription_till
                }))
                localStorage.removeItem('subscription_id');
                localStorage.removeItem('customer_id');
                localStorage.removeItem('vendor_name');
                localStorage.removeItem('vendor_data');
                localStorage.removeItem('vendor_reg_email');
                localStorage.setItem('subscription_cancelled', 0)
                history.push('/dashboard')
            }).catch((err) => {
                // console.log(err);
                // notifyError('Some error occured')
            });

        }
        else {
            // notifyError('Some error occured')
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div style={{ width: "100%", marginTop: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button
                    // onClick={handleSubmit}
                    // disabled={load}
                    className="btn payment_cancel_button mt-md-1"
                    onClick={(e) => {
                        e.preventDefault();
                        showConfirm_cancelAlert(() => {
                            history.push('/profile')
                        })

                    }}
                    style={{ maxWidth: "150px", backgroundColor: "red", marginRight: "10px" }}>
                    Cancel
                </button>
                <button
                    // onClick={handleSubmit}
                    disabled={load_2}
                    className="btn login__submit_button mt-md-1" style={{ maxWidth: "350px" }}>
                    Pay & Subscribe
                </button>
            </div>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    );
};

const stripePromise = loadStripe('pk_test_51NoyikIw3CIjvUYbwCO1hWZlzeHEk0s48fJeLMaCwFYNlXopFFx8UowOPF1w5oMaLxCQBT6eTwaZCDwCDz37WWhc00QrE1QmTJ');


const Payment_component_new = ({ clientSecret, load_2 }) => {
    const options = {
        clientSecret
    };

    const [load, setLoad] = useState(false);

    return (
        <div className='p-2'>
            <Load load={load} />

            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm setLoad={setLoad} load={load} load_2={load_2} />
            </Elements>
        </div>
    )
};

export default Payment_component_new;