import React, { useEffect } from "react";
import Select from "react-select"
import "./AddAppointment.css";
import back from "../assets/back.png";
import { Link } from "react-router-dom";
import humburger from "../assets/humburger.png";
import profileIcon from "../assets/profile.png";
import emailIcon from "../assets/email.png";
import passwordIcon from "../assets/password.png";
import clockIcon from "../assets/clock.png";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { createCustomerBox, notifyError, notifySuccess } from "../util/Util";
import { addAppointment, getAllVehicle } from "../services/httpService";
import Loader from "./Loader";
import TimePicker from "rc-time-picker";
import moment from "moment";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import { useMediaQuery } from "@mui/material";
import userIcon from "../assets/user(1).png";

const CustomOption = ({ innerRef, innerProps, ...rest }) => {
  {/*
<div ref={innerRef} {...innerProps} style={{ width: "100%", textAlign: "start", padding: "2px 0px 2px 10px" }}>
      <span style={{ fontSize: "16px", fontWeight: rest.data.value.customer_id == -1 ? "600" : "450", color: rest.data.value.customer_id == -1 ? "#1050e6" : "#696969" }}>
        {rest.data.label + (rest.data.value.customer_id == -1 && ' +')}
      </span>
    </div>
*/}
  // console.log(innerProps, rest)
  return (
    <div ref={innerRef} {...innerProps}>
      <div className="card customer__card m-2">
        <div className="card-body customer__cardBody">
          <div className="row mt-2 mb-2" style={{ height: "50px" }}>
            <div className="col-12 text-secondary">{rest.data.label}</div>
            <div className="col-12 text-dark">{rest.data.value.vin_number}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
function AddAppointment() {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const matches_545 = useMediaQuery('(max-width:545px)');

  const history = useHistory();
  const [vehicleData, setVehicleData] = useState([]);
  const ventor_id = localStorage.getItem("vendor_id");
  const [customerId, setCustomerId] = useState("");
  const [vin, setVin] = useState("");
  const [load, setLoad] = useState(false);
  const [reg, setReg] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to the month because it is 0-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    setLoad(true);
    setDate(formatDate(new Date()));
    getAllVehicle(ventor_id)
      .then((res) => {
        setLoad(false)
        if (res["data"]["shop_wizard"]["status"] === "1") {
          setVehicleData(res["data"]["shop_wizard"]["vehicles"]);
        }
      });
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (customerId && vin && date && time) {
      setLoad(true)
      addAppointment(customerId, ventor_id, vin, reg, date + " " + time + ":00").then((res) => {
        console.log(customerId, ventor_id, vin, reg, date + " " + time + ":00");
        try {
          setLoad(false)
          if (res["data"]["shop_wizard"]["status"] == "0") {
            notifyError(res["data"]["shop_wizard"]["msg"]);
          }
          if (res["data"]["shop_wizard"]["status"] == "1") {
            notifySuccess("Appointment add successfully")
            history.push("/appointment");
          }
        } catch (error) {
          notifyError("Something went wrong!")
        }
      });
    } else {
      let validate = [
        { name: "Customer", value: customerId },
        { name: "Date", value: date },
        { name: "time", value: time },
        { name: "VIN no.", value: vin }
      ]
      validate.some(v => {
        if (v.value) {
          return false
        } else {
          notifyError("Please enter " + v.name)
          return true
        }
      })
      // notifyError("Empty fields not allowed!")
    }
  };

  return (
    <>
      <Loader load={load} />
      <section className="addAppointment__section">
        <Navbar heading='Add Appointment' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80%" }}>
            <div className="container addAppointment__content mt-5">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <form onSubmit={handleSubmit}>
                    {/* <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="ml-5">
                  Customer ID
                </label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text login__span"
                      id="basic-addon1"
                    >
                      <img
                        src={profileIcon}
                        alt=""
                        className="img-fluid login__img"
                      />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control login__input"
                    placeholder="GRJAPP0365"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name="customerId"
                    value={customerId}
                    onChange={(event) => setCustomerId(event.target.value)}
                  />
                </div> */}
                    {/* </div> */}
                    <div className="input-group " style={{ width: "100%" }}>
                    <div
                        class="btn border-right-0 border input-group-append d-flex justify-content-center"
                        style={{ alignItems: "center",
                        width:"40px",
                        borderTopRightRadius:"0px",borderBottomRightRadius:"0px" }}
                        type="button">
                        <img
                          src={userIcon}
                          alt=""
                          // className="img-fluid"
                          style={{ height: "18px" }}
                        />
                      </div>
                      <Select
                        options={vehicleData.map(val => ({
                          label: val.customer_name,
                          value: {
                            customer_id: val.customer_id,
                            customer_name: val.customer_name,
                            vin_number: val.vin_number,
                            reg_number: val.reg_number,
                          }
                        }))}
                        placeholder="Customer"
                        value={(customerId > 0) ?
                          vehicleData.filter((el) => {
                            return el.customer_id == customerId
                          }).map(val => ({
                            label: val.customer_name,
                            value: {
                              customer_id: val.customer_id,
                              customer_name: val.customer_name,
                              vin_number: val.vin_number,
                              reg_number: val.reg_number,
                            }
                          }))[0]
                          : ""}
                        components={{ Option: CustomOption }}
                        onChange={v => {
                          if (v) {
                            setCustomerId(v.value.customer_id)
                            setVin(v.value.vin_number)
                            setReg(v.value.reg_number);
                            document.getElementById("date_input").focus();
                          }
                        }}
                        styles={{ container: (c) => ({ ...c, color: "black", zIndex: "200", width: matches_545?"71%":"91%" }) }}
                      ></Select>


                      <button
                        onClick={() => {
                          createCustomerBox({
                            vehicleData,
                            setVehicleData,
                            setCustomerId,
                            setVin_app: setVin,
                            setReg_app: setReg
                          });
                        }}
                        class="btn btn-outline-secondary border-left-0 border input-group-append d-flex justify-content-center"
                        style={{ alignItems: "center",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px" }}
                        // style={{ alignItems: "center" }}
                        type="button">
                        <i class="fa fa-plus"></i>
                      </button>

                    </div>


                    {/*<div style={{ width: "4%" }}>
                          <img
                            src={clockIcon}
                            alt=""
                            className="img-fluid ml-2"
                            style={{ height: "18px" }}
                          />
                      </div>*/}
                    <fieldset style={{ marginTop: "20px", width: "100%", padding: "0px 0px 8px 35px", border: "1px solid #c9c9c9" }}>
                      <legend style={{
                        width: "210px", borderRadius: "4px", padding: "2px 0px",
                        // border: "1px solid #c9c9c9",
                        marginLeft:"-35px",
                        display: "flex", alignItems: "center"
                      }}>
                        <img
                          src={clockIcon}
                          alt=""
                          className="img-fluid ml-2"
                          style={{ height: "18px" }}
                        />
                        <span style={{ color: "#696969", fontSize: "14px", marginLeft: "10px",fontWeight:"600" }}>
                          Appointment Date & Time
                        </span>
                      </legend>
                      {/*<p style={{ color: "#C4C4C4" }}>Appointment Date & Time</p>*/}
                      <div className="d-flex" style={{ alignItems: "center", padding: "10px 0px" }}>


                        <input
                          type="date"
                          className="form-control"
                          style={{ width: matches_545?"100px":"200px" }}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          // autoFocus={true}
                          // style={{ color: "#C4C4C4" }}
                          id="date_input"
                          name="date"
                          value={date}
                          onChange={(event) => {
                            // console.log(event.target.value);
                            let d = new Date();
                            let t = new Date(event.target.value)
                            if (t.setHours(0, 0, 0, 0) >= d.setHours(0, 0, 0, 0)) {
                              setDate(event.target.value)
                            }
                            else {
                              alert("Select correct date")
                            }
                          }}
                        />
                        <div className="ml-5"
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}>
                          <input type="time"
                            className="form-control "
                            style={{ width: matches_545?"100px":"200px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                            id="timeInput"
                            value={time}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setTime(e.target.value);
                            }}
                          />
                          {/*<TimePicker
                            showSecond={false}
                            defaultValue={moment().hour(0).minute(0)}
                            className="xxx"

                            onChange={e => {
                              let h = new Date(e._d).getHours() < 10 ? "0" + new Date(e._d).getHours() : new Date(e._d).getHours();
                              let m = new Date(e._d).getMinutes() < 10 ? "0" + new Date(e._d).getMinutes() : new Date(e._d).getMinutes();
                              setTime(h + ":" + m)
                            }}
                            format='h:mm a'
                            use12Hours
                            height="50px"
                            // clearIcon=<span>hi</span>
                            allowEmpty={false}
                            inputReadOnly
                          />*/}
                          {/*<input type="time" onChange={e => setTime(e.target.value)} value={time ? time : "00:00"} />*/}
                        </div>
                      </div>
                    </fieldset>
                    <div className="form-group mt-5">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            style={{ backgroundColor: "#fff" }}
                            id="basic-addon1"
                          >
                            <img
                              src={passwordIcon}
                              alt=""
                              style={{ zIndex: "0" }}
                              className="img-fluid login__img"
                            />
                          </span>
                        </div>
                        <input
                          type="text"
                          disabled
                          className="form-control login__input"
                          placeholder="VIN No. / Car Ragistration Number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          // style={{ color: "#C4C4C4" }}
                          name="vin"
                          value={vin}
                          onChange={(event) => setVin(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12">
                        <button className="btn addAppointment__button">
                          Book Appointment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddAppointment;
