import React, { useContext, useEffect } from "react";
import Select from "react-select"
import "./AddAppointment.css";
import back from "../assets/back.png";
import { Link } from "react-router-dom";
import humburger from "../assets/humburger.png";
import profileIcon from "../assets/profile.png";
import emailIcon from "../assets/email.png";
import passwordIcon from "../assets/password.png";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { notifyError, notifySuccess } from "../util/Util";
import { addAppointment, getAllAppointment, getAllVehicle, editAppointment } from "../services/httpService";
import Loader from "./Loader";
import TimePicker from "rc-time-picker";
import moment from "moment";
import Ncontext from "../ContextC";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
import clockIcon from "../assets/clock.png";
import { useMediaQuery } from "@mui/material";

const CustomOption = ({ innerRef, innerProps, ...rest }) => {
  console.log(innerProps, rest)
  return (
    <div ref={innerRef} {...innerProps}>
      <div className="card customer__card m-2">
        <div className="card-body customer__cardBody">
          <div className="row mt-2 mb-2" style={{ height: "50px" }}>
            <div className="col-12 text-secondary">{rest.data.label}</div>
            <div className="col-12 text-dark">{rest.data.value.vin_number}</div>
          </div>
        </div>
      </div>
    </div>)
}
function EditAppointment(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const context = useContext(Ncontext);
  const { mApp, setmApp, hApp, sethApp } = context;
  console.log({ mApp, setmApp, hApp });
  const history = useHistory();
  const [vehicleData, setVehicleData] = useState([]);
  const ventor_id = localStorage.getItem("vendor_id");
  const [customer_id, setCustomerId] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [vin, setVin] = useState("");
  const [load, setLoad] = useState(false);
  const [reg, setReg] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState(null);
  const [appointment, setAppointment] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  // const [hour, sethour] = useState(0);
  // const [minute, setminute] = useState(0);

  useEffect(() => {
    setLoad(true)
    getAllVehicle(ventor_id)
      .then((res) => {
        if (res["data"]["shop_wizard"]["status"] === "1") {
          setLoad(false)
          setVehicleData(res["data"]["shop_wizard"]["vehicles"]);
          // setTime()
          // console.log(res["data"]["shop_wizard"]);
          // console.log(res["data"]["shop_wizard"]["vehicles"]);
        }
      });


  }, [])

  // useEffect(() => {
  //   getAllAppointment(ventor_id)
  //     .then((res) => {
  //       console.log(res["data"]["shop_wizard"]);
  //       if (res["data"]["shop_wizard"]["status"] === "1") {
  //         res["data"]["shop_wizard"]["items"].map(val => {
  //           if (props.match.params.appoint_id == val.appointment_id) {
  //             sethour(parseInt(val.time.split(" ")[0]));
  //             setminute(parseInt(val.time.split(" ")[1]))
  //           }
  //         })
  //       }
  //     })
  // }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (customer_id && vin && date && time) {
      let d = new Date();
      let t = new Date(date)
      if (t.setHours(0, 0, 0, 0) >= d.setHours(0, 0, 0, 0)) {
        setLoad(true)
        editAppointment(appointment.appointment_id, date + " " + time + ":00", customer_id, vin, customer_name).then((res) => {
          try {
            setLoad(false)
            if (res["data"]["shop_wizard"]["status"] == "0") {
              notifyError(res["data"]["shop_wizard"]["msg"]);
            }
            if (res["data"]["shop_wizard"]["status"] == "1") {
              notifySuccess("Appointmentt Update successful")
              history.push("/appointment");
            }
          } catch (error) {
            notifyError("Something went wrong!")
          }
        });
      }
      else {
        notifyError("Enter correct date!")
      }
    } else {
      let validate = [
        { name: "Customer", value: customer_id },
        { name: "Date", value: date },
        { name: "time", value: time },
        { name: "VIN no.", value: vin }
      ]
      validate.some(v => {
        if (v.value) {
          return false
        } else {
          notifyError("Please enter " + v.name)
          return true
        }
      })
      // notifyError("Empty fields not allowed!")
    }
  };

  // console.log("params sss", props)

  useEffect(() => {
    if (vehicleData.length) {
      getAllAppointment(ventor_id)
        .then((res) => {
          console.log(res["data"]["shop_wizard"]);
          if (res["data"]["shop_wizard"]["status"] === "1") {
            res["data"]["shop_wizard"]["items"].map(val => {
              if (props.match.params.appoint_id == val.appointment_id) {
                setAppointment(val);
                // console.log(val, "qwedfg")
                setDate(val.date)
                // (val.time.split(" "))
                const time_arr = val.time.split(" ");
                const converted_time = time_arr[2] == 'AM' ? time_arr[0] + ":" + time_arr[1] : (parseInt(time_arr[0])+12)+":"+time_arr[1];
                // console.log(converted_time);
                setTime(converted_time);
                setVin(val.vin_number)
                setCustomerId(val.customer_id);
                // sethour(parseInt(val.time.split(" ")[0]));
                // setminute(parseInt(val.time.split(" ")[1]))
                setCustomerName(val.customer_name);
                // alert(parseInt(val.time.split(" ")[0]) + ":" + parseInt(val.time.split(" ")[1]))
                let filtered = vehicleData.filter(val2 => {
                  if (val.customer_id === val2.customer_id) {
                    return true
                  }
                })
                // console.log(vehicleData)
                if (filtered.length) {
                  setSelectedCustomer({
                    label: filtered[0].customer_name,
                    value: filtered[0].customer_id
                  })
                }
              }
            })
          }
        });
    }
  }, [vehicleData])

  useEffect(() => {

  }, [selectedCustomer])
  // console.log("veh data", vehicleData)
  // console.log("veh data 2", appointment)
  return (
    <>
      <Loader load={load} />
      <section className="addAppointment__section">
        <Navbar heading='Edit Appointment' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80.9%" }}>
            <div className="container addAppointment__content mt-5">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <form onSubmit={handleSubmit}>
                    <Select
                      isDisabled={true}
                      value={selectedCustomer}
                      options={vehicleData.map(val => ({
                        label: val.customer_name,
                        value: {
                          customer_id: val.customer_id,
                          customer_name: val.customer_name,
                          vin_number: val.vin_number,
                          reg_number: val.reg_number,
                        }
                      }))}
                      placeholder="Customer"
                      components={{ Option: CustomOption }}
                      onChange={v => {
                        if (v) {
                          setVin(v.vin_number)
                          setCustomerId(v.customer_id)
                          setCustomerName(v.customer_name)
                        }
                      }}
                      styles={{ container: (c) => ({ ...c, color: "black", zIndex: "200" }) }}
                    ></Select>
                    <fieldset style={{ marginTop: "20px", width: "100%", padding: "0px 0px 8px 35px", border: "1px solid #c9c9c9" }}>
                      <legend style={{
                        width: "210px", borderRadius: "4px", padding: "2px 0px",
                        // border: "1px solid #c9c9c9",
                        marginLeft: "-35px",
                        display: "flex", alignItems: "center"
                      }}>
                        <img
                          src={clockIcon}
                          alt=""
                          className="img-fluid ml-2"
                          style={{ height: "18px" }}
                        />
                        <span style={{ color: "#696969", fontSize: "14px", marginLeft: "10px",fontWeight:"600" }}>
                          Appointment Date & Time
                        </span>
                      </legend>
                      {/*<p style={{ color: "#C4C4C4" }}>Appointment Date & Time</p>*/}
                      <div className="d-flex" style={{ alignItems: "center", padding: "10px 0px" }}>


                        <input
                          type="date"
                          className="form-control"
                          style={{ width: "200px" }}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          // autoFocus={true}
                          // style={{ color: "#C4C4C4" }}
                          name="date"
                          value={date}
                          onChange={(event) => {
                            // console.log(event.target.value);
                            let d = new Date();
                            let t = new Date(event.target.value)
                            if (t.setHours(0, 0, 0, 0) >= d.setHours(0, 0, 0, 0)) {
                              setDate(event.target.value)
                            }
                            else {
                              alert("Select correct date")
                            }
                          }}
                        />
                        <div className="ml-5"
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}>
                          <input type="time"
                            className="form-control "
                            style={{
                              width: "200px"
                              // , borderTopRightRadius: "0px", borderBottomRightRadius: "0px"
                            }}
                            id="timeInput"
                            value={time}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setTime(e.target.value);
                            }}
                          />



                          {/*<TimePicker
                            showSecond={false}
                            defaultValue={moment().hour(0).minute(0)}
                            className="xxx"

                            onChange={e => {
                              let h = new Date(e._d).getHours() < 10 ? "0" + new Date(e._d).getHours() : new Date(e._d).getHours();
                              let m = new Date(e._d).getMinutes() < 10 ? "0" + new Date(e._d).getMinutes() : new Date(e._d).getMinutes();
                              setTime(h + ":" + m)
                            }}
                            format='h:mm a'
                            use12Hours
                            height="50px"
                            // clearIcon=<span>hi</span>
                            allowEmpty={false}
                            inputReadOnly
                          />*/}
                          {/*<input type="time" onChange={e => setTime(e.target.value)} value={time ? time : "00:00"} />*/}
                        </div>
                      </div>
                    </fieldset>
                    <div className="form-group mt-5">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text login__span"
                            id="basic-addon1"
                          >
                            <img
                              src={passwordIcon}
                              alt=""
                              style={{ zIndex: "0" }}
                              className="img-fluid login__img"
                            />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control login__input"
                          placeholder="VIN No. / Car Ragistration Number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          // style={{ color: "#C4C4C4" }}
                          name="vin"
                          value={vin}
                          onChange={(event) => setVin(event.target.value)}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12">
                        <button className="btn addAppointment__button">
                          Book Appointment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditAppointment;
