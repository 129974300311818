import React from 'react'
import logo from "../assets/sw_logo.png";
import { Link } from "react-router-dom";
import "./Sidebar.css"
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import dashboard_icon from "../assets/dashboard.png"
import appointment_icon from "../assets/appointment.png"
import estimate_icon from "../assets/calculator.png"
import invoice_icon from "../assets/bill.png"
import customer_icon from "../assets/user(1).png"
import vehicle_icon from "../assets/CAR.png"
import summary_icon from "../assets/summary.png"
import message_icon from "../assets/message_icon.png"
import profile_icon from "../assets/profile_icon.png"
import logout_icon from "../assets/logout.png"

const SideBar = ({ width }) => {
    const matches_820 = useMediaQuery('(max-width:820px)');

    const Icon_img = (img_src, size) => { return <img src={img_src} style={{ height: size || "18px" }} /> };

    const routes = [
        { path: "/dashboard", label: 'Dashboard', icon: Icon_img(dashboard_icon) },
        { path: "/appointment", label: 'Appointments', icon: Icon_img(appointment_icon) },
        { path: "/estimate-list", label: 'Estimates', icon: Icon_img(estimate_icon) },
        { path: "/invoice", label: 'Invoices', icon: Icon_img(invoice_icon) },
        { path: "/customer", label: 'Customers', icon: Icon_img(customer_icon) },
        { path: "/vehicle", label: 'Accounts', icon: Icon_img(vehicle_icon, '22px') },
        { path: "/invoice-summary", label: 'Invoice Statement', icon: Icon_img(summary_icon) },
        { path: "/message", label: 'Messages', icon: Icon_img(message_icon) },
        { path: "/profile", label: 'My Profile', icon: Icon_img(profile_icon) },
        // { path: "/img-to-text", label: 'Img to text', icon: Icon_img(profile_icon) },
        { path: "/", label: 'Logout', icon: Icon_img(logout_icon,'20px') },
    ]

    const history = useHistory();

    const pathhash = window.location.hash.split('/');
    const path_name = pathhash[pathhash.length - 1];

    const logout = () => {
        if (window.confirm("Do you want to log out?")) {
            localStorage.clear()
            history.push("/")
        }
    }

    return (
        <div style={{ width: width ? width : "19%", height: "91.5vh", position: !matches_820 ? "fixed" : "", top: 70, left: 0, zIndex: 29, backgroundColor: "rgba(243,244,246,var(--tw-bg-opacity))", borderRight: "1px solid #e5e7eb" }}>
            {matches_820 && <div style={{ padding: "4px 0px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={logo} style={{ height: "50px" }} />
                <b style={{ color: "#696969", marginLeft: "10px", fontSize: "20px" }}>ShopWizard</b>
            </div>}
            {
                routes.map((el) => {
                    const active_path = el.path == ("/" + path_name);
                    const active_style = active_path ? { backgroundColor: "rgba(17,24,39,.075)", textDecoration: "underline" } : {}

                    return (
                        <div style={{ padding: "5px 4px", width: "100%", }}>
                            <div
                                onClick={() => {
                                    if (el.label == 'Logout') {
                                        logout();
                                    }
                                    else {
                                        history.push(el.path)
                                    }
                                }}
                                id='nav_item'
                                style={{
                                    ...active_style, padding: "5px 10px", cursor: "pointer", borderRadius: "5px", display: "flex", justifyContent: "start", alignItems: "center"
                                }}>
                                {
                                    el.icon &&
                                    el.icon
                                }
                                &nbsp;
                                &nbsp;
                                {
                                    el.label == 'Logout' &&
                                    <span style={{ color: "#696969", fontWeight: "600" }} >
                                        {el.label}
                                    </span>
                                }
                                {
                                    el.label != 'Logout' &&
                                    <Link style={{ color: "#696969", fontWeight: "600" }} to={el.path}>
                                        {el.label}
                                    </Link>
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SideBar