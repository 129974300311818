import React, { useState } from "react";
import "./Customer.css";
import back from "../assets/back.png";
import { Link, useHistory } from "react-router-dom";
import humburger from "../assets/humburger.png";
import name from "../assets/name.png";
import down from "../assets/down.png";
import tick from "../assets/checked.png";
import { useEffect } from "react";
import { getAllCustomer, searchCustomer } from "../services/httpService";
import Loader from "./Loader";
import { notifyError } from "../util/Util";
import Navbar from "./Navbar";
import SideBar from "./Sidebar";
import { useMediaQuery } from "@mui/material";

function SearchCustomer(props) {
  const matches_820 = useMediaQuery('(max-width:820px)');
  const [customerData, setCustomerData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [load, setLoad] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [query, setQuery] = useState("");
  const ventor_id = localStorage.getItem("vendor_id");
  const history = useHistory();
  const [dummy, setdummy] = useState(0)
  useEffect(() => {
    setLoad(true)
    getAllCustomer(ventor_id)
      .then((res) => {
        setLoad(false)
        if (res["data"]["shop_wizard"]["status"] === "1") {
          setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
          console.log(res["data"]["shop_wizard"]["customer_list"], "////")
          setShowData(res["data"]["shop_wizard"]["customer_list"]);
        }
      });
    return () => { };
  }, []);

  useEffect(() => {
    if (query) {
      // searchCustomer(ventor_id, query)
      //   .then((res) => {
      //     if (res["data"]["shop_wizard"]["status"] === "1") {
      //       setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
      //     }
      //   });
      setShowData(customerData.filter(v => v.name.toLowerCase().includes(query.toLowerCase())))
    } else {
      // getAllCustomer(ventor_id)
      //   .then((res) => {
      //     if (res["data"]["shop_wizard"]["status"] === "1") {
      //       setCustomerData(res["data"]["shop_wizard"]["customer_list"]);
      //     }
      //   });
      setShowData(customerData)
    }
  }, [query])
  // console.log("datatatata")

  useEffect(() => {
    console.log("emails", props.emails)
  }, [props.emails && props.emails.length])
  return (
    <>
      <Loader load={load} />
      <section className="customer__section">
        <Navbar heading='Select Customer' />
        <div style={{ display: "flex" }}>
          {
            !matches_820 &&
            <div style={{ width: "19%" }}>
              <SideBar />
            </div>
          }
          <div style={{ width: matches_820 ? "100%" : "80.9%" }}>
            {/*<i class="fa fa-check" aria-hidden="true" style={{ cursor: "pointer", position: "absolute", zIndex: "1000", right: "25px", fontSize: "25px", color: "#fff" }} alt=""
              onClick={
                () => {
                  props.emails.length > 0 ?
                    props.setSearchCustomer(false) :
                    notifyError("Select a customer")
                }
              }></i>*/}
            <div class="input-group col-md-12 search-bar_container py-3 border border-bottom-1">
              <div class="input-group mb-0">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input type="checkbox" aria-label="Checkbox for following text input" style={{ cursor: "pointer" }} checked={props.emails.length == showData.length ? true : false} onClick={
                      () => {
                        const checkboxes = document.querySelectorAll(".selected-customers input[type = checkbox]")
                        // console.log(checkboxes)
                        // Array.prototype.map.call(checkboxes, function (n) {
                        //   if (!allChecked) {
                        //     n.checked = true
                        //     // if (e.target.checked) {
                        //     props.setEmails(val => {
                        //       val.push(n.value)
                        //       console.log('if', val)
                        //       return val
                        //     })
                        //   } else {
                        //     n.checked = false
                        //     // if (e.target.checked) {
                        //     props.setEmails([])
                        //   }
                        // });
                        if (props.emails.length == showData.length) {
                          props.setEmails([])
                        } else {
                          if(props.messageDetail.template_type==1){
                            props.setEmails(showData.map((data) => {
                              return data.contact;
                            }))
                          }
                          else{
                          props.setEmails(showData.map((data) => {
                            return data.email;
                          }))
                        }
                        }
                        setAllChecked(!allChecked)
                      }
                    } />
                  </div>
                </div>
                <input type="text" class="form-control" aria-label="Text input with checkbox" onChange={e => setQuery(e.target.value)} placeholder="Search" id="example-search-input" />
              </div>
            </div>
            <div className="container customer__content bg-white" style={{ minHeight: "100vh" }}>
              <div className="row selected-customers">

                {showData?.map(function (data) {
                  return (
                    <div className="col-12 mt-2"
                      onClick={
                        () => {
                          props.addEstimateSearch &&
                            console.log("ddddaaaattttaaaa", data)
                        }
                      }
                    >
                      <div className="card customer__card">
                        <div className="card-body customer__cardBody py-1">
                          <div className="row py-2">
                            <div className="col-1 pr-0 d-flex align-items-center">
                              {
                                !props.addEstimateSearch ?
                                (props.messageDetail.template_type == 1?
                                  <input type="checkbox" style={{ cursor: "pointer", width: "20px", height: "20px" }}
                                    value={data.contact}
                                    checked={props.emails.indexOf(data.contact) == -1 ? false : true}
                                    onClick={
                                      () => {
                                        setdummy(dummy + 1);
                                        if (props.emails.indexOf(data.contact) == -1) {
                                          // props.setEmails(val => {
                                          //   val.push(data.contact)
                                          //   console.log('if', val)
                                          //   return val
                                          // })
                                          props.emails.push(data.contact)
                                        } else {
                                          props.setEmails(val => {
                                            return val.filter(email => email !== data.contact)
                                          })
                                        }
                                        // alert(props.emails.length);
                                      }
                                    }
                                  />:
                                  <input type="checkbox" style={{ cursor: "pointer", width: "20px", height: "20px" }}
                                    value={data.email}
                                    checked={props.emails.indexOf(data.email) == -1 ? false : true}
                                    onClick={
                                      () => {
                                        setdummy(dummy + 1);
                                        if (props.emails.indexOf(data.email) == -1) {
                                          // props.setEmails(val => {
                                          //   val.push(data.email)
                                          //   console.log('if', val)
                                          //   return val
                                          // })
                                          props.emails.push(data.email)
                                        } else {
                                          props.setEmails(val => {
                                            return val.filter(email => email !== data.email)
                                          })
                                        }
                                        // alert(props.emails.length);
                                      }
                                    }
                                  />)
                                  : null
                              }
                            </div>
                            <div className="col-11" style={{ display: "flex", alignItems: "center" }}>
                              <p className="customer__paraDark mb-0">{data.name + " ("}</p><span style={{ color: "black", fontSize: "14px" }}>{props.messageDetail && props.messageDetail.template_type == 1 ? data.contact : data.email}</span><p className="customer__paraDark mb-0">)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/*<div className="row">
            <div className="col-12  d-flex justify-content-center align-items-center">
            </div>
          </div>*/}
              <button className="btn addAppointment__button"
                onClick={
                  () => {
                    props.setSearchCustomer(false)
                  }
                }
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SearchCustomer;
